/** @format */
import ButtonComp from '@/components/button-comp'
import CardComp from '@/components/card-comp'
import FlodAvatar from '@/components/fold-avatar'
import LoadingComp from '@/components/loading-comp'
import Separator from '@/components/separator'
import useIsLogin from '@/hooks/useIsLogin'
import {addAppBridge, postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import CODE from '@/utils/enum/code'
import {CIRCLE_OPERATING, MTYPE, TOGGLE_TYPE} from '@/utils/enum/index'
import NativePage from '@/utils/enum/native-page'
import SHARE_TYPE from '@/utils/enum/share'
import {likeFormat} from '@/utils/format'
import {swicthToNativeApp} from '@/utils/native-application'
import {circleDetails, getPostList, operaCircle} from '@/utils/request/circle-details'
import {follow, getInfo} from '@/utils/request/public'
import {isAndroid, isIOS} from '@/utils/userAgentUtil'
import {ListView, Toast} from 'antd-mobile'
import React, {MutableRefObject, useEffect, useRef, useState} from 'react'
import intl from 'react-intl-universal'
import {useHistory, useParams} from 'react-router-dom'
import {POST_ACTION} from '@/utils/enum/index'
import {operaPost} from '@/utils/request/circle-details'
import './index.less'

let page = 0

const CircleDetails = (): JSX.Element => {
  const history = useHistory()
  const {id, type} = useParams()
  const userId = cookieUtils.getCookie('__CXY_UID_')
  const deviceNavHeight = cookieUtils.getNativeStatusBarHeight()
  const tabs = [intl.get('circleDetail.tab_all'), intl.get('circleDetail.tab_video')]
  const PAGESIZE = 5
  // 初始化上拉加载
  const pullUp = new ListView.DataSource({
    rowHasChanged: (row1: any, row2: any) => row1 !== row2,
  })

  // 获取Dom
  const stickyRef: MutableRefObject<any> = useRef(null)
  const headerRef: MutableRefObject<any> = useRef(null)
  const detailsRef: MutableRefObject<any> = useRef(null)
  const sliderRef: MutableRefObject<any> = useRef(null)
  const tabRef: MutableRefObject<any> = useRef(null)
  const tabsRef: MutableRefObject<any> = useRef(null)

  // state变量
  const [init, setInit] = useState(false)
  const [scrollY, setScrollY] = useState(0)
  const [active, setActive] = useState(0)
  const [fixed, setFixed] = useState(false)
  const [sticky, setSticky] = useState(false)
  const [loading, setLoading] = useState(true)
  const [circleData, setCircleData] = useState<any>({})
  const [avatars, setAvatars] = useState<any[]>([])
  const [postList, setPostList] = useState<any[]>([])
  const [sourceData, setSourceData] = useState(pullUp)
  // 监听滚动条的距离，显示悬浮导航
  const getScroll = function (): void {
    const headerHeight = headerRef.current.clientHeight
    const scrollY = document.documentElement.scrollTop || document.body.scrollTop
    setScrollY(scrollY)
    setTabsTop()
    if (scrollY >= headerHeight) return setSticky(true)
    setSticky(false)
  }

  const backTo = () => {
    if (type === 'h5') {
      return history.go(-1)
    }
    postMsgToNative('closePage', '')
  }

  // 获取圈子数据
  const _initDetails = async (sort = false) => {
    const {code, result} = await circleDetails({id})
    if (code === CODE.SUCCESS) {
      setCircleData(result.item)
      let list = result.userList
      // 是否需要添加当前用户头像
      if (sort) {
        // 获取用户头像
        const {code, msg, result} = await getInfo(1)
        if (code !== CODE.SUCCESS) {
          Toast.fail(msg)
          return
        }
        const userInfo = result.userInfo
        // setUserInfo(result.userInfo)
        // await getUserInfoData() // 获取用户信息
        const newMember = {
          base: userInfo.base,
          likeNumber: 0,
          crownLevel: 0,
        }
        // 去重
        list = list.filter((item: any) => {
          return item.base.userId !== userInfo.base.userId
        })
        list.unshift(newMember)
      }
      setAvatars(list)
    }
  }

  // 设置tabs固定顶部
  const setTabsTop = () => {
    const tabsOffsetTop = detailsRef.current.getBoundingClientRect().top
    const targetHeight = stickyRef.current.getBoundingClientRect().height
    if (tabsOffsetTop <= targetHeight) {
      setFixed(true)
      tabsRef.current.style.top = `${targetHeight}px`
      detailsRef.current.style.paddingTop = `${targetHeight}px`
    } else {
      setFixed(false)
      tabsRef.current.style.top = 0
      detailsRef.current.style.paddingTop = 0
    }
  }

  // 设置tabs滑块的样式
  const setTabs = () => {
    sliderRef.current.style.width = tabRef.current.clientWidth * 0.3 + 'px'
    const left = tabRef.current.clientWidth * (active + 1 - 0.5) + 'px'
    sliderRef.current.style.left = left
  }

  // 获取帖子公共方法
  const _postList = async (mediaType: number, originList: any[]) => {
    const params = {
      page: page,
      pageSize: PAGESIZE,
      circleId: id,
      keyword: '',
      mediaType,
    }
    const {code, result} = await getPostList(params)
    if (code === CODE.SUCCESS) {
      setInit(true)
      const newList = [...originList, ...result.list]
      setPostList(newList)
      setSourceData(pullUp.cloneWithRows(newList))
      if (result.list.length < PAGESIZE) {
        setLoading(false)
      }
    }
  }

  // 获取帖子详情
  const _getPostList = async (isScroll: boolean, mediaType: number) => {
    if (!loading) return
    isScroll ? page++ : (page = 1)
    _postList(mediaType, postList)
  }

  // 跳转圈子成员列表
  const onCircleMember = (id: any) => {
    const param: any = {
      type: NativePage.webPage,
      params: {
        url: `/circle-member/${id}`,
      },
    }
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
  }

  // 搜索圈子内的帖子
  const searchPost = () => {
    const param: any = {
      type: NativePage.searchPost,
      params: {
        url: `/search-post/${id}`,
      },
    }
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
  }

  // 分享功能
  const shareCircle = (post: any, type: string) => {
    const _params: any = {}
    if (type === 'circle') {
      _params.desc = post.description
      _params.title = post.name
      _params.pageType = SHARE_TYPE.Circle
      _params.url = `/share-circle/${post.id}`
      _params.icon = post.avatar
      _params.actionType = userId === post.authorUserId ? [0, 3, 1] : [0, 3, 1, 2]
    }
    if (type === 'post') {
      _params.desc = post.content
      _params.title = `${post.authorUserInfo.nickName}在创想云发了一条帖子`
      _params.pageType = SHARE_TYPE.Post
      _params.url = `/share-post/${post.id}`
      _params.icon = post.media && post.media.length ? post.media[0].url || '' : ''
      _params.actionType = userId === post.authorUserId ? [0, 1] : [0, 1, 2]
    }
    const params = Object.assign({}, {id: post.id}, _params)
    postMsgToNative('onMenu', encodeURI(JSON.stringify(params)))
  }

  // 点击切换tabs
  const toggleTabs = (index: number) => {
    if (index === active) return
    window.scrollTo(0, 0)
    setInit(false)
    setActive(index)
    setTabs()
    setPostList([])
    setLoading(true)
    setSourceData(pullUp.cloneWithRows([]))
  }

  // 按钮操作，加入圈子或者点赞
  const toggleBtns = async (type: string, id: number, relationship?: number) => {
    if (!useIsLogin()) return
    const isJoined = circleData.isJoined
    // 加入与未加入圈子操作
    if (type === TOGGLE_TYPE.JOIN) {
      if (isJoined) return
      const params = {
        id,
        action: CIRCLE_OPERATING.JOIN,
      }
      const {code} = await operaCircle(params)
      if (code === CODE.SUCCESS) {
        _initDetails(true)
      }
    }
    // 关注与取消关注
    if (type === TOGGLE_TYPE.FOLLOW) {
      let action = false
      if (relationship === 0 || relationship === 2) {
        action = true
      }
      if (relationship === 1 || relationship === 3) {
        action = false
      }
      const params = {
        userIds: [id],
        action: action,
      }
      const {code} = await follow(params)
      if (code === CODE.SUCCESS) {
        postList.forEach(post => {
          const relationship = post.authorUserInfo.relationship
          if (id === post.authorUserId) {
            if (relationship === 0 || relationship === 2) {
              post.authorUserInfo.relationship = 1
            }
            if (relationship === 1 || relationship === 3) {
              post.authorUserInfo.relationship = 0
            }
          }
        })
        setPostList([...postList])
        setSourceData(pullUp.cloneWithRows(JSON.parse(JSON.stringify(postList))))
      }
    }
  }

  // 评论
  const commentPost = (id: number, type: string) => {
    if (type === 'click') {
      if (!useIsLogin()) return
      postMsgToNative('commentPost', `${id}`)
    }
    if (type === 'jump') {
      history.push(`/post-details/${id}/h5`)
    }
  }

  // 点赞
  const toggleLike = async (id: number, isLike: boolean) => {
    const params = {
      id,
      action: isLike ? POST_ACTION.NOLIKE : POST_ACTION.LIKE,
    }
    const {code} = await operaPost(params)
    if (code === CODE.SUCCESS) {
      const _postList = postList
      for(let i = 0; i < _postList.length; i++) {
        const post = _postList[i]
        if (post.id === id) {
          post.isLike = !post.isLike
          post.likeNumber = isLike ? post.likeNumber - 1 : post.likeNumber + 1
          break
        }
      }
      setPostList(_postList)
      setSourceData(pullUp.cloneWithRows(_postList))
    }
  }

  // 我要参与
  const participate = () => {
    if (!useIsLogin()) return
    // 登录状态
    const param: any = {
      type: NativePage.participate,
      params: {
        id: id,
        name: circleData.name,
        avatar: circleData.avatar,
      },
    }
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
  }

  addAppBridge('refreshPage', async (data: any) => {
    const VERISON = cookieUtils.getCookie('__CXY_APP_VER_')
    if ((isAndroid() && VERISON > '1.4.0') || (isIOS() && VERISON > '1.4.0')) {
      if (!data) return
      let result: any = {}
      if (isAndroid()) {
        result = JSON.parse(data).item
      }
      if (isIOS()) {
        result = JSON.parse(decodeURIComponent(data)).item
      }
      window.scrollTo(0, 0)
      setPostList([result, ...postList])
      setSourceData(pullUp.cloneWithRows([result, ...postList]))
      return
    }
    _postList(MTYPE.ALL, [])
  })

  useEffect(() => {
    _initDetails()
  }, [])
  useEffect(() => {
    setTabs()
    // tabs切换时获取数据
    if (active === 0) {
      _getPostList(false, MTYPE.ALL)
    } else {
      _getPostList(false, MTYPE.VIDEO)
    }
  }, [active])
  useEffect(() => {
    const APP: any = document.getElementsByClassName('App')[0]
    if (APP) {
      APP.style.paddingTop = '0px'
    }
    window.addEventListener('scroll', getScroll)
    // 组件卸载时移除滚动条的监听事件
    return () => {
      window.removeEventListener('scroll', getScroll)
    }
  }, [scrollY])

  return (
    <div className="circle-details">
      <div
        className={`sticky-nav ${sticky ? 'active' : ''}`}
        ref={stickyRef}
        style={{paddingTop: `${deviceNavHeight}px`}}>
        <div className="back" onClick={() => backTo()}></div>
        <div className="title">{circleData.name}</div>
        {circleData.authorUserId !== userId && (
          <ButtonComp onClick={() => toggleBtns(TOGGLE_TYPE.JOIN, id)} status={circleData.isJoined ? -1 : -2} />
        )}
        <div className="share" onClick={() => shareCircle(circleData, 'circle')}></div>
      </div>
      <div
        className="header"
        ref={headerRef}
        style={{backgroundColor: circleData.backColor, paddingTop: `${deviceNavHeight}px`}}>
        <div className="tooptips">
          <div className="back" onClick={() => backTo()}></div>
          <div className="right">
            <div className="search" onClick={() => searchPost()}></div>
            <div className="share" onClick={() => shareCircle(circleData, 'circle')}></div>
          </div>
        </div>
        <div className="info">
          <div className="content">
            <div
              className="avatar"
              onClick={() => swicthToNativeApp('previewImages', `${[circleData.avatar]}`, 0)}
              style={{backgroundImage: 'url(' + circleData.avatar + ')'}}></div>
            <div className="text">
              <h3>{circleData.name}</h3>
              {circleData.location && (
                <div className="location">
                  <span className="icon"></span>
                  <p>{circleData.location.poiName}</p>
                </div>
              )}
            </div>
          </div>
          {circleData.authorUserId !== userId && (
            <ButtonComp onClick={() => toggleBtns(TOGGLE_TYPE.JOIN, id)} status={circleData.isJoined ? -1 : -2} />
          )}
        </div>
      </div>
      <div className="main">
        <div className="title">
          <p>{circleData.description}</p>
          <div className="user">
            <div className="flod-avatar">
              <FlodAvatar
                showCount={5}
                avatarList={avatars}
                userCount={circleData.userCount}
                event={(): void => onCircleMember(circleData.id)}
              />
            </div>
            {Number(circleData.likeNumber) ? (
              <span className="like">
                {likeFormat(circleData.likeNumber)} {intl.get('likePeople')}
              </span>
            ) : null}
          </div>
        </div>
        <Separator />
        <div className="details" ref={detailsRef}>
          <div ref={tabsRef} className={`tabs ${fixed ? 'fixed-tabs' : ''}`}>
            {tabs.map((tab, index) => (
              <span
                key={index}
                ref={tabRef}
                className={`tabs-item ${active === index ? 'active' : ''}`}
                onClick={() => toggleTabs(index)}>
                {tab}
              </span>
            ))}
            <span className="slider" ref={sliderRef}></span>
          </div>
          <div className="tabs-content">
            {init ? (
              <>
                {postList.length ? (
                  <ListView
                    dataSource={sourceData}
                    renderFooter={() => <LoadingComp loading={loading} />}
                    renderRow={(rowData, rowID) => (
                      <>
                        <CardComp
                          type="search"
                          isNative={true}
                          key={rowID}
                          card={rowData}
                          onClick={() =>
                            toggleBtns(TOGGLE_TYPE.FOLLOW, rowData.authorUserId, rowData.authorUserInfo.relationship)
                          }
                        />
                        <div className="tooltips-comp">
                          <div className="write" onClick={() => commentPost(rowData.id, 'click')}>
                            <span className="pen"></span>
                            <span>{intl.get('comTooltip.saySomething')}</span>
                          </div>
                          <div className="icons">
                            <div className={`like icon ${ rowData.isLike ? 'active' : ''}`} onClick={ () => toggleLike(rowData.id, rowData.isLike) }>
                              { rowData.likeNumber }
                            </div>
                            <div className="comment icon" onClick={() => commentPost(rowData.id, 'jump')}>
                              { rowData.commentNumber }
                            </div>
                            <div className="share icon" onClick={ () => shareCircle(rowData, 'post') }>
                              { rowData.sharedNumber }
                            </div>
                          </div>
                        </div>
                      </>  
                    )}
                    useBodyScroll
                    pageSize={PAGESIZE}
                    initialListSize={PAGESIZE}
                    onEndReached={() => _getPostList(true, active === 0 ? MTYPE.ALL : MTYPE.VIDEO)}
                    onEndReachedThreshold={20}
                  />
                ) : (
                  <div className="empty">
                    <img src={require('../../assets/img/no_post.png')} alt="" />
                    <p>{intl.get('circleDetail.noData')}</p>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/* 加入圈子、允许发帖、圈子审核通过同时满足才可参与发布帖子 */}
      {circleData.isJoined && circleData.isAllowComment && circleData.status === 2 && (
        <div className="suspend" onClick={() => participate()}>
          <div className="bubble">
            <div>{intl.get('circleDetail.join')}</div>
          </div>
          <i className="partake"></i>
        </div>
      )}
    </div>
  )
}

export default CircleDetails
