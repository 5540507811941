/** @format */

import post from '../http'

/**
 * 获取圈子标签列表
 * @param params
 * @var page 页码
 * @var pageSize 每页条数
 * @var classId 标签分类id
 * @var name 标签名称
 */
const getCircleTagList = (params: {page: number; pageSize: number; classId?: string; name?: string}) =>
  post('/api/circle/tag/listTag', params)

export {getCircleTagList}
