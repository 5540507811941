/** @format */

import CircleCheck from '@/assets/img/circle-check-icon.png'
import uploadImg from '@/assets/img/uploadImg.png'
import FormComfirm from '@/components/form-comfirm'
import NavBar from '@/components/nav-bar'
import {addAppBridge, nativeRouteTo} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import CODE from '@/utils/enum/code'
import NativePage from '@/utils/enum/native-page'
import {createUid} from '@/utils/public'
import {createCircle} from '@/utils/request/create-circle'
import {getClassList, reqOssInfo} from '@/utils/request/public'
import {CompressorImg, OssRequset} from '@/utils/upload-avatar'
import {ImagePicker, InputItem, Modal, TextareaItem, Toast, WingBlank} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import {useHistory} from 'react-router-dom'
import './index.less'

const CreatCircle = (props: any) => {
  const colorList = ['#0066cb', '#45baaa', '#009900', '#ff9934', '#cc6698']
  const language = cookieUtils.getLang()
  const alert = Modal.alert
  const history = useHistory()
  // 获取圈子分类；1：圈子
  const getCircleClassList = async () => {
    const {code, msg, result} = await getClassList(1)
    if (code !== CODE.SUCCESS) {
      Toast.fail(msg)
      return
    }
    setClassList(result.list)
  }
  const submitFrom = async () => {
    if (!classId) {
      Toast.info(intl.get('createCircle.needCate'))
      return
    }
    if (!name.trim()) {
      Toast.info(intl.get('createCircle.needName'))
      return
    }
    if (!description.trim()) {
      Toast.info(intl.get('createCircle.needDesc'))
      return
    }
    if (description.trim().length < 15 || description.trim().length > 120) {
      Toast.info(intl.get('createCircle.maxDescribe'))
      return
    }
    if (!avatar) {
      Toast.info(intl.get('createCircle.needAvatar'))
      return
    }
    Toast.loading(intl.get('comLoading.loading'), 30)
    let CdnAvatar = ''
    let image = avatar.file
    const isLt1M = image.size / 1024 / 1024 < 1 // 是否压缩大小
    if (!isLt1M) {
      image = await CompressorImg(image)
    }
    const ossInfo = await reqOssInfo()
    if (ossInfo.code == CODE.SUCCESS) {
      const aliyunInfo = ossInfo.result.info
      const client: any = await OssRequset(aliyunInfo)
      const _obj = aliyunInfo.image.prefixPath + '/' + createUid()
      let addrss: any = undefined
      if (checkAddress && checkAddress.poiName) {
        addrss = {
          city: checkAddress.city,
          country: checkAddress.country,
          district: checkAddress.district,
          lat: checkAddress.lat,
          lng: checkAddress.lng,
          poiName: checkAddress.poiName,
          province: checkAddress.province,
        }
      }
      client
        .put(_obj, image)
        .then(async (r1: any) => {
          CdnAvatar = aliyunInfo.image.cdnHost + r1.name
          const {code, msg} = await createCircle({
            name: name,
            avatar: CdnAvatar,
            backColor: backColor || colorList[0],
            description: description,
            classId: classId,
            location: addrss,
          })
          Toast.hide()
          if (code !== CODE.SUCCESS) {
            setIsShowComfirm(true)
            setConfirmType('fail')
            setConfirmTitle(intl.get('createCircle.createErr'))
            setConfirmMess(msg)
            //   setIsShowModel(true)
            // setModelTitle(msg)
            return
          }
          setIsShowComfirm(true)
          setConfirmType('success')

          setConfirmTitle(intl.get('createCircle.createSucc'))
          setConfirmMess(intl.get('createCircle.auditTip'))
          // setConfirmTitle("创建成功")
          // setConfirmMess("我们将会在2个工作日审核完成")
          // history.goBack()
        })
        .catch((err: any) => {
          Toast.fail(err)
        })
    }
  }
  const avatarObj: any = null
  const [files] = useState([])
  // const [isShowModel, setIsShowModel] = useState(false)
  // const [modelTitle, setModelTitle] = useState('')
  const [isShowComfirm, setIsShowComfirm] = useState(false)
  const [confirmTitle, setConfirmTitle] = useState('')
  const [confirmMess, setConfirmMess] = useState('')
  const [comfirmType, setConfirmType] = useState('fail')
  const [checkAddress, setCheckAddress] = useState<any>({})
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [avatar, setAvatar] = useState(avatarObj)
  const [backColor, setBackColor] = useState('')
  const [classId, setClassId] = useState(0)
  const [classList, setClassList] = useState([])
  const [] = useState(false)
  // 选择图片，并上传到阿里云
  const onChange = async (File: any) => {
    const image = File[0]
    const isLt5M = image.file.size / 1024 / 1024 < 5 // 允许图片大小
    if (!isLt5M) {
      Toast.fail(intl.get('createCircle.avatarMaxSize'))
      return
    }
    setAvatar(image)
  }
  // 返回上一页
  const backPage = () => {
    if (!name.trim() && !avatar && !classId && !backColor && !description.trim()) {
      history.goBack()
    } else {
      alert(intl.get('createCircle.tip'), intl.get('createCircle.createTip'), [
        {text: intl.get('cancel'), onPress: () => console.log('cancel')},
        {text: intl.get('confirm'), onPress: () => history.goBack()},
      ])
    }
  }
  useEffect(() => {
    console.log(props)
    // props.router.setRouteLeaveHook(props.route, () => {
    //   //if (this.state.unsaved)
    //   Toast.info('监听到路由离开')
    //   return 'You have unsaved information, are you sure you want to leave this page?'
    //    //获取通过connect包裹的对象
    // })
    addAppBridge('setLocation', (res: any) => {
      // Toast.info(res)
      if (res) {
        const location = JSON.parse(decodeURI(res))
        setCheckAddress(location)
      } else {
        setCheckAddress({})
      }
    })
    getCircleClassList()
    const APP: any = document.body
    if (APP) {
      APP.style.background = '#fff'
    }
  }, [])
  return (
    <div className="creat-circle-layout">
      {/* <MapModel isVisible={isVisible} onClear={() => clearMap()} onclose={() => setisVisible(false)} onChange={(e: any) => {setCheckAddress(e)}} /> */}
      {/* <FormModel visible={isShowModel} title={modelTitle} type="fail" hide={() => setIsShowModel(false)} /> */}
      <FormComfirm
        visible={isShowComfirm}
        title={confirmTitle}
        message={confirmMess}
        hide={() => history.replace('/my-circles')}
        type={comfirmType}
      />
      <NavBar
        isNativeBack={false}
        title={intl.get('createCircle.create')}
        leftAction={() => backPage()}
        rightContent={<div onClick={() => submitFrom()}>{intl.get('createCircle.confirm')}</div>}
      />
      <div className="upload-avatar">
        {avatar && avatar ? (
          <img className="img-view" src={avatar.url} />
        ) : (
          <div className="upload-view">
            <img src={uploadImg} />
            <span>{intl.get('createCircle.circleAvatar')}</span>
          </div>
        )}
        <ImagePicker
          accept="image/gif,image/jpeg,image/jpg,image/png"
          files={files}
          length={1}
          multiple={false}
          onChange={onChange}
          onImageClick={(index, fs) => console.log(index, fs)}
        />
      </div>
      <div className="from-layout">
        <WingBlank>
          <div className="circle-color">
            <div className="title">{intl.get('createCircle.circleBg')}</div>
            <div className="color-checkgroup">
              {colorList.map((color: string) => (
                <div
                  key={color}
                  onClick={() => {
                    setBackColor(color)
                  }}
                  className={`color-item`}
                  style={{backgroundColor: color}}>
                  {backColor === color && <img src={CircleCheck} width="17px" />}
                </div>
              ))}
            </div>
          </div>
          <div className="class-list">
            <div className="title">
              <span className="star-color">*</span>
              <span>{intl.get('createCircle.cate')}</span>
            </div>
            <div className="list">
              {classList.map((item: any) => (
                <div
                  key={item.id}
                  onClick={() => {
                    setClassId(item.id)
                  }}
                  className={`item ${classId === item.id && 'active'}`}>
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          <div className="circle-input">
            <InputItem
              clear
              value={name}
              onChange={value => setName(value)}
              maxLength={language === 0 ? 30 : 10}
              placeholder={intl.get('createCircle.namePlaceholder')}>
              <div className="input-tip">
                <span className="star-color">*</span>
                {intl.get('createCircle.name')}
              </div>
            </InputItem>
          </div>
          <div className="circle-input">
            <TextareaItem
              title={
                <div className="input-tip">
                  <span className="star-color">*</span>
                  {intl.get('createCircle.describe')}
                </div>
              }
              placeholder={intl.get('createCircle.describePlaceholder')}
              value={description}
              onChange={(value: any) => setDescription(value)}
              rows={5}
              count={120}
            />
          </div>
          <div className="circle-input">
            <InputItem
              value={checkAddress.poiName}
              maxLength={20}
              onClick={() => nativeRouteTo(NativePage.searchLocation, {})}
              editable={false}
              placeholder={intl.get('createCircle.locationPlaceholder')}>
              <div className="input-tip">{intl.get('createCircle.location')}</div>
            </InputItem>
          </div>
        </WingBlank>
      </div>
    </div>
  )
}
export default CreatCircle
