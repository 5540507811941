/** @format */

import NavBar from '@/components/nav-bar'
import {nativeRouteTo, postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import CODE from '@/utils/enum/code'
import NativePage from '@/utils/enum/native-page'
import {circleMember} from '@/utils/request/circle-member'
import {follow} from '@/utils/request/public'
import {Button, Toast} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import {useHistory, useParams} from 'react-router-dom'
import './index.less'
// const alert = Modal.alert
const page = 1
let pageSize = 100
// 跳转个人app中心页面
const goUserInfo = (id: any) => {
  const param: any = {
    type: NativePage.userInfo,
    params: {
      userId: id,
    },
  }
  postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
}
const CircleMemberList = (member: any) => {
  const data = member.member
  if (data.relationship === 1 || data.relationship === 3) {
    data.isFollow = true
  }
  const [obj, setObj] = useState<any>(data)
  const [Disabled, setDisabled] = useState<any>(false)
  const myUserId = cookieUtils.getCookie('__CXY_UID_')
  const isLogin = cookieUtils.checkingLogin()
  const onFollow = async (e: any, item: any) => {
    e.stopPropagation()
    if (!isLogin) {
      nativeRouteTo(NativePage.login, {})
      return
    }
    setDisabled(true)
    const {code, msg} = await follow({
      action: !item.isFollow,
      userIds: [item.base.userId],
    })

    if (code !== CODE.SUCCESS) {
      setDisabled(false)
      Toast.fail(msg)
      return
    }
    const newData = {
      base: data.base,
      isFollow: !item.isFollow,
      likeNumber: data.likeNumber,
    }
    setObj(newData)
    setDisabled(false)
  }
  return (
    <div className="circle-member" onClick={() => goUserInfo(obj.base.userId)}>
      <div className="avater">
        <img src={obj.base.avatar} />
      </div>
      <div className="user">
        <div className="name">{obj.base.nickName}</div>
        {obj.base.introduction && <div className="des">{obj.base.introduction}</div>}
      </div>
      {Number(obj.base.userId) !== Number(myUserId) ? (
        <div className="option">
          <Button
            disabled={Disabled}
            loading={Disabled}
            onClick={e => {
              onFollow(e, obj)
            }}
            className={`optioin ${obj.isFollow ? 'am-button-disabled' : ''}`}
            type="ghost"
            inline
            size="small">
            {obj.isFollow ? intl.get('circleMember.followed') : intl.get('circleMember.follow') + ' '}
          </Button>
        </div>
      ) : null}
    </div>
  )
}

const CircleMember = (): JSX.Element => {
  const {id} = useParams()
  const route = useHistory()

  if (!id) {
    route.replace('/404')
  }
  // 获取成员列表数据
  const getCircleMemberList = async () => {
    const {code, msg, result} = await circleMember({
      page,
      pageSize,
      id,
    })
    if (code !== CODE.SUCCESS) {
      Toast.fail(msg)
      return
    }
    const isFollow = (relationship: number): boolean => {
      let Follow = false
      if (relationship === 0 || relationship === 2) {
        Follow = false
      } else if (relationship === 1 || relationship === 3) {
        Follow = true
      }
      return Follow
    }
    setMemberList(result.list)
  }
  const [memberList, setMemberList] = useState([])
  useEffect(() => {
    getCircleMemberList()
  }, [])
  return (
    <div className="CircleMember-layout">
      <NavBar title={intl.get('circleMember.member')} isNativeBack={true} />
      <div className="list-box" style={{paddingTop: 0.44 + 'rem'}}>
        {memberList.map((item, index) => (
          <CircleMemberList key={index} member={item} />
        ))}
      </div>
    </div>
  )
}
export default CircleMember
