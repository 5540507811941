/** @format */

import Back from '@/assets/img/backIcon2x.png'
import {postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import {NavBar} from 'antd-mobile'
import {default as React, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import './index.less'

interface nav {
  cRef?: any
  title: string // 中间标题
  rightContent?: any // 右侧按钮区
  leftContent?: any //左侧按钮区
  icon?: any // 左侧图标(返回箭头)
  isNativeBack?: boolean
  leftAction?(): void
}

const Nav: React.FC<nav> = props => {
  const {title, rightContent, leftContent, icon = <img src={Back} />, isNativeBack = true, leftAction, cRef} = props
  const history = useHistory()
  const navBarRef: any = useRef(null)
  const [headBarHeight, setHeadBarHeight] = useState<any>(0)
  const backPage = () => {
    // postMsgToNative("closePage", "")
    if (leftAction) {
      return leftAction()
    }
    if (isNativeBack) {
      postMsgToNative('closePage', '')
    } else {
      history.goBack()
    }
  }
  useImperativeHandle(cRef, () => ({
    navBarH: navBarRef.current.clientHeight,
  }))
  useEffect(() => {
    const __CXY_STATUBARHEIGHT_ = cookieUtils.getNativeStatusBarHeight()
    setHeadBarHeight(__CXY_STATUBARHEIGHT_)
  }, [])
  return (
    <div className="nav-layout" style={{paddingTop: headBarHeight + 'px'}} ref={navBarRef}>
      <NavBar
        mode="light"
        leftContent={leftContent}
        icon={icon}
        onLeftClick={() => backPage()}
        rightContent={rightContent}>
        {title}
      </NavBar>
    </div>
  )
}
export default Nav
