/** @format */

import post from '../http'

/**
 * 获取阿里云信息
 * @method
 * @return {Object} 返回值 包括code,msg,result
 */
const reqAliyunInfo = () => post('/api/account/getAliyunInfo', {})

// 获取阿里oss信息
const reqOssInfo = () => post('/api/circle/public/getOssInfo', {})

// 获取用户信息
const getInfo = (version: number) => post('/api/user/getInfo', {version})

/**
 * 获取分类列表
 * @param type 1  圈子  2 模型 3 标签
 */
const getClassList = (type: number) => post('/api/circle/class/listClass', {type})

const loginV2 = (params: {account: string; password: string; type: number}) => post('/api/account/loginV2', params)

/**
 * 关注/取消关注，用户
 * @param params true：关注，false：取消关注
 */
interface follow {
  userIds: any[]
  action: boolean
}
const follow = (params: follow) => post('/api/user/follow', {action: params.action, userIds: params.userIds})

export {reqOssInfo, getClassList, loginV2, reqAliyunInfo, follow, getInfo}
