/** @format */

import cookieUtils from '@/utils/cookieUtils'
import throttle from '@/utils/throttle'
import {createUid} from '@/utils/public'
import React, {useEffect, useRef, useState} from 'react'
import './index.less'

interface Props {
  video: any
  isDetail: any
}

const VideoComp = (props: Props): JSX.Element => {
  const {video, isDetail} = props
  const scale = video.video.width / video.video.height

  const isWifi: boolean = cookieUtils.isWifi()
  const __CXY_APP_VER_: any = cookieUtils.getCookie('__CXY_APP_VER_') // 大于1.4.1

  // state变量
  const [play, setPlay] = useState(false)
  const [isMute, setMute] = useState(true)
  const [scrollY, setScrollY] = useState(0)
  const [videoId] = useState(createUid())
  const [isHide, setIsHide] = useState(true)
  const [routePath, setPath] = useState<string>('')

  // 获取dom
  const videoRef: any = useRef(null)
  const coverRef: any = useRef(null)
  // 设置视频播放
  const playVideo = () => {
    const _play = !play
    setPlay(_play)
    const current: any = document.getElementById(`video-${videoId}`)
    const videoEle: any = document.getElementsByClassName('video')
    current.style.visibility = 'visible'
    current.controlslist = 'nodownload'
    current.disablePictureInPicture = true
    setIsHide(false)
    if (_play) {
      for (let i = 0; i < videoEle.length; i++) {
        // if (videoEle[i] === current) {
        //   videoEle[i].play().catch(() => {
        //     // 当播放异常时，走异常流程
        //     console.warn("视频异常播放失败，请检查视频资源：" + video.video.defaultUrl)
        //   })
        // } else {
        //   videoEle[i].pause()
        // }
        videoEle[i].pause()
      }
      videoRef.current.play().catch(() => {
        // 当播放异常时，走异常流程
        console.warn("视频异常播放失败，请检查视频资源：" + video.video.defaultUrl)
      })

      coverRef.current.style.display = 'none'
    } else {
      videoRef.current.pause()
    }
  }
  // 设置视频静音
  const mutedVideo = () => {
    setMute(!isMute)
  }
  // 监听滚动条的距离，显示悬浮导航
  const getScroll = function (): void {
    setIsHide(false)
    const current: any = document.getElementById(`video-${videoId}`)
    const currentH = current.getBoundingClientRect()
    const windowH = window.innerHeight
    const videoTop = currentH.top
    const scrollY = document.documentElement.scrollTop || document.body.scrollTop
    setScrollY(scrollY)
    // const videoAll: any = document.getElementsByClassName('video')
    if (videoTop <= 160 && videoTop > 0 && !play) {
      playVideo()
      // console.log('自动播放')
    } else if (videoTop < 0 || videoTop > windowH - currentH.height) {
      setPlay(false)
      videoRef.current.pause()
    }
  }


  useEffect(() => {
    const {pathname} = location
    const path: string = '/' + pathname.split('/')[1]
    setPath(path)
    // Toast.info("当前app版本：" + __CXY_APP_VER_)
    if (videoRef && videoRef.current) {
      videoRef.current.addEventListener(
        'ended',
        function () {
          if (coverRef.current) {
            coverRef.current.style.display = 'block'
          }
          setPlay(false)
        },
        false,
      )
      // videoRef.current.addEventListener(
      //   'volumechange',
      //   function () {
      //     if (coverRef.current) {
      //       console.log('静音', coverRef.current.muted)
      //       // coverRef.current.style.display = 'block'
      //     }
      //     // setPlay(false)
      //   },
      //   false,
      // )
      videoRef.current.addEventListener(
        'play',
        function () {
          const videoEle: any = document.getElementsByClassName('video')
          const current: any = document.getElementById(`video-${videoId}`)
          for (let i = 0; i < videoEle.length; i++) {
            if (videoEle[i] === current) {
              videoEle[i].play().catch(() => {
                // 当播放异常时，走异常流程
                console.warn("视频异常播放失败，请检查视频资源：" + video.video.defaultUrl)
              })
            } else {
              videoEle[i].pause()
            }
          }
        },
        false,
      )
    }
    if (!isDetail) {
      // window.addEventListener('scroll', getScroll)
      window.addEventListener('scroll', throttle(function() { getScroll() }, 200))
    }
    // 组件卸载时移除滚动条的监听事件
    return () => {
      window.removeEventListener('scroll', throttle(function() { getScroll() }, 200))
    }
  }, [location])
  // 如果第三方浏览器打开则使用默认播放器设置
  if (routePath === '/share-post') {
    return (
      <div className={`video-comp ${scale > 1 ? 'horizontal ' : 'vertical'}`}>
        <div
          ref={coverRef}
          className="cover"
          style={{backgroundImage: `url(${isWifi && video.gifCover ? video.gifCover : video.url})`}}></div>
        <i className="play" onClick={() => playVideo()} style={{display: `${play ? 'none' : 'block'}`}}></i>
        <video
          controls
          controlsList="nodownload"
          x5-playsinline="true"
          playsInline
          x5-video-player-type="h5"
          x-webkit-airplay="true"
          x5-video-orientation="portraint"
          webkit-playsinline="true"
          ref={videoRef}
          poster={video.url}
          className="video-detail"
          id={`video-${videoId}`}
          src={video.video.defaultUrl}
        />
      </div>
    )
  } else {
    return (
      <div className={`video-comp ${scale > 1 ? 'horizontal ' : 'vertical'}`}>
        <div
          ref={coverRef}
          className="cover"
          style={{backgroundImage: `url(${isWifi && video.gifCover ? video.gifCover : video.url})`}}></div>
        <i className="play" onClick={() => playVideo()} style={{display: `${play ? 'none' : 'block'}`}}></i>
        {__CXY_APP_VER_ < '1.4.1' ? (
          <>
            <i
              style={{display: `${play ? 'none' : 'block'}`}}
              className={isMute ? 'mute' : 'voice'}
              onClick={() => {
                mutedVideo()
              }}></i>
            <div className="mask" onClick={() => playVideo()} style={{display: `${play ? 'block' : 'none'}`}}></div>
            <video
              ref={videoRef}
              controlsList="nodownload"
              x5-playsinline="true"
              playsInline
              autoPlay
              x5-video-player-type="h5"
              x-webkit-airplay="true"
              webkit-playsinline="true"
              poster={video.url}
              muted={isMute}
              className="video"
              id={`video-${videoId}`}
              src={isHide ? '' : video.video.defaultUrl}
            />
          </>
        ) : isDetail ? (
          <video
            controls
            controlsList="nodownload"
            x5-playsinline="true"
            playsInline
            x5-video-player-type="h5"
            x-webkit-airplay="true"
            webkit-playsinline="true"
            ref={videoRef}
            poster={video.url}
            className="video-detail"
            id={`video-${videoId}`}
            src={video.video.defaultUrl}
          />
        ) : (
          <>
            {/* <i
                  style={{display: `${play ? 'none' : 'block'}`}}
                  className={isMute ? 'mute' : 'voice'}
                  onClick={() => {
                    mutedVideo()
                  }}></i> */}
            {/* <div className="mask" onClick={() => playVideo()} style={{display: `${play ? 'block' : 'none'}`}}></div> */}
            <video
              ref={videoRef}
              controls={play}
              controlsList="nodownload"
              x5-playsinline="true"
              playsInline
              autoPlay
              x5-video-player-type="h5"
              x-webkit-airplay="true"
              webkit-playsinline="true"
              poster={video.url}
              muted={isMute}
              className="video"
              id={`video-${videoId}`}
              src={isHide ? '' : video.video.defaultUrl}
            />
          </>
        )}
      </div>
    )
  }
}

export default VideoComp
