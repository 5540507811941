/** @format */

import CardComp from '@/components/card-comp'
import LoadingComp from '@/components/loading-comp'
import useScrollTop from '@/hooks/useScrollTop'
import {postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import CODE from '@/utils/enum/code'
import {TOGGLE_TYPE} from '@/utils/enum/index'
import {getPostList} from '@/utils/request/circle-details'
import {follow} from '@/utils/request/public'
import {isAndroid, isIOS} from '@/utils/userAgentUtil'
import {ListView} from 'antd-mobile'
import React, {MutableRefObject, useEffect, useRef, useState} from 'react'
import intl from 'react-intl-universal'
import {useParams} from 'react-router-dom'
import './index.less'

let page = 0

const SearchPost: React.FC = () => {
  let verControl = true
  const VERISON = cookieUtils.getCookie('__CXY_APP_VER_')
  if ((isAndroid() && VERISON > '1.4.0') || (isIOS() && VERISON > '1.4.0')) {
    verControl = true
  } else {
    verControl = false
  }

  const {id, navKeyword} = useParams()
  const deviceNavHeight = cookieUtils.getNativeStatusBarHeight()
  const PAGESIZE = 4
  // 初始化上拉加载
  const pullUp = new ListView.DataSource({
    rowHasChanged: (row1: any, row2: any) => {
      return row1 !== row2
    },
  })

  // 获取dom
  const searchRef: MutableRefObject<any> = useRef(null)
  const emptyRef: MutableRefObject<any> = useRef(null)
  const inputRef: MutableRefObject<any> = useRef(null)

  // state
  const [keyword, setKeyword] = useState('')
  const [clear, setClear] = useState(false)
  const [initList, setInitList] = useState(false)
  const [postList, setPostList] = useState<any[]>([])
  const [sourceData, setSourceData] = useState(pullUp)
  const [loading, setLoading] = useState(true)

  // 设置高度
  const setTop = () => {
    if (!emptyRef.current) return
    const height = searchRef.current.clientHeight
    emptyRef.current.style.top = height + 'px'
  }

  // 输入框事件
  const handleChange = function (e: any): void {
    const keyword = e.target.value.replace(/\s+/g, '')
    if (!keyword) setInitList(false)
    setClear(!!keyword)
    setKeyword(keyword)
  }
  // 键盘事件
  const handleKeyDown = function (e: any): void {
    const keyCode = e.keyCode
    setLoading(true)
    if (keyCode !== 13 || !keyword) return
    _getPostList()
    inputRef.current.blur()
  }
  // 禁用表单的默认提交行为
  const handleSubmit = (e: any) => {
    e.preventDefault()
  }

  // 清空操作
  const clearAll = () => {
    setKeyword('')
    setClear(false)
    setInitList(false)
  }

  // 搜索帖子
  const _getPostList = async (isScroll = false) => {
    const _keyword = verControl ? navKeyword : keyword
    if (!loading) return
    isScroll ? page++ : (page = 1)
    const params = {
      page: page,
      pageSize: PAGESIZE,
      circleId: id,
      keyword: _keyword,
      mediaType: 0,
    }
    const {code, result} = await getPostList(params)
    if (code === CODE.SUCCESS) {
      setInitList(true)
      !verControl && setTop()
      const newList = [...postList, ...result.list]
      setPostList(newList)
      setSourceData(sourceData.cloneWithRows(newList))
      if (result.count * 1 <= PAGESIZE) {
        setLoading(false)
      }
    }
  }

  // 按钮操作，加入圈子或者点赞
  const toggleBtns = async (type: string, id: number, relationship?: number) => {
    // 关注与取消关注
    if (type === TOGGLE_TYPE.FOLLOW) {
      let action = false
      if (relationship === 0 || relationship === 2) {
        action = true
      }
      if (relationship === 1 || relationship === 3) {
        action = false
      }
      const params = {
        userIds: [id],
        action,
      }
      const {code} = await follow(params)
      if (code === CODE.SUCCESS) {
        const _postList: any[] = []
        postList.forEach(post => {
          const relationship = post.authorUserInfo.relationship
          if (id === post.authorUserId) {
            if (relationship === 0 || relationship === 2) {
              post.authorUserInfo.relationship = 1
            }
            if (relationship === 1 || relationship === 3) {
              post.authorUserInfo.relationship = 0
            }
          }
          _postList.push(post)
        })
        setPostList([..._postList])
        setSourceData(sourceData.cloneWithRows(JSON.parse(JSON.stringify(_postList))))
      }
    }
  }

  // 首次进入页面，返回顶部
  useScrollTop()

  useEffect(() => {
    const app: any = document.getElementsByClassName('App')[0]
    if (app) {
      app.style.paddingTop = '0px'
    }
  })

  useEffect(() => {
    if (navKeyword) {
      _getPostList()
    }
  }, [navKeyword])

  return (
    <div className="search-post">
      {!verControl ? (
        <div className="search-header" ref={searchRef} style={{paddingTop: `${deviceNavHeight}px`}}>
          <i className="back-icon" onClick={() => postMsgToNative('closePage', '')}></i>
          <div className="input">
            <i className="search-icon"></i>
            <form action="#" onSubmit={e => handleSubmit(e)}>
              <input
                ref={inputRef}
                placeholder={intl.get('searchPost.searchPlaceholder')}
                value={keyword}
                type="search"
                onKeyDown={() => handleKeyDown(event)}
                onChange={() => handleChange(event)}
              />
            </form>
            <i className="clear-icon" onClick={() => clearAll()} style={{display: clear ? 'block' : 'none'}}></i>
          </div>
          <div className="cancel" onClick={() => postMsgToNative('closePage', '')}>
            {intl.get('cancel')}
          </div>
        </div>
      ) : null}
      {initList ? (
        <>
          {postList.length ? (
            <ListView
              dataSource={sourceData}
              renderFooter={() => <LoadingComp loading={loading} />}
              renderRow={rowData => (
                <CardComp
                  key={rowData.id}
                  card={rowData}
                  type={'search'}
                  isNative={true}
                  onClick={() =>
                    toggleBtns(TOGGLE_TYPE.FOLLOW, rowData.authorUserId, rowData.authorUserInfo.relationship)
                  }
                />
              )}
              useBodyScroll
              pageSize={PAGESIZE}
              initialListSize={PAGESIZE}
              onEndReached={() => _getPostList(true)}
              onEndReachedThreshold={0}
            />
          ) : (
            <div className="search-empty" ref={emptyRef}>
              <i className="empty"></i>
              <p className="text">{intl.get('searchPost.noPostText')}~</p>
            </div>
          )}
        </>
      ) : null}
    </div>
  )
}

export default SearchPost
