/** @format */

import {postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import NativePage from '@/utils/enum/native-page'

const useIsLogin = () => {
  const token = cookieUtils.getCookie('__CXY_TOKEN_')
  const userId = cookieUtils.getCookie('__CXY_UID_')
  let isLogin = true
  if (!token || !userId) {
    const params = {
      type: NativePage.login,
      params: {},
    }
    isLogin = false
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(params)))
  }
  return isLogin
}

export default useIsLogin
