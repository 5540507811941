export const emoji = [
	{
    text: '[!开心]',
    image: require('./kaixin.png'),
    reg: /\[!开心\]/g
  },
  {
    text: '[!大哭]',
    image: require('./daku.png'),
    reg: /\[!大哭\]/g
  }, 
  {
    text: '[!捂脸]',
    image: require('./wulian.png'),
    reg: /\[!捂脸\]/g
  },
  {
    text: '[!得意]',
    image: require('./deyi.png'),
    reg: /\[!得意\]/g
  },
  {
    text: '[!疑问]',
    image: require('./yiwen.png'),
    reg: /\[!疑问\]/g
  },
  {
    text: '[!吃惊]',
    image: require('./chijing.png'),
    reg: /\[!吃惊\]/g
  },
  {
    text: '[!擦汗]',
    image: require('./cahan.png'),
    reg: /\[!擦汗\]/g
  },
	{
    text: '[!笑哭]',
    image: require('./xiaoku.png'),
    reg: /\[!笑哭\]/g
  },
  {
    text: '[!喜欢]',
    image: require('./xihuan.png'),
    reg: /\[!喜欢\]/g
  }, 
  {
    text: '[!挤眼]',
    image: require('./jiyan.png'),
    reg: /\[!挤眼\]/g
  },
  {
    text: '[!白眼]',
    image: require('./baiyan.png'),
    reg: /\[!白眼\]/g
  }, 
  {
    text: '[!吐舌]',
    image: require('./tushe.png'),
    reg: /\[!吐舌\]/g
  },
  {
    text: '[!坏笑]',
    image: require('./huaixiao.png'),
    reg: /\[!坏笑\]/g
  }, 
  {
    text: '[!骷髅]',
    image: require('./kulou.png'),
    reg: /\[!骷髅\]/g
  }, 
  {
    text: '[!呆]',
    image: require('./dai.png'),
    reg: /\[!呆\]/g
  },  
  {
    text: '[!偷笑]',
    image: require('./touxiao.png'),
    reg: /\[!偷笑\]/g
  }, 
  {
    text: '[!胜利]',
    image: require('./shengli.png'),
    reg: /\[!胜利\]/g
  }, 
  {
    text: '[!点赞]',
    image: require('./dianzan.png'),
    reg: /\[!点赞\]/g
  }, 
  {
    text: '[!加油]',
    image: require('./jiayou.png'),
    reg: /\[!加油\]/g
  }, 
];