/** @format */

import React from 'react'
import './index.less'

interface ModelItem {
  openPage: Function
  modelInfo: any
}
const formatCount = (num: number) => {
  if (!num) return
  return num > 99 ? '99+' : num
}
const formatSize = (size: any) => {
  if (!size) return
  size = parseInt(size)
  if (size > 1024 * 1024) return (size / 1024 / 1024).toFixed(2) + 'MB'
  return (size / 1024).toFixed(2) + 'KB'
}
const ComModelItem = (props: ModelItem) => {
  const {openPage, modelInfo} = props
  return (
    <div className="model-item-container" onClick={() => openPage()}>
      <div className="model-count" style={{backgroundImage: `url(${modelInfo.coverUrl})`}}></div>
      {modelInfo && modelInfo.modelCount > 1 && (
        <div className="cover">
          <span>{formatCount(modelInfo.modelCount)}</span>
        </div>
      )}
      <div className="name">{modelInfo.groupName}</div>
      <div className="size">{formatSize(modelInfo.totalFileSize)}</div>
    </div>
  )
}

export default ComModelItem
