/** @format */

import {Icon} from 'antd-mobile'
import React from 'react'
import intl from 'react-intl-universal'
import './index.less'

interface Props {
  loading: boolean
}

const LoadingComp = (props: Props): JSX.Element => {
  const {loading} = props
  return (
    <div className="loading-comp">
      {loading ? (
        <div className="loading">
          <Icon type="loading" size="xs" />
          <span>{intl.get('comLoading.loading')}</span>
        </div>
      ) : (
        <span>{intl.get('comLoading.loaded')}</span>
      )}
    </div>
  )
}

export default LoadingComp
