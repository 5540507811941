/** @format */

import {isIOS, isAndroid} from './userAgentUtil'

// 检测消息是否可用
export function isAppMsgAlive(msgName) {
  return (
    window.webkit !== undefined &&
    window.webkit.messageHandlers !== undefined &&
    window.webkit.messageHandlers[msgName] !== undefined
  )
}

// 向原生App发送消息
export function postMsgToNative(msgName, param) {
  if (isIOS() && isAppMsgAlive(msgName)) {
    window.webkit.messageHandlers[msgName].postMessage(param)
  } else if (isAndroid()) {
    const AndroidInterface = getAndroidInterface()
    AndroidInterface[msgName](param)
  } else {
    console.log(`----------设备：PC,API：${msgName}(),参数：${decodeURI(param)}-------------------`)
  }
}

// 获取Android消息接口
export function getAndroidInterface() {
  return window.CXSWGroupInterface
}

// 在window对象上绑定app回调函数
export function addAppBridge(funName, funBody) {
  window[funName] = funBody
}

// 编码数据
export function encodeData(data) {
  if (!data) return ''
  if (!(typeof data === 'object')) return data
  return encodeURI(JSON.stringify(data))
}

// 解码
export function decodeData(data) {
  if (!data) return ''
  return JSON.parse(decodeURI(data))
}

// 页面跳转
export function nativeRouteTo(page, params) {
  const options = {
    type: page,
    params: params || {},
  }
  postMsgToNative('jumpPage', encodeData(options))
}

// 获取原生App请求头信息
export function getNativeAuthInfo(callback) {
  if (isIOS() || isAndroid()) {
    addAppBridge('getToken', auth => callback(auth))
  } else {
    callback(null)
  }
}
