/** @format */

export default {
  home: 'Home',
  mine: 'Me',
  share: 'Share',
  report: 'Report',
  confirm: 'Confirm',
  cancel: 'Cancel',
  quit: 'Quit',
  likePeople: 'Like',
  doSucc: 'Successful operation!',
  auditing2: 'under review',
  auditing: 'Audit',
  auditing2Tip: 'The group has been submitted and is under review',
  errNetwork: 'Network error',
  minute: 'minute ago',
  hour: 'hour ago',
  day: 'day ago',
  week: 'week ago',
  month: 'month ago',
  now: 'just now',
  systemErr: 'System error, please contact administrator',
  endpointErr: 'Your session has expired. Please relogin!',
  uploadErr: 'Upload failed,ID info error',

  comButton: {
    // 公共按钮
    join: 'Join',
    joined: 'Joined',
    follow: 'Follow',
    followed: 'Followed',
  },
  comLoading: {
    // 公共底部加载信息
    loading: 'Loading...',
    loaded: 'All data loaded',
    noData: 'No data',
  },
  homePage: {
    // 首页
    myCircle: 'My groups',
    findCircle: 'Find group',
    loadAll: 'All data loaded',
    noData: 'No data',
    searchPlaceholder: 'Search for the content you are interested in',
    share: 'Share',
    fullText: 'All text',
  },
  circleDetail: {
    // 圈子详情
    tab_all: 'All',
    tab_video: 'Video',
    join: 'Participate',
    noData: 'The group owner is too lazy, come to be the first~',
  },
  createCircle: {
    //创建圈子
    needCate: 'Please select group category',
    needName: 'Please enter the group name',
    needDesc: 'Please enter the group describe',
    needAvatar: 'Please upload the group avatar',
    createErr: 'Create failed',
    createSucc: 'Create success',
    auditTip: 'We will complete the audit within 2 working days',
    avatarMaxSize: 'Avatar size should not exceed 5M',
    create: 'Create group',
    confirm: 'Confirm',
    circleAvatar: 'group avatar',
    circleBg: 'Select the group background color',
    cate: 'Category',
    name: 'Name',
    namePlaceholder: 'Please enter the group name within 30 words',
    describe: 'Describe',
    describePlaceholder: 'Please enter the group describe',
    maxDescribe: 'Group descriptions are between 15 to 120 characters',
    location: 'Location',
    locationPlaceholder: 'Click get Location',
    createTip: 'Do you want to exit the create circle page?',
    tip: 'Tip'
  },
  findCircle: {
    // 发现圈子
    loading: 'Loading...',
    recommend: 'Recommend',
    create: 'Create',
    findCircle: 'Find group',
    searchPlaceholder: 'Search for the group you want to find',
  },
  circleMember: {
    // 圈子成员
    followSucc: 'Follow success',
    follow: 'Follow',
    followed: 'Followed',
    member: 'Group members',
  },
  myCircle: {
    // 我的圈子
    loading: 'Loading...',
    create: 'Create',
    myCircle: 'My groups',
    topCircle: 'Sticky groups',
    emptyText: 'Can put your favorite groups on the top',
    noJoinText: "You haven't joined any groups yet~",
    emptyListText: 'There are many funny groups, come and join~~',
    toJoin: 'Go join',
    toCreate: 'Go to create',
    joinCircle: 'Join group',
    joinTip: 'Are you sure to join the group?',
    joinedTip: 'You have joined the group',
    quitTip: 'Are you sure to quit?',
    quittedTip: 'You have exited from the group',
    quitCircle: 'Exit group',
    stick: 'Stick',
    cancelStick: 'Cancel stick',
    cancelStickTip: 'Are you sure you want to cancel stick the group?',
    stickTip: 'Are you sure you want to pin this group?',
    think: 'Cancel',
  },
  participate: {
    // 发布帖子
    saveTip: 'Do you want to keep this edit? After keeping, enter the page again to continue editing.',
    loadVideoRrr: 'Video failed to load!',
    uploadVideoRrr: 'Video upload failed!',
    releasing: 'Announcing...',
    releaseSucc: 'Release success!',
    release: 'Release',
    describePlaceholder: 'Add a description, it will be seen by more people...',
    cover: 'Select the cover',
    addTags: 'Add tags',
    addLocation: 'Add location',
    tag: 'Tag',
  },
  searchPost: {
    // 搜索帖子
    searchPlaceholder: 'Search posts in groups',
    noPostText: 'Open the wrong way, try another keyword',
  },
  postDetail: {
    //帖子详情
    associated: 'Connect groups',
    numComments: 'Comments',
    firstMan: 'Be the first to comment~',
    more: 'More comments',
    folded: 'Folded'

  },
  myPost: {
    // 我的帖子
    myPost: 'My posts',
  },
  userCircles: {
    //用户圈子
    myJoin: 'Me joined',
    myCreate: 'Me created',
    myCircle: 'My groups',
    noCircleText: 'No created groups~~~',
    noCircleText2: 'No created groups, go to create~~',
    joinBtnTxt: 'Go to join',
    createBtnTxt: 'Go to create',
  },
  errorPage: {
    noPage: 'Uh-oh, the page you visited no longer exists~~',
    otherOperation: 'You can try the following',
    goHome: 'Go home',
  },
  comComment: {
    oneDayBefore: 'A day ago',
    like: 'Like',
    author: 'Author'
  },
  comFormComfirm: {
    know: 'I know',
  },
  comFormModel: {
    createErr: 'Create failed',
  },
  comKeyboard: {
    write: 'Write comments...',
    release: 'Release',
  },
  comMapModel: {
    errLocation: 'Failed to locate the city!',
    errTip: 'Failed to obtain location, please search',
    hidden: 'Does not show my location',
    searchPlaceholder: 'Search location',
    emptyLocation: 'The location information is empty, please search again',
  },
  comTooltip: {
    saySomething: 'Say something...',
  },
  comSharePage: {
    download: 'Download immediately',
    creality: 'Creality Cloud | 3D Printing Industry Evangelist',
    open: 'Open the App',
    circlePage: 'Group page',
    postPage: 'Post page',
    model: 'Model group',
    post: 'Post',
    circle: 'Group',
    private: 'Contact',
    follow: 'Follow',
    man: 'Male',
    woman: 'Femal',
    fans: 'Fans',
    like: 'Like',
    pcTips: 'Please use your mobile to open the page!',
    crealityTitle: 'Creality Cloud',
    createText: 'Create Reality, Achieve Dreams',
    download2: ' Download',
    openApp: 'Open App browsing ',
    circles: ' Group',
    models: ' Model group',
    posts: ' Post',
    allModels: 'Open App to browse all model groups',
    allcircles: 'Open App to browse all circles',
    allPosts: 'Open App to browse all posts',
  },
}
