/** @format */

import PictureList from '@/components/picture-list'
import {postMsgToNative} from '@/utils/app-bridge'
import NativePage from '@/utils/enum/native-page'
import {formatMsgTime} from '@/utils/format'
import React, {useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import intl from 'react-intl-universal'
import './index.less'

interface Comment {
  replyComment?(commentId: number, replyId: number, nickName: string): void
  lookMore?(commentId: number, loading: boolean, page: number, toggle: boolean): void
  toggleLike?(id: number, type: string, isLike: boolean): void
  comment: any
  isSharePage?: boolean
  openPage?: Function
}

const CommentComp = (props: Comment) => {
  const userId = localStorage.getItem('__CXY_UID_')
  const history = useHistory()
  const {replyComment, lookMore, toggleLike, comment, openPage, isSharePage} = props
  const descRef: any = useRef(null)

  // 跳转个人app中心页面
  const goUserInfo = (id: any) => {
    if (isSharePage) {
      return history.push(`/share-center/${ id }`)
    }
    const param: any = {
      type: NativePage.userInfo,
      params: {
        userId: id,
      },
    }
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
  }

  const _replyComment = (commentId: number, replyId: number, nickName: string) => {
    if (isSharePage && openPage) {
      openPage()
      return
    }
    if (replyComment) replyComment(commentId, replyId, nickName)
  }

  const _lookMore = (commentId: number, loading: boolean, page: number, toggle: boolean) => {
    if (lookMore) lookMore(commentId, loading, page, toggle)
  }

  const _toggleLike = (id: number, type: string, isLike: boolean) => {
    if (isSharePage && openPage) return openPage()
    if (toggleLike) toggleLike(id, type, isLike)
  }

  useEffect(() => {
    // if (!descRef.current) return
    // const atUser: any[] = descRef.current.querySelectorAll('.at_user')
    // console.log(atUser)
    // if (atUser.length) {
    //   atUser.forEach(user => {
    //     const userid = user.dataset.userid
    //     user.addEventListener('click', (e: any) => {
    //       console.log("点到@" + userid)
    //       e.stopPropagation()
    //       if (isSharePage && openPage) {
    //         openPage()
    //         return
    //       }
    //       const param: any = {
    //         type: NativePage.userInfo,
    //         params: {
    //           userId: userid,
    //         },
    //       }
    //       postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
    //     })
    //   })
    // }
  }, [])

  return (
    <div className="comment-comp" data-id={comment.id}>
      {comment.userInfo && (
        <>
          <div className="comment-wrapper">
            <div
              className="cover"
              onClick={() => goUserInfo(comment.userInfo.userId)}
              style={{backgroundImage: `url(${comment.userInfo.avatar})`}}></div>
            <div className="comment">
              <div className="author" onClick={() => goUserInfo(comment.userInfo.userId)}>
                {comment.userInfo.nickName}
                {userId === comment.userId && <i className="icon">{intl.get('comComment.author')}</i>}
              </div>
              <div className="pubdate">
                <span className="text">{formatMsgTime(comment.createTime)}</span>
                <div className="good" onClick={() => _toggleLike(comment.id, 'comment', comment.isLike)}>
                  <i className={`icon ${comment.isLike ? 'like' : 'un_like'}`}></i>
                  <span className="num">
                    {comment.likeCount === 0 ? intl.get('comComment.like') : comment.likeCount}
                  </span>
                </div>
              </div>
              <p
                className="desc"
                ref={descRef}
                onClick={() => _replyComment(comment.id, 0, comment.userInfo.nickName)}
                dangerouslySetInnerHTML={{__html: comment.content}}></p>
              <PictureList List={comment.pictures || []} imgWidth="0.93rem" />
            </div>
          </div>
          {comment.replyList.map(
            (reply: any) =>
              reply.userInfo && (
                <div className="reply-wrapper" key={reply.id}>
                  <div
                    className="cover"
                    onClick={() => goUserInfo(reply.userInfo.userId)}
                    style={{backgroundImage: `url(${reply.userInfo.avatar})`}}></div>
                  <div className="comment" key={reply.id}>
                    <div className="author" onClick={() => goUserInfo(reply.userInfo.userId)}>
                      {reply.userInfo.nickName}
                      {userId === reply.userId && <i className="icon">{intl.get('comComment.author')}</i>}
                    </div>
                    <div className="pubdate">
                      <span className="text">{formatMsgTime(reply.createTime)}</span>
                      <div className="good" onClick={() => _toggleLike(reply.id, 'reply', reply.isLike)}>
                        <i className={`icon ${reply.isLike ? 'like' : 'un_like'}`}></i>
                        <span className="num">
                          {reply.likeCount === 0 ? intl.get('comComment.like') : reply.likeCount}
                        </span>
                      </div>
                    </div>
                    <p
                      className="desc"
                      ref={descRef}
                      onClick={() => _replyComment(reply.commentId, reply.id, reply.userInfo.nickName)}
                      dangerouslySetInnerHTML={{__html: reply.content}}></p>
                    <PictureList List={reply.pictures || []} imgWidth="0.78rem" />
                  </div>
                </div>
              ),
          )}
          {comment.replyCount > 3 ? (
            <div className="more" onClick={() => _lookMore(comment.id, comment.loading, comment.page, comment.toggle)}>
              <span>{comment.more}</span>
              <i className={`arrow ${comment.loading ? 'down' : 'up'}`}></i>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default CommentComp
