/** @format */

import CircleList from '@/components/circle-list'
import TextButton from '@/components/text-button'
import {nativeRouteTo, postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import {Circle, CircleClass} from '@/utils/data'
import CODE from '@/utils/enum/code'
import NativePage from '@/utils/enum/native-page'
import {getCircleList} from '@/utils/request/circle'
import {getClassList} from '@/utils/request/public'
import {NavBar, Toast, WhiteSpace} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import {useHistory} from 'react-router-dom'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import './index.less'

/**
 * 发现圈子
 */
const FindCircles = () => {
  const createPreIcon = require('../../assets/img/plus.png')
  const route = useHistory()
  // 初始化滑动组件
  const initSwiper = () => {
    new Swiper('.swiper-container', {
      loop: false,
      freeMode: false,
      slidesPerView: 'auto',
      cssMode: true,
    })
  }

  const [page, setPage] = useState(1)
  const pageSize = 10

  const [circleClassList, setCircleClassList] = useState<CircleClass[]>([])
  const [circleList, setCircleList] = useState<Circle[]>([])
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [classId, setClassId] = useState('0')

  useEffect(() => {
    initSwiper()
    loadCircleClassList()
  }, [])

  useEffect(() => {
    loadCircleList()
  }, [page, classId])

  function showLoading() {
    setLoading(true)
    Toast.loading(intl.get('findCircle.loading'))
  }

  function hideLoading() {
    setLoading(false)
    Toast.hide()
  }

  // 加载圈子分类列表
  async function loadCircleClassList() {
    const {code, msg, result} = await getClassList(1)
    if (code === CODE.SUCCESS) {
      const {list} = result
      list.unshift({id: '0', name: intl.get('findCircle.recommend')})
      setCircleClassList(list)
      if (list.length > 0) {
        const initClassId = list[0].id
        setClassId(initClassId)
      }
    } else {
      Toast.fail(msg)
    }
  }

  // 加载圈子列表 classId?: string;
  async function loadCircleList() {
    showLoading()
    const isRecommend = classId === '0'
    const {code, result, msg} = await getCircleList({
      page,
      pageSize,
      classId,
      isRecommend,
    })
    if (code === CODE.SUCCESS) {
      setCircleList([...circleList, ...result.list])
      setCount(parseInt(result.count))
    } else {
      Toast.fail(msg)
    }
    hideLoading()
  }

  // 重置
  function resetData() {
    if (page !== 1) setPage(1)
    if (circleList.length > 0) setCircleList([])
  }

  // 点击切换分类
  function onItemClick(_classId: string) {
    if (classId === _classId) return
    resetData()
    setClassId(_classId)
  }

  // 搜索圈子
  function submitSearch() {
    // 跳转到搜索页
    nativeRouteTo(NativePage.searchPage)
  }

  // 滑动到底部加载下一页
  function handleListScroll() {
    if (circleList.length < count && !loading) {
      setPage(page + 1)
    }
  }

  // 去创建圈子
  function goToCreate() {
    if (!cookieUtils.checkingLogin()) {
      nativeRouteTo(NativePage.login)
      return
    }
    route.push('/creat-circle')
  }

  const statusBarHeight = cookieUtils.getCookie('__CXY_STATUBARHEIGHT_')

  return (
    <div className="page-find-circles pd-top-44">
      <NavBar
        className="fixed-nav-bar"
        mode="light"
        leftContent={<span className="left-icon full-bg" onClick={() => postMsgToNative('closePage')} />}
        style={{paddingTop: statusBarHeight + 'px'}}
        rightContent={
          <TextButton
            text={intl.get('findCircle.create')}
            preIcon={createPreIcon}
            fontSize={16}
            iconSize={13}
            onClick={() => goToCreate()}
          />
        }>
        {intl.get('findCircle.findCircle')}
      </NavBar>
      <div className="fixed-container" style={{paddingTop: statusBarHeight + 'px'}}>
        <div className="circle-search-bar">
          <div className="search-bar-inner" onClick={() => submitSearch()}>
            <span className="search-icon full-bg" />
            <span>{intl.get('findCircle.searchPlaceholder')}</span>
          </div>
        </div>
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {circleClassList.map(item => (
              <div key={item.id} className="swiper-slider" onClick={() => onItemClick(item.id)}>
                <div className={`slide ${classId === item.id ? 'active' : ''}`}>
                  <span className="tag-name">{item.name}</span>
                  <span className="active-line" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="fixed-container-replace"></div>
      <WhiteSpace />
      <CircleList circleList={circleList} onScrollToBottom={() => handleListScroll()} />
    </div>
  )
}

export default FindCircles
