/** @format */

import CircleList from '@/components/circle-list'
import {nativeRouteTo} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import {Circle} from '@/utils/data'
import CODE from '@/utils/enum/code'
import NativePage from '@/utils/enum/native-page'
import {deepClone} from '@/utils/public'
import {getOwnerCircleList} from '@/utils/request/circle'
import {Button, Toast} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import {useParams} from 'react-router-dom'
import './index.less'

export default function UserCenterCircles() {
  const {userId} = useParams()

  const pageSize = 10
  const [page, setPage] = useState(1)

  const [isEmpty, setIsEmpty] = useState(false)
  const [loading, setLoading] = useState(false)

  const [centerCircles, setCenterCircles] = useState<Circle[]>([])
  const [count, setCount] = useState(0)

  async function loadUserCenterCircleList() {
    setLoading(true)
    const {code, msg, result} = await getOwnerCircleList({page, pageSize, userId, isHome: false, isNotCreated: false})
    if (code === CODE.SUCCESS) {
      const oldList = deepClone(centerCircles)

      setCenterCircles([...oldList, ...result.list])
      const listCount = parseInt(result.count)
      setCount(listCount)
      setIsEmpty(listCount === 0)
    } else {
      Toast.fail(msg)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (loading) {
      Toast.loading(intl.get('comLoading.loading'), 60)
    } else {
      Toast.hide()
    }
  }, [loading])

  useEffect(() => {
    loadUserCenterCircleList()
  }, [page])

  function onListScroll() {
    if (!loading && centerCircles.length < count) {
      setPage(page + 1)
    }
  }

  // 去加入
  function goToJoin() {
    if (!cookieUtils.checkingLogin()) {
      nativeRouteTo(NativePage.login)
      return
    }
    nativeRouteTo(NativePage.webPage, {url: '/find-circles'})
  }

  return !isEmpty ? (
    <div className="user-center-circle-layout">
      <CircleList circleList={centerCircles} onScrollToBottom={() => onListScroll()} />
    </div>
  ) : (
    <div className="user-circle-empty">
      <div className="list-empty-pic full-bg" />
      <div className="list-empty-tip">{intl.get('userCircles.noCircleText')}</div>
      <Button type="ghost" onClick={() => goToJoin()}>
        {intl.get('userCircles.joinBtnTxt')}
      </Button>
    </div>
  )
}
