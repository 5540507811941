/** @format */
import intl from 'react-intl-universal'

// 数字补零
function addZero(num: Number): String {
  return (num < 10 ? '0' + num : num).toString()
}

/**
 * 时间戳格式化
 * @param time 秒
 * @param full
 * @param symbol
 * @returns {string} 格式化后的日期字符串
 */
export const TiemFormat = (time: number, full = false, symbol = '.'): string => {
  const d = new Date(time * 1000)
  const year = d.getFullYear()
  const month = addZero(d.getMonth() + 1)
  const date = addZero(d.getDate())
  const hours = addZero(d.getHours())
  const minutes = addZero(d.getMinutes())
  const seconds = addZero(d.getSeconds())
  if (full) return `${year}${symbol}${month}${symbol}${date} ${hours}:${minutes}:${seconds}`
  return `${year}${symbol}${month}${symbol}${date}`
}
/**
 * 保留两位小数
 * @param {Number} x
 * @returns {string}
 */
export const toDecimal2 = (x: any): string => {
  let f = parseFloat(x)
  if (isNaN(f)) {
    return ''
  }
  f = Math.round(x * 100) / 100
  let s = f.toString()
  let rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length <= rs + 2) {
    s += '0'
  }
  return s
}

/**
 * 分转元
 * @param {Number} fen
 * @returns {Number} num
 */
export const regFenToYuan = (fen: number, toFixed = true): string => {
  let num = (fen * 0.01).toString()
  const reg = num.includes('.') ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g
  num = num.replace(reg, '$1')
  num = toDecimal2(num)
  if (!toFixed && num.split('.')[1] === '00') {
    return num.split('.')[0]
  }
  return num
}
/**
 * 元转分
 * @param {Number} yuan
 * @param {Number} digit
 * @returns {Number} 分
 */
export const regYuanToFen = (yuan: number, digit: number = 100): number => {
  let m = 0
  const s1 = yuan.toString()
  const s2 = digit.toString()
  try {
    m += s1.split('.')[1].length
    // eslint-disable-next-line no-empty
  } catch (e) {}
  try {
    m += s2.split('.')[1].length
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / 10 ** m
}

// 数字转成万
export const numToWan = (num: number) => {
  if (num < 10000) return num
  return Math.round(num / 10000) + 'W'
}

// 时间转换
export const formatMsgTime = (time: number) => {
  const dateTimeStamp = time * 1000
  var result = ''
  var minute = 1000 * 60
  var hour = minute * 60
  var day = hour * 24
  var month = day * 30
  var now = new Date().getTime()
  var diffValue = now - dateTimeStamp
  if (diffValue < 0) return
  var monthC = diffValue / month
  var weekC = diffValue / (7 * day)
  var dayC = diffValue / day
  var hourC = diffValue / hour
  var minC = diffValue / minute
  if (monthC >= 1) {
    result = '' + parseInt(monthC.toString()) + intl.get('month')
  } else if (weekC >= 1) {
    result = '' + parseInt(weekC.toString()) + intl.get('week')
  } else if (dayC >= 1) {
    result = '' + parseInt(dayC.toString()) + intl.get('day')
  } else if (hourC >= 1) {
    result = '' + parseInt(hourC.toString()) + intl.get('hour')
  } else if (minC >= 1) {
    result = '' + parseInt(minC.toString()) + intl.get('minute')
  } else {
    result = intl.get('now')
  }
  return result
}

export const likeFormat = (likeNum: number): string => {
  let format:string = '0'
  if (likeNum <= 0) {
    return format
  }
  // 如果数字超过1万
  if (likeNum >= 10000) {
    format = `${(likeNum / 10000).toFixed(1)}w`
  } else {
    format = likeNum + ''
  }
  return format
}
