/** @format */

function flexible(window: any, document: any) {
  const docEl: any = document.documentElement
  const dpr: number = window.devicePixelRatio || 1

  function setBodyFontSize() {
    if (document.body) {
      document.body.style.fontSize = 12 * dpr + 'px'
    } else {
      document.addEventListener('DOMContentLoaded', setBodyFontSize)
    }
  }
  setBodyFontSize()

  // set 1rem = viewWidth / 10
  function setRemUnit() {
    const rem = docEl.clientWidth / 3.75
    docEl.style.fontSize = rem + 'px'
  }

  setRemUnit()

  // reset rem unit on page resize
  window.addEventListener('resize', setRemUnit)
  window.addEventListener('pageshow', function (e: any) {
    if (e.persisted) {
      setRemUnit()
    }
  })

  // 禁用橡皮筋效果
  window.addEventListener(
    'touchmove',
    (ev: any) => {
      if (ev && ev.preventDefault && ev.persist) {
        ev.persist()
        ev.preventDefault()
      }
    },
    {passive: true},
  )

  // detect 0.5px supports
  if (dpr >= 2) {
    const fakeBody = document.createElement('body')
    const testElement = document.createElement('div')
    testElement.style.border = '.5px solid transparent'
    fakeBody.appendChild(testElement)
    docEl.appendChild(fakeBody)
    if (testElement.offsetHeight === 1) {
      docEl.classList.add('hairlines')
    }
    docEl.removeChild(fakeBody)
  }
}

export default flexible
