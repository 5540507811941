/**
 *  Created by wangAlen on 2019-12-17 11:32
 *
 * @format
 */
import intl from 'react-intl-universal'

import Compressor from 'compressorjs'
import OSS from 'ali-oss'
import {Toast} from 'antd-mobile'
import {reqAliyunInfo} from '@/utils/request/public'
import CODE from '@/utils/enum/code'

/**
 * 图片压缩
 * @param {Object} file
 * @returns {Promise<unknown>} file
 * @constructor
 */
export const CompressorImg = (file: File) =>
  new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      convertSize: 500 * 1024,
      success(result) {
        resolve(result)
      },
      error(err) {
        reject(err)
      },
    })
  })
/**
 * 阿里云接口构建oss对象
 * @param {Object} ctx
 * @param {String} fileType
 * @description 根据token请求oss接口，返回oss实列
 * @returns {Promise<{msg: (string|string|msg), status: string}>} Promise
 */

export const OssRequset = (ctx: any, fileType: string = 'image') => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (!ctx) {
      Toast.fail(intl.get('systemErr'))
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        status: 'error',
        msg: '错误信息：系统返回$axios,store为空',
      })
      return
    }
    const response = await reqAliyunInfo()
    if (response.code === CODE.SUCCESS) {
      const {accessKeyId, secretAccessKey, sessionToken} = response.result.aliyunInfo
      if (!accessKeyId || !secretAccessKey || !sessionToken) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          status: 'error',
          msg: '错误信息：系统返回accessKeyId/secretAccessKey/sessionToken为空',
        })
        Toast.fail(intl.get('systemErr'))
      }
      const endpoint = ctx.endpoint
      if (!endpoint) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          status: 'error',
          msg: '错误信息：系统AliOSS => endpoint为空',
        })
        Toast.fail(intl.get('endpointErr'))
      }
      const bucket = fileType === 'image' ? ctx.image.bucket : ctx.video.bucket
      resolve(
        new OSS({
          endpoint, // 云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，部署在服务端使用RAM子账号或STS，部署在客户端使用STS。
          accessKeyId,
          accessKeySecret: secretAccessKey,
          bucket,
          stsToken: sessionToken,
          secure: true,
        }),
      )
    } else if (response.CODE !== CODE.ERR_TOKEN) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        status: 'error',
        msg: response.msg,
      })
      Toast.fail(response.msg)
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        status: 'error',
        msg: '上传失败，网络错误',
      })
      Toast.fail(intl.get('uploadErr'))
    }
  })
}
