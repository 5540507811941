/** @format */

const useIsPc = () => {
  let isPc = false
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    //说明是移动端
    console.log('navigator.userAgent', navigator.userAgent)
    isPc = false
  } else {
    //说明是pc端
    isPc = true
  }
  return isPc
}

export default useIsPc
