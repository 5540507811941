/** @format */
import ButtonComp from '@/components/button-comp'
import ComShareFooter from '@/components/com-share-footer'
import ComShareTop from '@/components/com-share-top'
import FlodAvatar from '@/components/fold-avatar'
import LoadingComp from '@/components/loading-comp'
import ShareCardComp from '@/components/share-card-comp'
import Separator from '@/components/separator'
import useIsPc from '@/hooks/useIsPc'
import CODE from '@/utils/enum/code'
import {MTYPE} from '@/utils/enum/index'
import {likeFormat} from '@/utils/format'
import {openApp} from '@/utils/openApp.js'
import {circleDetails, getPostList} from '@/utils/request/circle-details'
import React, {MutableRefObject, useEffect, useRef, useState} from 'react'
import intl from 'react-intl-universal'
import {useParams} from 'react-router-dom'
import './index.less'

let page = 1
const pcCom = (): JSX.Element => {
  return <div className="pc-tips-container">{intl.get('comSharePage.pcTips')}</div>
}

const ShareCircle = (): JSX.Element => {
  const {id} = useParams()
  const tabs = [intl.get('circleDetail.tab_all'), intl.get('circleDetail.tab_video')]
  const PAGESIZE = 10

  // 获取Dom
  const headerRef: MutableRefObject<any> = useRef(null)
  const detailsRef: MutableRefObject<any> = useRef(null)
  const sliderRef: MutableRefObject<any> = useRef(null)
  const tabRef: MutableRefObject<any> = useRef(null)
  const tabsRef: MutableRefObject<any> = useRef(null)

  // state变量
  const [active, setActive] = useState(0)
  const [circleData, setCircleData] = useState<any>({})
  const [avatars, setAvatars] = useState<any[]>([])
  const [postList, setPostList] = useState<any[]>([])
  const [videoList, setVideoList] = useState<any[]>([])
  const [initPost, setInitPost] = useState(true)
  const [initVideo, setInitVideo] = useState(true)
  const [postActive, setPostActive] = useState(false)
  const [videoActive, setVideoActive] = useState(false)

  // 获取圈子数据
  const _initDetails = async () => {
    const {code, result} = await circleDetails({id})
    if (code === CODE.SUCCESS) {
      setCircleData(result.item)
      setAvatars(result.userList)
    }
  }

  // 设置tabs滑块的样式
  const setTabs = (index: number) => {
    sliderRef.current.style.width = tabRef.current.clientWidth * 0.3 + 'px'
    const left = tabRef.current.clientWidth * (index + 1 - 0.5) + 'px'
    sliderRef.current.style.left = left
  }

  // 获取帖子详情
  const _getPostList = async (mediaType: number) => {
    if (mediaType === MTYPE.ALL && postActive) return
    if (mediaType === MTYPE.VIDEO && videoActive) return
    const params = {
      page: page,
      pageSize: PAGESIZE,
      circleId: id,
      keyword: '',
      mediaType,
    }
    const {code, result} = await getPostList(params)
    if (code === CODE.SUCCESS) {
      if (mediaType === MTYPE.ALL) {
        setTabs(0)
        setPostActive(true)
        setPostList(result.list)
        setInitPost(false)
      }
      if (mediaType === MTYPE.VIDEO) {
        setVideoList(result.list)
        setInitVideo(false)
      }
    }
  }

  // 跳转圈子成员列表
  const onCircleMember = () => {
    openPage()
  }
  // 点击切换tabs
  const toggleTabs = (index: number) => {
    if (index === active) return
    setActive(index)
    setTabs(index)
    if (index === 0) {
      setTimeout(() => {
        _getPostList(MTYPE.ALL)
      }, 500)
    } else {
      setTimeout(() => {
        setVideoActive(true)
        _getPostList(MTYPE.VIDEO)
      }, 500)
    }
  }

  const openPage = () => {
    const jumpPage = 'shareCircle'
    const params = id
    openApp({jumpPage, params})
  }
  useEffect(() => {
    _initDetails()
    _getPostList(MTYPE.ALL)
  }, [])
  useEffect(() => {
    document.title = intl.get('comSharePage.circlePage')
  })
  return (
    <div className="share-circle-details">
      <ComShareTop openPage={openPage} />
      <div className="header" ref={headerRef} style={{backgroundColor: circleData.backColor}}>
        <div className="info">
          <div className="content">
            <div className="avatar" style={{backgroundImage: 'url(' + circleData.avatar + ')'}}></div>
            <div className="text">
              <h3>{circleData.name}</h3>
              {circleData.location && (
                <div className="location">
                  <span className="icon"></span>
                  <p>{circleData.location.poiName}</p>
                </div>
              )}
            </div>
          </div>
          <ButtonComp onClick={() => openPage()} status={-2} />
        </div>
      </div>
      <div className="main">
        <div className="title">
          <p>{circleData.description}</p>
          <div className="user">
            <div className="flod-avatar">
              <FlodAvatar avatarList={avatars} userCount={circleData.userCount} event={(): void => onCircleMember()} />
            </div>
            {Number(circleData.likeNumber) ? (
              <span className="like">
                {likeFormat(circleData.likeNumber)} {intl.get('likePeople')}
              </span>
            ) : null}
          </div>
        </div>
        <Separator />
        <div className="details" ref={detailsRef}>
          <div ref={tabsRef} className="tabs">
            {tabs.map((tab, index) => (
              <span
                key={index}
                ref={tabRef}
                className={`tabs-item ${active === index ? 'active' : ''}`}
                onClick={() => toggleTabs(index)}>
                {tab}
              </span>
            ))}
            <span className="slider" ref={sliderRef}></span>
          </div>
          <div className="tabs-content">
            {
              active === 0 && (postList.length ? postList.map(post => <ShareCardComp
                key={post.id}
                card={post}
                openPage={ openPage }
              />) : 
              initPost ? <LoadingComp loading={true} /> : <div className="empty">
                <img src={require('../../assets/img/no_post.png')} alt="" />
                <p>{intl.get('circleDetail.noData')}</p>
              </div>)
            }
            {
              active === 1 && (videoList.length ? videoList.map(video => <ShareCardComp
                key={video.id}
                card={video}
                openPage={ openPage }
              />) : 
              initVideo ? <LoadingComp loading={true} /> : <div className="empty">
                <img src={require('../../assets/img/no_post.png')} alt="" />
                <p>{intl.get('circleDetail.noData')}</p>
              </div>)
            }
          </div>
        </div>
      </div>
      <ComShareFooter openPage={openPage} />
    </div>
  )
}

export default useIsPc() ? pcCom : ShareCircle
