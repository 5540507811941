/** @format */

import empty from '@/assets/img/empty.png'
import {Button} from 'antd-mobile'
import React from 'react'
import intl from 'react-intl-universal'
import {useHistory} from 'react-router-dom'
import './index.less'

interface EmptyPorp {
  top?: number
  title: string
  isBtn?: boolean
}
const Error = (props: EmptyPorp): JSX.Element => {
  const {title, isBtn = false, top} = props
  const route = useHistory()
  return (
    <div className="empty-view-layout" style={{top: top}}>
      <div className="layout">
        <img src={empty} />
        <h1>{title}</h1>
        {isBtn ? (
          <Button
            type="ghost"
            onClick={() => {
              route.replace('/')
            }}
            inline
            size="small">
            {intl.get('errorPage.goHome')}
          </Button>
        ) : null}
      </div>
    </div>
  )
}
export default Error
