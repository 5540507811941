/** @format */

import post from '../http'

/**
 * 获取圈子列表
 * @param params
 */
const getCircleList = (
  params: {
    page: number // 页码
    pageSize: number // 每页条数
    keyword?: string // 搜索关键字
    classId?: string // 分类id
    isRecommend?: boolean // 是否推荐
    begin?: number // 起始
    end?: number // 结束
    userId?: string
  } = {page: 1, pageSize: 10},
) => post('/api/circle/circle/circleList', params)

/**
 * 获取圈子详情
 * @param params
 * @var id 圈子id
 */
const getCircleInfo = (params: {id: string}) => post('/api/circle/circle/circleInfo', params)

/**
 * 操作圈子
 * @param params
 * @var id 圈子id
 * @var action 操作类型 1-加入圈子 2-退出圈子 3-置顶圈子 4-取消置顶圈子
 */
const operateCircle = (params: {id: string; action: number}) => post('/api/circle/circle/circleOperating', params)

/**
 * 获取我的圈子列表
 * @param params
 * @var page 页码
 * @var pageSize 每页条数
 * @var isHome 是否用于首页
 * @var isOwner 是否只返回我创建的
 */
const getOwnerCircleList = (
  params: {
    page: number
    pageSize: number
    userId?: string
    isHome: boolean
    isNotCreated?: boolean
  } = {
    page: 1,
    pageSize: 10,
    isHome: false,
  },
) => post('/api/circle/circle/ownerList', params)

export {getCircleList, getCircleInfo, operateCircle, getOwnerCircleList}
