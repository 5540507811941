/** @format */

import ButtonComp from '@/components/button-comp'
import ComShareFooter from '@/components/com-share-footer'
import ComShareTop from '@/components/com-share-top'
import CommentComp from '@/components/comment-comp'
import GalleryComp from '@/components/gallery-comp'
import LoadingComp from '@/components/loading-comp'
import Separator from '@/components/separator'
import TooltipsComp from '@/components/tooltips-comp'
import useIsPc from '@/hooks/useIsPc'
import useScrollTop from '@/hooks/useScrollTop'
import {emoji} from '@/utils/emoji/index'
import CODE from '@/utils/enum/code'
import {openApp} from '@/utils/openApp.js'
import {commentList, postDetails} from '@/utils/request/circle-details'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import {useParams, useHistory} from 'react-router-dom'
import './index.less'

const pcCom = (): JSX.Element => {
  return <div className="pc-tips-container">{intl.get('comSharePage.pcTips')}</div>
}
const SharePost = () => {
  const {id} = useParams()
  const history = useHistory()
  const PAGESIZE = 10

  // state 变量
  const [init, setInit] = useState(true)
  const [post, setPost] = useState<any>({})
  const [comments, setComments] = useState<any[]>([])
  const [count, setCount] = useState(0)


  // 获取帖子详情
  const _postDetails = async () => {
    const {code, result} = await postDetails({id})
    if (code === CODE.SUCCESS) {
      setPost(result.item)
    }
  }

  // 获取评论详情
  const _commentList = async () => {
    const params = {
      page: 1,
      pageSize: PAGESIZE,
      blogId: id,
    }
    const emojiReg = /\[\![\u4E00-\u9FA5]+\]/g
    const atReg = /\[\%[0-9]+\]/g
    const {code, result} = await commentList(params)
    if (code === CODE.SUCCESS) {
      const commentList: any[] = result.list
      commentList.forEach(comment => {
        const atUserInfoList = comment.atUserInfoList
        // 解析表情
        comment.content = comment.content.replace(emojiReg, (a: string) => {
          let imgSrc: any = ''
          for (let i = 0; i < emoji.length; i++) {
            if (a === emoji[i].text) {
              imgSrc = emoji[i].image
              break
            }
          }
          return `<img src=${imgSrc}>`
        })
        // 解析@成员
        comment.content = comment.content.replace(atReg, (a: string) => {
          const userId = a.replace(/\[\%/, '').replace(/\]/, '')
          let nickName: string = ''
          for (let i = 0; i < atUserInfoList.length; i++) {
            if (userId === atUserInfoList[i].userId) {
              nickName = atUserInfoList[i].nickName
            }
          }
          return `<span class="at_user" data-userId=${userId}>@${nickName}</span>`
        })
      })
      setInit(false)
      setCount(result.count * 1)
      setComments(result.list)
    }
  }

  const jumpCircle = (circleId: number) => {
    history.push(`/share-circle/${circleId}`)
  }

  const openPage = () => {
    // 打开app
    const jumpPage = 'sharePost'
    const params = id
    openApp({jumpPage, params})
  }
  // 首次进入页面，返回顶部
  useScrollTop()

  useEffect(() => {
    _postDetails()
    _commentList()
  }, [])
  useEffect(() => {
    document.title = intl.get('comSharePage.postPage')
  })
  return (
    <div className="share-post-details">
      <ComShareTop openPage={openPage} />
      <div className="content">
        <div className="details">
          <p>{post.content}</p>
        </div>
        <GalleryComp media={post.media} mediaType={post.mediaType} isSharePage={true} openPage={openPage} />
      </div>
      <Separator />
      <div className="share">
        <span className="text">{intl.get('share')}</span>
        <i className="icon firends-circle" onClick={() => openPage()}></i>
        <i className="icon qq-zone" onClick={() => openPage()}></i>
      </div>
      <Separator />
      <div className="relevant">
        <h4 className="title">{intl.get('postDetail.associated')}</h4>
        {post.circle && (
          <div className="circle">
            <div
              className="cover"
              style={{backgroundImage: 'url(' + post.circle.avatar + ')'}}
              onClick={ () => jumpCircle(post.circle.id) } >
            </div>
            <div className="text" onClick={ () => jumpCircle(post.circle.id) }>
              <h5 className="subtitle">{post.circle.name}</h5>
              <p className="desc">{post.circle.description}</p>
            </div>
            {<ButtonComp onClick={() => openPage()} status={-2} />}
          </div>
        )}
      </div>
      <Separator />
      <div className="comments">
        <h4 className="title">
          {count} {intl.get('postDetail.numComments')}
        </h4>
        <div className="list">
          {
            comments.length ? comments.map(comment => <CommentComp
              key={comment.id}
              replyComment={() => openPage()}
              openPage={openPage}
              isSharePage={true}
              comment={comment}
            />) : 
            init ? <LoadingComp loading={true} /> : <div className="empty">
              <img src={require('../../assets/img/no_comment.png')} alt="" />
              <p>{intl.get('postDetail.firstMan')}</p>
            </div>
          }
        </div>
      </div>
      <div className="tooltips">
        {post.id && (
          <TooltipsComp
            id={id}
            toPostDetails={() => openPage()}
            commentPost={() => openPage()}
            shareCircle={() => openPage()}
            isLike={post.isLike}
            like={post.likeNumber}
            comment={post.commentNumber}
            share={post.sharedNumber}
            isSharePage={true}
            openPage={openPage}
          />
        )}
      </div>
      <ComShareFooter openPage={openPage} />
    </div>
  )
}

export default useIsPc() ? pcCom : SharePost
