/** @format */

import ButtonComp from '@/components/button-comp'
import Empty from '@/components/empty-view'
import GalleryComp from '@/components/gallery-comp'
import {postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import CODE from '@/utils/enum/code'
import {TOGGLE_TYPE} from '@/utils/enum/index'
import NativePage from '@/utils/enum/native-page'
import {getPostList} from '@/utils/request/circle-details'
import {follow} from '@/utils/request/public'
import highlight from '@/utils/showKeyWords'
import {Icon, ListView, Toast} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import {useParams} from 'react-router-dom'
import './index.less'

let page = 0
const pageSize = 10
const SearchPostNative = (): JSX.Element => {
  const {keyWords} = useParams()
  const key = decodeURI(keyWords)
  const myUserId = cookieUtils.getUserId()

  // 初始化上啦组件
  const ScrollData = new ListView.DataSource({
    rowHasChanged: (row1: any, row2: any) => row1 !== row2,
  })
  const [request, setRequest] = useState(true)
  const [postList, setPostList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [dataSource, setDataSource] = useState(ScrollData)
  // 关键字搜索帖子列表
  const searchPost = async (isScroll = false) => {
    if (!isLoading) return
    if (isScroll) {
      page++
    } else {
      page = 1
    }

    const param = {
      page,
      pageSize,
      keyword: key,
    }
    const {code, msg, result} = await getPostList(param)
    if (code !== CODE.SUCCESS) {
      Toast.fail(msg)
      return
    }
    // 如果加载到最后一页则停止加载数据
    if (page * pageSize >= result.count) {
      setIsLoading(false)
    }
    setRequest(false)
    setPostList([...postList, ...result.list])
    setDataSource(ScrollData.cloneWithRows([...postList, ...result.list]))
  }
  // 高亮关键字
  const SetHighKeyWords = (content: string): string => {
    return highlight(content, key, content.length)
  }
  // 跳转帖子详情页面
  const postDetail = (id: string) => {
    const param: any = {
      type: NativePage.webPage,
      params: {
        url: `/post-details/${id}`,
      },
    }
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
  }
  // 举报
  // 按钮操作，加入圈子或者点赞
  const toggleBtns = async (type: string, id: number, relationship?: number) => {
    // 关注与取消关注
    if (type === TOGGLE_TYPE.FOLLOW) {
      let action = false
      if (relationship === 0 || relationship === 2) {
        action = true
      }
      if (relationship === 1 || relationship === 3) {
        action = false
      }
      const params = {
        userIds: [id],
        action,
      }
      const {code} = await follow(params)
      if (code === CODE.SUCCESS) {
        const _postList: any[] = []
        postList.forEach((post: any) => {
          const relationship = post.authorUserInfo.relationship
          if (id === post.authorUserId) {
            if (relationship === 0 || relationship === 2) {
              post.authorUserInfo.relationship = 1
            }
            if (relationship === 1 || relationship === 3) {
              post.authorUserInfo.relationship = 0
            }
            // post.authorUserInfo.relationship = relationship === 0 ? 1 : 0
          }
          _postList.push(post)
        })
        setPostList([..._postList])
        setDataSource(ScrollData.cloneWithRows(JSON.parse(JSON.stringify(_postList))))
      }
    }
  }

  // 跳转个人app中心页面
  const goUserInfo = (id: any) => {
    const param: any = {
      type: NativePage.userInfo,
      params: {
        userId: id,
      },
    }
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
  }
  // 帖子数据row
  interface PostRow {
    rowData: any
    sectionID: any
    rowID: any
    onClick(): void
  }
  const PostRow: React.FC<PostRow> = props => {
    const {rowData, rowID, onClick} = props
    const item = rowData
    return (
      <div key={rowID} className="circle-item">
        <div className="item-title">
          <div className="circle-info">
            <img
              alt={item.authorUserInfo.avatar}
              onClick={() => goUserInfo(item.authorUserInfo.userId)}
              src={item.authorUserInfo.avatar}
            />
            <div className="info" onClick={() => postDetail(item.id)}>
              <p className="name">{item.authorUserInfo.nickName}</p>
            </div>
          </div>
          <div className="circle-btn">
            {Number(myUserId) !== Number(item.authorUserId) ? (
              <>
                <ButtonComp onClick={onClick} status={item.authorUserInfo.relationship} />
                {/* <div className="more" onClick={ showAction }></div> */}
              </>
            ) : null}
          </div>
        </div>
        <div className="circle-content">
          <div
            className="content"
            onClick={() => postDetail(item.id)}
            dangerouslySetInnerHTML={{__html: SetHighKeyWords(item.content)}}></div>
          <GalleryComp media={item.media} mediaType={item.mediaType} />
        </div>
      </div>
    )
  }
  useEffect(() => {
    searchPost()
  }, [])
  return (
    <div className="search-post-native-layout">
      {postList.length ? (
        <ListView
          dataSource={dataSource}
          renderFooter={() => (
            <>
              {isLoading ? (
                <>
                  <Icon type="loading" size="xs" />
                  <span>Loading...</span>
                </>
              ) : (
                intl.get('comLoading.loaded')
              )}
            </>
          )}
          renderRow={(rowData: any, sectionID: any, rowID: any) => (
            <PostRow
              onClick={() => toggleBtns(TOGGLE_TYPE.FOLLOW, rowData.authorUserId, rowData.authorUserInfo.relationship)}
              rowData={rowData}
              sectionID={sectionID}
              rowID={rowID}
            />
          )}
          useBodyScroll
          pageSize={pageSize}
          initialListSize={pageSize}
          onContentSizeChange={(contentWidth, contentHeight) => {
            console.log(contentHeight)
          }}
          onEndReached={() => searchPost(true)}
          onEndReachedThreshold={10}
        />
      ) : request ? null : (
        <Empty title={`${intl.get('searchPost.noPostText')}~`} />
      )}
    </div>
  )
}
export default SearchPostNative
