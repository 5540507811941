import post from '@/utils/http/'

// 获取圈子数据
interface CircleDetails {
  id: number
}
export const circleDetails = (params: CircleDetails) => {
  return post("/api/circle/circle/circleInfo", { id: params.id })
}

// 获取帖子列表
interface PostList {
  page: number
  pageSize: number
  circleId?: number
  keyword?: string
  mediaType?: number
  isOwner?: boolean
  userId?: number
  tagId?: number
}
export const getPostList = (params: PostList) => {
  const { page, pageSize, circleId, keyword, mediaType, isOwner, tagId, userId } = params
  return post("/api/circle/blog/blogList", { 
    page, pageSize, circleId, keyword, mediaType, isOwner, tagId, userId
  })
}

// 获取帖子详情
interface PostDetails {
  id: number
} 
export const postDetails = (params: PostDetails) => {
  return post("/api/circle/blog/blogInfo", { 
    id: params.id
  })
}

// 发表评论
interface PostReply {
  blogId: number,
  content: number,
  commentId: number,
  replyId?: number,
  pictures?: string[]
}
export const postReply = (params: PostReply) => {
  const { blogId, content, commentId, replyId, pictures } = params
  return post("/api/circle/blog/postReply", { 
    items: { blogId, content, commentId, replyId, pictures }
  })
}

// 获取评论列表
interface CommentList {
  page: number
  pageSize: number
  blogId: number
  sortBy?: number
}
export const commentList = (params: CommentList) => {
  const { page, pageSize, blogId, sortBy } = params
  return post("/api/circle/blog/CommentList", { 
    page, pageSize, blogId, sortBy
  })
}

// 帖子操作
interface Operapost {
  id: number
  action: number
}
export const operaPost = (params: Operapost) => {
  const { id, action } = params
  return post("/api/circle/blog/blogOperating", { 
    id, action
  })
}

// 圈子操作
interface Operacircle {
  id: number
  action: number
}
export const operaCircle = (params: Operacircle) => {
  const { id, action } = params
  return post("/api/circle/circle/circleOperating", { 
    id, action
  })
}

// 回复列表
interface ReplyList {
  page: number
  pageSize: number
  commentId: number
  sortBy?: number
}
export const replyList = (params: ReplyList) => {
  const { page, pageSize, commentId, sortBy } = params
  return post("/api/circle/blog/replyList", { 
    page, pageSize, commentId, sortBy
  })
}

// 评论点赞
interface LikeComment {
  commentId?: number,
  replyId?: number,
  isUnlike?: boolean
}
export const likeComment = (params: LikeComment) => {
  const { commentId, replyId, isUnlike } = params
  return post("/api/circle/blog/likeComment", { 
    commentId, replyId, isUnlike
  })
}
