/** @format */
import React from 'react'
import GalleryComp from '@/components/gallery-comp'
import {useHistory} from 'react-router-dom'
import intl from 'react-intl-universal'
import '../com-style/card-comp.less'

interface Props {
  card: any,
  openPage(): void
}

const ShareCardComp = (props: Props): JSX.Element => {
  const { card, openPage } = props
  const history = useHistory()

  const jumpCenter = () => {
    history.push(`/share-center/${card.authorUserId}`)
  }  

  const jumpPostDetails = () => {
    history.push(`/share-post/${card.id}`)
  }

  return (
    <div className="share-card-comp">
      <div className="card-item">
        <div className="card-header">
          <div 
            className="cover" 
            style={{backgroundImage: 'url(' + card.authorUserInfo.avatar + ')'}} 
            onClick={ () => jumpCenter() }>
          </div>
          <div className="name" onClick={ () => jumpCenter() }>
            {card.authorUserInfo.nickName}
          </div>
          <div className="button-comp" onClick={ () => openPage() }>
            { intl.get('comButton.follow') }
          </div>
        </div>
        <div className="desc" onClick={ () => jumpPostDetails() }>
          {card.content}
        </div>
        <GalleryComp tags={card.tags} media={card.media} mediaType={card.mediaType} />
        <div className="tooltips-comp" onClick={ () => openPage() }>
          <div className="write">
            <span className="pen"></span>
            <span>{intl.get('comTooltip.saySomething')}</span>
          </div>
          <div className="icons">
            <div className="like icon">
              { card.likeNumber }
            </div>
            <div className="comment icon">
              { card.commentNumber }
            </div>
            <div className="share icon">
              { card.sharedNumber }
            </div>
          </div>
        </div>
      </div>  
    </div>
  )
}

export default ShareCardComp
