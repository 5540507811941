/** @format */

const u = navigator.userAgent
// const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; // android终端或者uc浏览器
// const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
import {getPlatformType} from '@/utils/userAgentUtil'

// react 应用调原生应用方法
export const swicthToNativeApp = (funcName, data = null, data2 = null) => {
  try {
    // 如果是IOS设备
    if (getPlatformType(u) === 0 && window.webkit && window.webkit.messageHandlers) {
      eval('window.webkit.messageHandlers.' + funcName + '.postMessage("' + data + ',' + data2 + '")')
    } else if (getPlatformType(u) === 1 && window.CXSWGroupInterface) {
      // 如果是android设备
      eval('window.CXSWGroupInterface.' + funcName + '("' + data + '","' + data2 + '")')
    } else {
      console.log('pc')
    }
  } catch (error) {
    console.log(error)
  }
}

// 应用调react应用方法；原生app传递请求headers到react
export const AsyncToken = funcName => {
  //  如果是IOS设备
  if (getPlatformType(u) === 0) {
    window.getToken = token => {
      if (funcName) {
        funcName(token)
      }
    }
  } else if (getPlatformType(u) === 1) {
    //如果是android设备
  }
}
