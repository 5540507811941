/** @format */
let download = false
const allUrl = {
  android: {
    sharePost: '?type=3&url=' + encodeURI('/post-details/'),
    shareCircle: '?type=3&url=' + encodeURI('/circle-details/'),
    shareCenter: '?type=2&userId='
  },
  ios: {
    sharePost: '?type=3&url=' + encodeURI('/post-details/'),
    shareCircle: '?type=3&url=' + encodeURI('/circle-details/'),
    shareCenter: '?type=2&userId='
  }
}
let currentPage
let currentParams
export const openApp = ({isDownload=false, jumpPage='', params=''}) => {
  download = isDownload
  currentPage = jumpPage
  currentParams = params
  postRequest('/api/model/getGeo', respondAddress)
  // window.location = 'http://model-admin-dev.crealitygroup.com/download.html'
}
function openAppByUrl(appUrl, downloadUrl) {
  window.location = appUrl
}
function respondAddress(data, fun) {
  if (data) {
    let address = JSON.parse(data)
    const {code, result} = address
    let userAgent = navigator.userAgent
    let app = navigator.appVersion
    let platform = {
      // android终端
      android: userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1,
      // 是否为iPhone或者QQHD浏览器
      iPhone: userAgent.indexOf('iPhone') > -1,
      // 是否iPad
      iPad: userAgent.indexOf('iPad') > -1,
      isWeixin: userAgent.indexOf('MicroMessenger') > -1, //weixin
      isQQ: userAgent.match(/\sQQ/i) != null ? userAgent.match(/\sQQ/i).toLowerCase() == ' qq' : false, //qq
      isQQBrower: userAgent.indexOf('MQQBrowser') > -1, //QQ浏览器
      isUC: userAgent.indexOf('UCBrowser') > -1, //uc浏览器
      isBaidu: userAgent.indexOf('baidu') > -1, // 百度百度浏览器
    }
    let theUrl = '' // 下载地址
    let url = '' // 打开协议地址
    let country = 'china'
    if (code == 0) {
      country = result.country
      if (country.toLowerCase() == 'china') {
        //中国
        if (platform.android) {
          //安卓
          if (download) {
            theUrl = 'https://sj.qq.com/myapp/detail.htm?apkName=com.cxsw.sdprinter'
            window.location = theUrl
            return
          }
          url = 'cxsw://app.creality/open' + allUrl.android[currentPage] +  currentParams//打开app指定页面协议
          theUrl = 'https://file-cdn.creality.com/creality-cloud-china.apk' //下载地址
          if (false && platform.isUC || platform.isWeixin  || platform.isBaidu) {
            //不支持的uc浏览器，百度浏览器， 微信，QQ浏览器打开 直接提示下载
            document.write('该浏览器不支持打开应用，请用本地浏览器打开')
            window.location = theUrl
          } else {
            //安卓暂时发现 夸克浏览器,魅族自带浏览器，华为自带浏览器 ，搜狗浏览器，欧朋浏览器，360浏览器 能打开
            fun(url, theUrl, true)
          }
        } else {
          if (platform.iPhone || platform.iPad) {
            //iphone
            if (download) {
              theUrl = 'https://apps.apple.com/cn/app/creality-3d/id1483881440?l=zh&ls=1'
              window.location = theUrl
              return
            }
            // url = 'https://file-cdn.creality.com/' + allUrl.ios[currentPage] +  currentParams
            url = 'https://file-cdn.creality.com/cxsw/app_download.html' + allUrl.ios[currentPage] +  currentParams
            // theUrl = 'https://apps.apple.com/us/app/creality-3d/id1499175013?l=zh&ls=1'
            theUrl = 'https://apps.apple.com/cn/app/creality-3d/id1483881440?l=zh&ls=1'

            fun(url, theUrl)
          } else if (platform.iPad) {
            document.write('抱歉，暂时没有iPad版APP!')
            theUrl = ''
          } else {
            // pc
            if (download) {
              theUrl = 'https://sj.qq.com/myapp/detail.htm?apkName=com.cxsw.sdprinter'
              window.location = theUrl
              return
            }
          }
        }
      } else {
        //国外
        if (platform.android) {
          theUrl = 'https://play.google.com/store/apps/details?id=com.cxsw.sdprinter'
          window.location = theUrl
        } else {
          if (platform.iPhone || platform.iPad) {
            theUrl = 'https://apps.apple.com/us/app/id1499175013'
            window.location = theUrl
          } else if (platform.iPad) {
            document.write('抱歉，暂时没有iPad版APP!')
            theUrl = ''
          }
        }
      }
    } else {
      document.write('获取版本位置数据失败，请刷新')
    }
  }
}
function postRequest(url, fn) {
  let xhr = new XMLHttpRequest()
  xhr.open('POST', url, true)
  // 添加http头，发送信息至服务器时内容编码类型
  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4) {
      if (xhr.status == 200) {
        fn(xhr.responseText, openapp)
      }
    }
  }
  xhr.send('{}')
}
function openapp(appUrl, downloadUrl, isAndroid) {
  if (isAndroid) {
    //安卓
    //创建iframe
    try {
      let ifr = document.createElement('iframe')
      ifr.src = appUrl //安卓协议启动地址
      ifr.style.display = 'none' //隐藏
      document.body.appendChild(ifr)
    } catch (e) {
      window.location = downloadUrl
    }
  } else {
    window.location = appUrl
  }
  let loadDateTime = +new Date()
  // window.location = appUrl;//打开app
  setTimeout(function () {
    //定时器 3秒未启动调用
    let timeOutDateTime2 = +new Date()
    let a = timeOutDateTime2 - loadDateTime
    if (a < 4000) {
      // document.body.removeChild(ifr);
      window.location = downloadUrl //下载地址
    } else {
      window.close()
    }
  }, 3000)
}
