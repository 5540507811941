/** @format */

import en_us from '@/locale/en_US'
import zh_cn from '@/locale/zh_CN'
import CreatCircle from '@/pages/creat-circle/'
import Error from '@/pages/error'
import PostList from '@/pages/my-post'
import PostDetails from '@/pages/post-details'
import SearchPost from '@/pages/search-post'
import TagPostList from '@/pages/tag-post-list'
import cookieUtils from '@/utils/cookieUtils'
import React, {useEffect, useState} from 'react'
import {setConfig} from 'react-hot-loader'
import {hot} from 'react-hot-loader/root'
import intl from 'react-intl-universal'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import CircleDetails from './pages/circle-details/'
import CircleMember from './pages/circle-member'
import FindCircles from './pages/find-circles'
import Home from './pages/home/index'
import MyCircles from './pages/my-circles'
import SearchCircles from './pages/search-circles'
import SearchPostNative from './pages/search-post-native'
import SelectCircleTags from './pages/select-circle-tags'
import ShareCircle from './pages/share-circle'
import ShareHome from './pages/share-home'
import ShareCenter from './pages/share-my-center'
import SharePost from './pages/share-post'
import UserCenterCircles from './pages/user-center-circles'
import UserCircles from './pages/user-circles'

// import {loginV2} from './utils/request/public'
// import CODE from './utils/enum/code'
// import {isIOS, isAndroid} from '@/utils/userAgentUtil'

const locales = {
  'zh-CN': zh_cn,
  'en-US': en_us,
}

// 测试用登录
// async function testLogin() {
//   if (cookieUtils.checkingLogin()) return
//   const account = '8615921502616'
//   const password = 'hu123456'
//   const {code, msg, result} = await loginV2({account, password, type: 1})
//   if (code === CODE.SUCCESS) {
//     const expires = 3 * 24 * 60 * 60 * 1000
//     const {token, userId} = result
//     document.cookie = `__CXY_TOKEN_=${token};expires=${expires};path=/`
//     document.cookie = `__CXY_UID_=${userId};expires=${expires};path=/`
//   } else {
//     alert(msg)
//   }
// }

function App() {
  // 测试用登录
  // testLogin()

  const [initdone, setInitdone] = useState(false)
  const [topPadding, setTopPadding] = useState(0)

  useEffect(() => {
    const {pathname} = location

    let language
    const cookieLang = cookieUtils.getLang()
    if (cookieLang) {
      // 如果cookie里有值
      language = cookieLang === 0 ? 'en-US' : 'zh-CN'
    } else {
      // cookie里没有值
      const navLang =  navigator.language.substr(0, 2)
      if (navLang === 'zh') {
        language = 'zh-CN'
      } else {
        language = 'en-US'
      }
    }
    // cookieUtils.getLang() === 0 ? 'en-US' : 'zh-CN'

    const path = '/' + pathname.split('/')[1]
    const statusBarHeight: any = cookieUtils.getNativeStatusBarHeight(path)
    setTopPadding(statusBarHeight)
    // console.log('这是打印的cookie：' + document.cookie)
    // console.log('这是打印的localStorage：' + JSON.stringify(window.localStorage))
    intl
      .init({
        currentLocale: language,
        locales,
      })
      .then(() => setInitdone(true))
  }, [location])

  return (
    <div className="App" style={{paddingTop: Number(topPadding) > 0 ? topPadding + 'px' : ''}}>
      <BrowserRouter>
        <React.Fragment>
          <Switch>
            <Route path="/" exact component={Home}></Route>
            <Route path="/circle-details/:id/:type?" component={CircleDetails}></Route>
            <Route path="/creat-circle" component={CreatCircle}></Route>
            <Route path="/find-circles" component={FindCircles} />
            <Route path="/circle-member/:id" component={CircleMember} />
            <Route path="/my-circles" component={MyCircles} />
            <Route path="/search-post/:id/:navKeyword?" component={SearchPost} />
            <Route path="/post-details/:id/:type?" component={PostDetails} />
            <Route path="/my-post/:userId/:isMy?" component={PostList} />
            <Route path="/tag-post-list/:tagId" component={TagPostList} />
            <Route path="/user-circles" component={UserCircles} />
            <Route path="/search-post-native/:keyWords?" component={SearchPostNative} />
            <Route path="/user-center-circles/:userId" component={UserCenterCircles} />
            <Route path="/search-circles/:keyword" component={SearchCircles} />
            <Route path="/select-circle-tags" component={SelectCircleTags} />
            <Route path="/share-circle/:id" component={ShareCircle} />
            <Route path="/share-post/:id" component={SharePost} />
            <Route path="/share-center/:userId" component={ShareCenter} />
            <Route path="/share-home" component={ShareHome} />

            {/* 404错误页面 */}
            <Route path="/404" component={Error} />
            <Redirect from="*" to="/404" />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    </div>
  )
}

const HotApp = process.env.NODE_ENV === 'development' ? hot(App) : App

setConfig({
  reloadHooks: false,
})

export default HotApp
