/** @format */

import post from '../http'

/**
 * 创建圈子
 * @method
 */
interface circleMember {
  page: number
  pageSize: number
  id: number
}
export const circleMember = (params: circleMember) =>
  post('/api/circle/circle/circleUserList', {
    page: params.page,
    pageSize: params.pageSize,
    id: params.id,
  })
