/** @format */

// 深拷贝
const deepClone = (obj: object) => JSON.parse(JSON.stringify(obj))

// 创建唯一id
const createUid = (): string => {
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((Number(s[19]) & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  return s.join('')
}

// 计算base64文件大小
// const calcBase64Size = (base64Str: string): string => {
//   const len = base64Str.length
//   const fileLength = len - (len / 8) * 2
//   const size = (fileLength / 1024).toFixed(2)
//   return size + 'KB'
// }

export {deepClone, createUid}
