/** @format */
import {swicthToNativeApp} from '@/utils/native-application'
import React from 'react'
import './index.less'

interface List {
  imgWidth?: string
  List: string[]
}

const PictureList: React.FC<List> = props => {
  const {List, imgWidth} = props
  const setWidth = () => {
    if (imgWidth) {
      return imgWidth
    }
    if (Number(List.length) === 1) {
      return '2.25rem'
    } else {
      return '1.08rem'
    }
  }
  const onPreimage = (index: number) => {
    const isObject = List.every((item: any) => {
      return item.url
    })
    let listString = List.map((item: any) => {
      return item.url
    }).toString()
    if (!isObject) {
      listString = List.toString()
    }
    try {
      // console.log(`预览图片列表：${listString}`)
      // console.log(`预览的index：${index}`)
      swicthToNativeApp('previewImages', `${listString}`, index)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div className={`PictureList-layout ${Number(List.length) === 1 && 'x1'} ${Number(List.length) === 4 && 'x4'}`}>
        {List.map((item: any, index: number) => (
          <div key={index} className="img-box" style={{width: setWidth(), height: setWidth()}}>
            {item.url ? (
              <div onClick={() => onPreimage(index)} className="image" style={{backgroundImage: `url(${item.url})`}} />
              // <img onClick={() => onPreimage(index)} src={item.url} />
            ) : (
              <div onClick={() => onPreimage(index)} className="image" style={{backgroundImage: `url(${item})`}} />
              // <img onClick={() => onPreimage(index)} src={item} />
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default PictureList
