/**
 *  Created by wangAlen on 2020-04-23 15:45
 *
 * @format
 */
const getParser = (ua?: string) => {
  const uaParser = require('ua-parser-js')
  return uaParser(ua || navigator.userAgent)
}

const getOsObj = (ua?: string): {name: string; version: string} => getParser(ua).os

// 是否是安卓设备
export function isAndroid(ua?: string): boolean {
  const os = getOsObj(ua)
  const osName: string = os.name.toLowerCase()
  return osName.includes('android')
}

// 是否是iOS设备
export function isIOS(ua?: string): boolean {
  const os = getOsObj(ua)
  const osName: string = os.name.toLowerCase()
  return osName.includes('ios')
}

// 获取设备平台
export function getPlatformType(ua?: string): number {
  if (isIOS(ua)) return 0
  if (isAndroid(ua)) return 1
  return 4
}

// 获取操作系统
export function getOs(ua?: string): string {
  const os = getOsObj(ua)
  return `${os.name} ${os.version}`
}
