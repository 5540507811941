/**
 * 高亮搜索结果中的关键字
 *
 * @format
 * @author Created by wangAlen on 2019-12-27 15:11
 * @param {String} value  [需要处理的字符串]
 * @param {String} key    [关键字]
 * @param {Number} length [截取长度]
 * @return {string}          [返回一个包含HTML的字符串]
 */

export default (content: string, key: string, length: number): string => {
  const value = content.toString()
  if (key) {
    if (value.length > length) {
      if (value.indexOf(key) >= 0 && key.length > length) {
        return '<span style="color: #1987EA">' + value.substring(0, length) + '…' + '</span>'
      } else {
        return (
          value
            .substring(0, length)
            .split(key)
            .join('<span style="color: #1987EA">' + key + '</span>') + '…'
        )
      }
    } else {
      return value.split(key).join('<span style="color: #1987EA">' + key + '</span>')
    }
  } else {
    if (value.length > length) {
      return value.substring(0, length) + '…'
    } else {
      return value
    }
  }
}
