/** @format */

import CircleList from '@/components/circle-list'
import TextButton from '@/components/text-button'
import {nativeRouteTo, postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import {Circle} from '@/utils/data'
import CODE from '@/utils/enum/code'
import NativePage from '@/utils/enum/native-page'
import {getOwnerCircleList} from '@/utils/request/circle'
import {Button, NavBar, Toast, WhiteSpace} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import {useHistory} from 'react-router-dom'
import './index.less'

export default function MyCircles() {
  const createPreIcon = require('../../assets/img/plus.png')
  const pageSize = 10

  const [page, setPage] = useState(1)
  const [ownerCircleList, setOwnerCircleList] = useState<Circle[]>([])
  const [count, setCount] = useState(0)
  const [topCircleList, setTopCircleList] = useState<Circle[]>([])
  const [loading, setLoading] = useState(false)
  const [empty, setEmpty] = useState(false)
  const [reloadState, setReloadState] = useState(0)

  useEffect(() => {
    loadOwnerCircleList()
  }, [page, reloadState])

  // 加载我的圈子列表
  async function loadOwnerCircleList() {
    setLoading(true)
    Toast.loading(intl.get('myCircle.loading'))
    const {code, msg, result} = await getOwnerCircleList({page, pageSize, isHome: false})
    if (code === CODE.SUCCESS) {
      setOwnerCircleList([...ownerCircleList, ...result.list])
      if (page === 1) {
        setTopCircleList(result.topList)
      }
      const listCount = parseInt(result.count)
      setCount(listCount)
      setEmpty(listCount === 0)
    } else {
      Toast.fail(msg)
    }
    setLoading(false)
    Toast.hide()
  }

  // 上滑分页加载
  function handleListScroll() {
    if (ownerCircleList.length < count && !loading) {
      setPage(page + 1)
    }
  }

  // 圈子操作后重新加载
  function reloadData() {
    setOwnerCircleList([])
    setPage(1)
    setReloadState(reloadState + 1)
  }

  const history = useHistory()

  // 去创建圈子
  function goToCreate() {
    if (!cookieUtils.checkingLogin()) {
      nativeRouteTo(NativePage.login)
      return
    }
    history.push('/creat-circle')
  }

  const statusBarHeight = cookieUtils.getNativeStatusBarHeight()

  return (
    <div className="page-my-circles pd-top-44">
      <NavBar
        className="fixed-nav-bar"
        mode="light"
        leftContent={<span className="left-icon full-bg" onClick={() => postMsgToNative('closePage')} />}
        style={{paddingTop: statusBarHeight + 'px'}}
        rightContent={
          <TextButton
            text={intl.get('myCircle.create')}
            preIcon={createPreIcon}
            fontSize={16}
            iconSize={13}
            onClick={() => goToCreate()}
          />
        }>
        {intl.get('myCircle.myCircle')}
      </NavBar>
      <div className="top-circles">
        <span className="title-icon full-bg" />
        <div className="title-text">
          {intl.get('myCircle.topCircle')}({topCircleList.length}/5)
        </div>
      </div>
      {topCircleList.length > 0 ? (
        <CircleList
          circleList={topCircleList}
          isMine={true}
          isTop={true}
          needStickyBtn
          onCircleOperating={() => reloadData()}
        />
      ) : (
        <div className="top-list-empty">{intl.get('myCircle.emptyText')}~</div>
      )}
      <WhiteSpace />
      {!empty ? (
        <CircleList
          circleList={ownerCircleList}
          isMine={true}
          needStickyBtn
          onScrollToBottom={() => handleListScroll()}
          onCircleOperating={() => reloadData()}
        />
      ) : (
        <div className="list-empty">
          <div className="list-empty-pic full-bg" />
          <div className="list-empty-tip">
            {topCircleList.length > 0 ? intl.get('myCircle.emptyListText') : intl.get('myCircle.noJoinText')}
          </div>
          <Button
            type="ghost"
            onClick={() =>
              topCircleList.length > 0 ? nativeRouteTo(NativePage.webPage, {url: '/find-circles'}) : goToCreate()
            }>
            {topCircleList.length > 0 ? intl.get('myCircle.toJoin') : intl.get('myCircle.toCreate')}
          </Button>
        </div>
      )}
    </div>
  )
}
