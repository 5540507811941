/** @format */

// 圈子状态
export enum CIRCLE_STATUS {
  PENDING = 1, // 等待审核
  PASSED = 2, // 审核通过
  FAILED = 3, // 审核失败
  DELETED = 4, // 已删除
}

// 圈子操作
export enum CIRCLE_OPERATING {
  JOIN = 1, // 加入圈子
  QUIT = 2, // 退出圈子
  STICKY = 3, // 置顶圈子
  CANCEL_STICKY = 4, // 取消置顶
}

// 圈子详请
export enum MTYPE {
  ALL = 0, // 获取全部帖子
  VIDEO = 3, // 获取视频帖子
}

// 帖子操作
export enum POST_ACTION {
  LIKE = 1,
  NOLIKE = 2,
  SHARE = 3,
}

// 区分是加入圈子还是点赞
export enum TOGGLE_TYPE {
  JOIN = 'join',
  FOLLOW = 'follow',
}

// 点赞，取消点赞
export enum FOLLOWED {
  FOLLOW = 0,
  UNFOLLOW = 1,
}
