/** @format */

import CircleList from '@/components/circle-list'
import {Circle} from '@/utils/data'
import CODE from '@/utils/enum/code'
import {getCircleList} from '@/utils/request/circle'
import {Toast} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import {useParams} from 'react-router-dom'
import './index.less'

export default function SearchCriles() {
  const {keyword} = useParams()

  const pageSize = 10
  const [page, setPage] = useState(1)

  const [isEmpty, setIsEmpty] = useState(false)
  const [loading, setLoading] = useState(false)

  const [searchCircles, setSearchCircles] = useState<Circle[]>([])
  const [count, setCount] = useState(0)

  async function loadUserCenterCircleList() {
    setLoading(true)
    const {code, msg, result} = await getCircleList({page, pageSize, keyword: decodeURI(keyword)})
    if (code === CODE.SUCCESS) {
      setSearchCircles([...searchCircles, ...result.list])
      const listCount = parseInt(result.count)
      setCount(listCount)
      setIsEmpty(listCount === 0)
    } else {
      Toast.fail(msg)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (loading) {
      Toast.loading(intl.get('comLoading.loading'), 60)
    } else {
      Toast.hide()
    }
  }, [loading])

  useEffect(() => {
    loadUserCenterCircleList()
  }, [page])

  function onListScroll() {
    if (!loading && searchCircles.length < count) {
      setPage(page + 1)
    }
  }

  return !isEmpty ? (
    <CircleList circleList={searchCircles} onScrollToBottom={() => onListScroll()} needHighlight keyword={keyword} />
  ) : (
    <div className="search-circle-empty">
      <div className="list-empty-pic full-bg" />
      <div className="list-empty-tip">{intl.get('searchPost.noPostText')}~</div>
    </div>
  )
}
