/** @format */

import useIsLogin from '@/hooks/useIsLogin'
import CODE from '@/utils/enum/code'
import {POST_ACTION} from '@/utils/enum/index'
import {operaPost} from '@/utils/request/circle-details'
import React, {useState} from 'react'
import intl from 'react-intl-universal'
import './index.less'

interface Props {
  commentPost?(type: string): void
  toPostDetails?(): void
  shareCircle?(): void
  disabled?: boolean
  id: number
  isLike: boolean
  like: number
  comment: number
  share: number
  isSharePage?: boolean,
  openPage?: Function
}

const TooltipsComp = (props: Props) => {
  const {id, like, comment, isLike, share, disabled, shareCircle, openPage, commentPost, isSharePage} = props
  const [staticLike, setStaticLike] = useState(like)
  const [staticIsLike, setStaticIsLike] = useState(isLike)
  // 点赞
  const operatCircle = async () => {
    if (isSharePage && openPage) return openPage()
    if (!useIsLogin()) return
    const params = {
      id,
      action: isLike ? POST_ACTION.NOLIKE : POST_ACTION.LIKE,
    }
    const {code} = await operaPost(params)
    if (code === CODE.SUCCESS) {
      setStaticIsLike(!staticIsLike)
      staticIsLike ? setStaticLike(staticLike - 1) : setStaticLike(staticLike + 1)
    }
  }

  // 评论
  const _commentPost = (type: string) => {
    if (disabled && type === 'jump') return
    if (commentPost) commentPost(type)
  }

  return (
    <div className="tooltips-comp">
      <div className="write" onClick={() => _commentPost('click')}>
        <span className="pen"></span>
        <span>{intl.get('comTooltip.saySomething')}</span>
      </div>
      <div className="icons">
        <div className={`like icon ${staticIsLike ? 'active' : ''}`} onClick={() => operatCircle()}>
          {staticLike}
        </div>
        <div className="comment icon" onClick={() => _commentPost('jump')}>
          {comment}
        </div>
        <div className="share icon" onClick={shareCircle}>
          {share}
        </div>
      </div>
    </div>
  )
}

export default TooltipsComp
