/** @format */

/**
 * 原生app页面枚举值
 */
enum NativePage {
  login = 1, // 登录页面
  userInfo, // 个人中心
  webPage, // web页面
  searchPage, // 搜索页面页面
  participate, // 我要参与
  findCircles, // 发现圈子
  myCircles, // 我的圈子
  createCircle, // 创建圈子
  searchLocation, // 获取当前定位页面
  searchPost, // 搜索圈子里面的帖子
}

export default NativePage
