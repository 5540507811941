/** @format */
import NativePage from '@/utils/enum/native-page'
import {Toast} from 'antd-mobile'
import axios from 'axios'
import {nativeRouteTo} from '../app-bridge'
import {ResultData} from '../data'
import CODE from '../enum/code'
// import storage from '../storage'
import cookieUtils from '../cookieUtils'
const instance = axios.create({
  timeout: 20000, // 设置超时时间10s
})

instance.defaults.headers.post['Content-Type'] = 'application/json'

instance.interceptors.request.use((config: any) => {
  const authOptions: any = cookieUtils.getAuthOptions()
  // console.log("来自web http请求打印的cookie："+JSON.stringify(document.cookie.split(';')));
  Object.keys(authOptions).forEach(key => {
    if (authOptions[key] !== undefined) config.headers[key] = authOptions[key]
  })
  return config
})

/** 添加响应拦截器  **/
instance.interceptors.response.use(
  response => {
    if (response.status === 200) {
      const {code} = response.data
      if (code === CODE.ERR_TOKEN || code === CODE.INVALID_IDENTITY) {
        response.data.message = '登录已过期，请重新登录'
        // storage.clearToken()
        // storage.clearUserId()
        nativeRouteTo(NativePage.login)
        return Promise.reject(response.data.message)
      } else {
        return Promise.resolve(response)
      }
    } else {
      Toast.fail('响应超时')
      return Promise.reject(response.data.message)
    }
  },
  error => {
    if (error.response) {
      // 根据请求失败的http状态码去给用户相应的提示
      const status = error.response.status
      switch (status) {
        case 400:
          error.message = '错误请求'
          break
        case 401:
          error.message = '未授权，请重新登录'
          break
        case 403:
          error.message = '拒绝访问'
          break
        case 404:
          error.message = '请求错误,未找到该资源'
          break
        case 405:
          error.message = '请求方法未允许'
          break
        case 408:
          error.message = '网络请求超时，请刷新重试'
          break
        case 500:
          error.message = '服务器端出错，请检查网络'
          break
        case 501:
          error.message = '网络未实现'
          break
        case 502:
          error.message = '链接服务器失败，请检查网络'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '网络超时'
          break
        case 505:
          error.message = 'http版本不支持该请求'
          break
        default:
          error.message = `连接错误${error.response.status}`
      }
      Toast.fail(error.message)
    } else {
      // alert(JSON.stringify(error))
      Toast.fail('网络请求失败, 请刷新重试')
      // Toast.fail(JSON.stringify(error))
    }
    return Promise.reject(error)
  },
)

/**
 * post请求
 */
async function post(url: string, data: any = {}, timeout: number = 30000): Promise<ResultData | any> {
  instance.defaults.timeout = timeout
  const res = await instance.post(url, data)
  return res.data
}

export default post
