/** @format */

import {Button} from 'antd-mobile'
import React, {useEffect} from 'react'
import intl from 'react-intl-universal'
import './index.less'

interface FormComfirmProps {
  visible: boolean
  type: string
  title: string
  message?: string
  hide(): void
}
const FormComfirm: React.FC<FormComfirmProps> = props => {
  const {visible, type, title = '', message = '', hide} = props
  // const [show, setShow] = useState<any>(visible)
  // const modelRef: MutableRefObject<any> = useRef(null)
  // const setTop = () => {
  //   if (!modelRef.current) return
  //   const height = modelRef.current.clientHeight
  //   modelRef.current.style.marginTop =  `-${height / 2}px`
  // }
  useEffect(() => {
    if (visible) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }
  }, [visible])

  return visible ? (
    <div className="form-comfirm-box">
      {/* <div className="mask-box"></div> */}
      <div className="model-layout">
        <div className={`icon ${type}`}></div>
        <div className="titles">{title}</div>
        <div className="message">{message}</div>
        <div className="btn">
          <Button type="primary" onClick={() => hide()} className="round">
            {intl.get('comFormComfirm.know')}
          </Button>
        </div>
      </div>
    </div>
  ) : null
}

export default FormComfirm
