/** @format */

import TextButton from '@/components/text-button'
import {addAppBridge, encodeData, postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import {CircleTag, CircleTagClass} from '@/utils/data'
import CODE from '@/utils/enum/code'
import {deepClone} from '@/utils/public'
import {getCircleTagList} from '@/utils/request/circle-tag'
import {getClassList} from '@/utils/request/public'
import {NavBar, Toast} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import './index.less'

export default function SelectCircleTags() {
  const [activeIds, setActiveIds] = useState<string[]>([])
  const [circleTagList, setCircleTagList] = useState<CircleTagClass[]>([])
  const [ tagNumber, setTagNumber ] = useState<number>(0)
  const limit = 3

  // 加载标签分类
  async function loadTagsData() {
    const {code, msg, result} = await getClassList(3)
    if (code === CODE.SUCCESS) {
      const tagClasses = result.list
      loadCircleTagList(tagClasses)
    } else {
      Toast.fail(msg)
    }
  }

  // 加载标签列表
  async function loadCircleTagList(classList: Array<CircleTagClass>) {
    const {code, msg, result} = await getCircleTagList({page: 1, pageSize: 200})
    if (code === CODE.SUCCESS) {
      const tagList: Array<CircleTag> = result.list
      classList.forEach(tagClass => {
        const children = tagList.filter(tag => tagClass.id === tag.classId)
        if (children.length) tagClass.children.push(...children)
      })
      setCircleTagList(classList)
    } else {
      Toast.fail(msg)
    }
  }

  useEffect(() => {
    loadTagsData()
    addAppBridge('sendSelectedTags', (data: any) => {
      if (!data) return
      const tagList: CircleTag[] = JSON.parse(decodeURIComponent(data))
      const tagIds = tagList.map(tag => tag.id)
      setActiveIds(tagIds)
    })
  }, [])

  // 选中标签
  const onTagSelect = (tagId: string) => {
    const ids: string[] = deepClone(activeIds)
    if (ids.includes(tagId)) {
      const i = ids.findIndex(id => id === tagId)
      setTagNumber(tagNumber - 1)
      ids.splice(i, 1)
    } else {
      
      if (ids.length < limit) {
        setTagNumber(tagNumber + 1)
        ids.push(tagId)
      }  
    }
    setActiveIds(ids)
  }

  // 获取选中标签
  function getSelectedTags() {
    const tags: CircleTag[] = []
    circleTagList.forEach(tagClass => {
      if (tagClass.children.length > 0) {
        tagClass.children.forEach((tag: CircleTag) => {
          if (activeIds.includes(tag.id)) {
            tags.push(tag)
          }
        })
      }
    })
    return tags
  }

  // 确认选择
  const onClickSubmit = () => {
    postCircleTags(1, getSelectedTags())
    setActiveIds([])
  }

  // 关闭
  function handleClose() {
    postCircleTags(0, getSelectedTags())
    setActiveIds([])
  }

  // 将选中标签列表发送给原生app
  function postCircleTags(code: number, tagList: CircleTag[]) {
    const params = {
      code, // 0-关闭，1-选中
      list: tagList, // 标签列表
    }
    postMsgToNative('sendSelectedTags', encodeData(params))
  }

  const statusBarHeight = cookieUtils.getNativeStatusBarHeight()

  return (
    <div className="page-select-circle-tags pd-top-44">
      <div className="fixed-nav-mask" style={{height: statusBarHeight + 'px'}} />
      <NavBar
        className="fixed-nav-bar"
        mode="light"
        leftContent={<span className="left-icon full-bg" onClick={() => handleClose()} />}
        style={{top: statusBarHeight + 'px'}}
        rightContent={
          <TextButton
            text={intl.get('confirm') +`(${ tagNumber }/${ limit })`}
            fontSize={16}
            onClick={() => onClickSubmit()}
            disabled={activeIds.length < 1}
          />
        }>
        {intl.get('participate.tag')}
      </NavBar>
      <div className="circle-tags-container">
        {circleTagList.map(tagClass => (
          <div className="tag-class-item" key={tagClass.id}>
            <div className="tag-class-name">{tagClass.name}</div>
            <div className="tag-children-box">
              {tagClass.children.map((tag: CircleTag) => {
                return (
                  <span
                    key={tag.id}
                    className={`tag-item ${activeIds.includes(tag.id) ? 'active' : ''}`}
                    onClick={() => onTagSelect(tag.id)}>
                    {tag.name}
                  </span>
                )
              })}
            </div>
            <div className="tag-line" />
          </div>
        ))}
      </div>
    </div>
  )
}
