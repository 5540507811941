/** @format */

import CardComp from '@/components/card-comp'
import NavBar from '@/components/nav-bar'
import useIsLogin from '@/hooks/useIsLogin'
import {postMsgToNative} from '@/utils/app-bridge'
import CODE from '@/utils/enum/code'
import {TOGGLE_TYPE} from '@/utils/enum/index'
import {getPostList} from '@/utils/request/circle-details'
import {follow} from '@/utils/request/public'
import {ActionSheet, Icon, ListView, Toast} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import {useHistory, useParams} from 'react-router-dom'
import './index.less'

let page = 0
const pageSize = 5 // 每次加载的数据数量
const TagPostList = (): JSX.Element => {
  const route = useHistory()
  const {tagId} = useParams()
  // 初始化上啦组件
  const ScrollData = new ListView.DataSource({
    rowHasChanged: (row1: any, row2: any) => row1 !== row2,
  })

  const [isLoading, setIsLoading] = useState(true)
  const [tagName, setTagName] = useState('')
  const [] = useState(false)
  const [list, setList] = useState<any>([])
  const [dataSource, setDataSource] = useState(ScrollData)

  // 获取帖子列表数据
  const initCircleList = async (isScroll = false) => {
    if (!isLoading) {
      return
    }
    if (isScroll) {
      page++
    } else {
      page = 1
    }
    const {code, msg, result} = await getPostList({
      page,
      pageSize,
      tagId,
    })
    if (code !== CODE.SUCCESS) {
      Toast.fail(msg)
      return
    }
    const NewList: any = [...list, ...result.list]
    NewList.forEach((item: any) => {
      item.tags.forEach((tag: any) => {
        if (tag.id === tagId) {
          setTagName(tag.name)
        }
      })
    })
    setDataSource(ScrollData.cloneWithRows(NewList))
    setList(NewList)
    if (result.list.length < pageSize) {
      setIsLoading(false)
    }
  }

  // 按钮操作，加入圈子或者点赞
  const toggleBtns = async (type: string, id: number, relationship?: number) => {
    // 关注与取消关注
    if (type === TOGGLE_TYPE.FOLLOW) {
      let action = false
      if (relationship === 0 || relationship === 2) {
        action = true
      }
      if (relationship === 1 || relationship === 3) {
        action = false
      }
      const params = {
        userIds: [id],
        action,
      }
      const {code} = await follow(params)
      if (code === CODE.SUCCESS) {
        const _postList: any[] = []
        list.forEach((post: any) => {
          const relationship = post.authorUserInfo.relationship
          if (id === post.authorUserId) {
            if (relationship === 0 || relationship === 2) {
              post.authorUserInfo.relationship = 1
            }
            if (relationship === 1 || relationship === 3) {
              post.authorUserInfo.relationship = 0
            }
            // post.authorUserInfo.relationship = relationship === 0 ? 1 : 0
          }
          _postList.push(post)
        })
        setList([..._postList])
        setDataSource(ScrollData.cloneWithRows(JSON.parse(JSON.stringify(_postList))))
      }
    }
  }

  // 举报
  const showAction = () => {
    const BUTTONS = [intl.get('report'), intl.get('cancel')]
    ActionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        cancelButtonIndex: 1,
        maskClosable: true,
      },
      buttonIndex => {
        if (buttonIndex === 0) {
          // ...举报
        }
      },
    )
  }

  // 评论
  const commentPost = (id: number, type: string) => {
    if (type === 'click') {
      if (!useIsLogin()) return
      postMsgToNative('commentPost', `${id}`)
    }
    if (type === 'jump') {
      route.push(`/post-details/${id}/h5`)
    }
  }

  useEffect(() => {
    initCircleList()
  }, [])
  return (
    <div className="tag-post-list-layout">
      <NavBar isNativeBack={true} title={tagName} />
      {list && list.length ? (
        <ListView
          dataSource={dataSource}
          renderFooter={() => (
            <>
              {isLoading ? (
                <>
                  <Icon type="loading" size="xs" />
                  <span>Loading...</span>
                </>
              ) : (
                intl.get('comLoading.loaded')
              )}
            </>
          )}
          renderRow={(rowData, rowID) => (
            <CardComp
              commentPost={type => commentPost(rowData.id, type)}
              onClick={() => toggleBtns(TOGGLE_TYPE.FOLLOW, rowData.authorUserId, rowData.authorUserInfo.relationship)}
              showAction={() => showAction()}
              key={rowID}
              card={rowData}
            />
          )}
          pageSize={pageSize}
          style={{
            overflow: 'auto',
          }}
          useBodyScroll
          initialListSize={pageSize}
          onContentSizeChange={(contentWidth, contentHeight) => {
            console.log(contentHeight)
          }}
          onEndReached={() => initCircleList(true)}
          onEndReachedThreshold={0}
        />
      ) : !isLoading ? (
        <div className="null-circle-list">{intl.get('comLoading.noData')}</div>
      ) : null}
    </div>
  )
}
export default TagPostList
