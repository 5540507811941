import post from '../http'

/**
 * 创建圈子
 * @method
 */
interface createCircle {
  name: string
  avatar: string
  backColor: string
  description: string
  classId: number
  isAllowComment?: boolean
  location?: object
}
export const createCircle = (params: createCircle) => 
  post('/api/circle/circle/createCircle', {
    item: {
      name: params.name,
      avatar: params.avatar,
      backColor: params.backColor,
      description: params.description,
      classId: params.classId,
      isAllowComment: params.isAllowComment,
      location: params.location,
    }
  })
