/** @format */

import ComShareFooter from '@/components/com-share-footer'
import ComShareTop from '@/components/com-share-top'
import Empty from '@/components/empty-view'
import ComModelItem from '@/components/model-item'
import ShareCardComp from '@/components/share-card-comp'
import useIsPc from '@/hooks/useIsPc'
import CODE from '@/utils/enum/code'
import {numToWan} from '@/utils/format'
import {openApp} from '@/utils/openApp.js'
import {getPostList} from '@/utils/request/circle-details'
import {getModelList, getOwnerCircleList, getUserInfo} from '@/utils/request/share'
import {Button, Toast, WhiteSpace} from 'antd-mobile'
import React, {useEffect, useState, useRef} from 'react'
import intl from 'react-intl-universal'
import {useParams, useHistory} from 'react-router-dom'
import LoadingComp from '@/components/loading-comp'
import './index.less'

const page = 1
const pageSize = 10 // 每次加载的数据数量
const pcCom = (): JSX.Element => {
  return <div className="pc-tips-container">{intl.get('comSharePage.pcTips')}</div>
}
const ShareCenter = (): JSX.Element => {
  const {userId} = useParams()
  const history = useHistory()
  // 初始化上啦组件
  const [postList, setPostList] = useState<any[]>([])
  // 帖子请求结果
  const [request, setRequest] = useState(true)
  // 用户信息
  const [userInfo, setUserInfo] = useState<any>({})
  // tab标签底部划线偏移量
  const [activeType, setActiveType] = useState('t1')
  // 圈子请求结果
  const [circleRequest, setCircleRequest] = useState(true)
  const [circleList, setCircleList] = useState<any[]>([])
  // 模型请求结果
  const [modelRequest, setModelRequest] = useState(true)
  const [modelList, setModelList] = useState<any[]>([])

  const [title, setTitle] = useState<string>()
  const [top, setTop] = useState<number>()
  const userRef: any = useRef()
  const tabsRef: any = useRef()
  const sliderRef: any = useRef()

  const formatNum = (num: number) => {
    // 数量转换
    if (!num) return '0'
    if (num > 100000) {
      return '10W+'
    } else if (num === 100000) {
      return '10W'
    } else if (num > 10000) {
      return '1W+'
    } else if (num === 10000) {
      return '1W'
    } else {
      return num
    }
  }
  const tabs = [
    {title: <span>{`${formatNum(userInfo.modelSharedCount)} ${intl.get('comSharePage.model')}`}</span>, key: 't1'},
    {title: <span>{`${formatNum(userInfo.blogCount)} ${intl.get('comSharePage.post')}`}</span>, key: 't2'},
    {title: <span>{`${formatNum(userInfo.circleCount)} ${intl.get('comSharePage.circle')}`}</span>, key: 't3'},
  ]
  // 获取帖子列表数据
  const initPostList = async () => {
    if (postList.length) return
    const {code, msg, result} = await getPostList({
      page,
      pageSize,
      userId: userId && parseInt(userId),
    })
    if (code !== CODE.SUCCESS) {
      Toast.fail(msg)
      return
    }
    const _title: string = intl.get('comSharePage.openApp') + result.count + intl.get('comSharePage.posts')
    result.list.length ? setTitle(_title) : setTitle(intl.get('comSharePage.allPosts'))
    setRequest(false)
    setPostList(result.list)
  }
  // 获取模型列表
  const initModelList = async () => {
    if (modelList.length) return
    const params = {
      page,
      pageSize,
      userId: userId && parseInt(userId),
      version: 1,
    }
    const {code, msg, result} = await getModelList(params)
    if (code !== CODE.SUCCESS) {
      Toast.fail(msg)
      return
    }
    const _title: string = intl.get('comSharePage.openApp') + result.count + intl.get('comSharePage.models')
    result.list.length ? setTitle(_title) : setTitle(intl.get('comSharePage.allModels'))
    setModelRequest(false)
    setModelList(result.list)
  }
  // 获取圈子列表
  const initCircleList = async () => {
    if (circleList.length) return
    const {code, msg, result} = await getOwnerCircleList({
      page,
      pageSize,
      isHome: false,
      userId: userId && parseInt(userId),
    })
    if (code !== CODE.SUCCESS) {
      Toast.fail(msg)
      return
    }
    const _title: string = intl.get('comSharePage.openApp') + result.count + intl.get('comSharePage.circles')
    result.list.length ? setTitle(_title) : setTitle(intl.get('comSharePage.allcircles'))
    setCircleRequest(false)
    setCircleList(result.list)
  }
  // 获取用户信息
  const _initDetails = async () => {
    const {code, msg, result} = await getUserInfo(parseInt(userId))
    if (code !== CODE.SUCCESS) {
      Toast.fail(msg)
      return
    }
    setUserInfo(result.userInfo)
    setTabs(0)
  }

  const openPage = () => {
    // 打开app页面
    const jumpPage = 'shareCenter'
    const params = userId
    openApp({jumpPage, params})
  }
  const jumpCircle = (circleId: number) => {
    history.push(`/share-circle/${ circleId }`)
  }
  // 设置tabs滑块的样式
  const setTabs = (index: number) => {
    const currentTab = tabsRef.current.children[index]
    const left = currentTab.offsetLeft + currentTab.clientWidth / 2 + 'px'
    sliderRef.current.style.left = left
  }
  const tabsChange = (tab: any, index: number) => {
    const key = tab.key
    setTabs(index)
    setActiveType(key)
    switch(key) {
      case 't1':
        initModelList()
        break
      case 't2':
        setTimeout(() => {
          initPostList()
        }, 500)
        break  
      case 't3':
        setTimeout(() => {
          initCircleList()
        }, 500)
        break    
      default:
        return null  
    }
  }
  useEffect(() => {
    const top = userRef.current.clientHeight + tabsRef.current.clientHeight
    setTop(top)
    _initDetails()
    initModelList()
  }, [])
  useEffect(() => {
    document.title = intl.get('comSharePage.homePage')
  })
  return (
    <div className="share-center-container">
      <ComShareTop openPage={openPage} />

      <div className="user-detail" ref={ userRef }>
        <div className="avatar-container">
          <div className="avatar">
            <div style={{backgroundImage: 'url(' + userInfo.userAvatar + ')'}} className="avatar-img"></div>
            {/* <img src={userInfo.userAvatar} alt=""/> */}
          </div>
          <div className="btn-container">
            <div
              className="btn-private"
              onClick={() => {
                openPage()
              }}>
              {intl.get('comSharePage.private')}
            </div>
            <div
              className="btn-follow"
              onClick={() => {
                openPage()
              }}>
              + {intl.get('comSharePage.follow')}
            </div>
          </div>
        </div>
        <div className="user-name">{userInfo.nickName}</div>
        <div className="user-introduce">{userInfo.introduction}</div>
        <div className="user-simple">
          {userInfo.sex && userInfo.sex !== 0 && (
            <span className="sex">
              {userInfo.sex === 1 ? intl.get('comSharePage.woman') : intl.get('comSharePage.man')}
            </span>
          )}
        </div>
        <div className="user-account">
          <div className="item">
            <div className="num">{formatNum(userInfo.followCount)}</div>
            <div className="type">{intl.get('comSharePage.follow')}</div>
          </div>
          <div className="item">
            <div className="num">{formatNum(userInfo.fansCount)}</div>
            <div className="type">{intl.get('comSharePage.fans')}</div>
          </div>
          <div className="item">
            <div className="num">{formatNum(userInfo.likeCount)}</div>
            <div className="type">{intl.get('comSharePage.like')}</div>
          </div>
        </div>
      </div>
      <div className="tabs-container" ref={ tabsRef }>
        {
          tabs.map((tab, index) => <div 
            key={ tab.key }
            className={`tabs-item ${activeType === tab.key ? 'active' : ''}`} 
            onClick={ () => tabsChange(tab, index) }>
            { tab.title }
          </div>)
        }
        <div className="slider" ref={ sliderRef }></div>
      </div>
      <WhiteSpace />
      {
        activeType === 't2' && (
          <div className="share-post-list-layout">
            {
              postList.length ? postList.map(post => <ShareCardComp
                key={post.id}
                card={post}
                openPage={ openPage }
              />) :
              request ? <LoadingComp loading={true} /> : <Empty top={ top } title={`${intl.get('comLoading.noData')}~`} />
            }
          </div>
        )
      }
      {
        activeType === 't3' && (
          <div className="share-page-my-circles">
            {
              circleList.length ? circleList.map(circle => <div className="circle-item" key={ circle.id }>
                <img className="circle-cover" src={circle.avatar} alt="" onClick={() => jumpCircle(circle.id)} />
                <div className="circle-info" onClick={() => jumpCircle(circle.id)}>
                  <div className="name">{circle.name}</div>
                  <div className="intro">{circle.description}</div>
                  <div className="like-count">
                    {numToWan(circle.likeNumber)} {intl.get('likePeople')}
                  </div>
                </div>
                <div className="btn-join" onClick={() => { openPage() }}>
                  {intl.get('comButton.join')}
                </div>
              </div>) :
              circleRequest ?  <LoadingComp loading={true} /> :
              <div className="list-empty">
                <div className="list-empty-pic full-bg" />
                <div className="list-empty-tip">{intl.get('myCircle.noJoinText')}</div>
                <Button type="ghost" onClick={() => openPage()}>
                  {intl.get('myCircle.toJoin')}
                </Button>
              </div>
            }
          </div>
        )
      }
      {
        activeType === 't1' && (
          <div className="model-list-container">
            {
              modelList.length ? modelList.map(model => <div key={model.groupId} className="model-item">
                <ComModelItem openPage={openPage} modelInfo={model} />
              </div>) :
              modelRequest ? null : <Empty top={ top } title={`${intl.get('comLoading.noData')}~`} />
            }
          </div>
        )
      }
      <ComShareFooter title={ title } openPage={openPage} />
    </div>
  )
}
export default useIsPc() ? pcCom : ShareCenter
