/** @format */

import ButtonComp from '@/components/button-comp'
import CommentComp from '@/components/comment-comp'
import GalleryComp from '@/components/gallery-comp'
import LoadingComp from '@/components/loading-comp'
import Separator from '@/components/separator'
import TooltipsComp from '@/components/tooltips-comp'
import useIsLogin from '@/hooks/useIsLogin'
import useScrollTop from '@/hooks/useScrollTop'
import {addAppBridge, postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import {emoji} from '@/utils/emoji/index'
import CODE from '@/utils/enum/code'
import {CIRCLE_OPERATING, TOGGLE_TYPE} from '@/utils/enum/index'
import NativePage from '@/utils/enum/native-page'
import SHARE_TYPE from '@/utils/enum/share'
import {commentList, likeComment, operaCircle, postDetails, replyList} from '@/utils/request/circle-details'
import {follow} from '@/utils/request/public'
import {isAndroid, isIOS} from '@/utils/userAgentUtil'
import {ListView} from 'antd-mobile'
import React, {useEffect, useRef, useState} from 'react'
import intl from 'react-intl-universal'
import {useHistory, useParams} from 'react-router-dom'
import './index.less'

let page = 0

const PostDetails = () => {
  const {id, type} = useParams()
  const history = useHistory()
  const isLogin = cookieUtils.checkingLogin()

  const userId = cookieUtils.getCookie('__CXY_UID_')
  const deviceNavHeight: number = Number(cookieUtils.getCookie('__CXY_STATUBARHEIGHT_')) || 0
  const lang = cookieUtils.getLang()
  const PAGESIZE = 5
  // 初始化上拉加载
  const pullUp = new ListView.DataSource({
    rowHasChanged: (row1: any, row2: any) => {
      return row1 !== row2
    },
  })

  const headerRef: any = useRef(null)
  const errorRef:any = useRef(null)
  // state 变量
  const [ initPage, setInitPage ] = useState<boolean>(false)
  const [ err, setError ] = useState<boolean>(false)
  const [ errorTop, setErrorTop ] = useState<number>(0)
  const [ padTop, setPadTop ] = useState<number>(0)
  const [loacalComment, setLoacalComment] = useState<any[]>([])
  const [init, setInit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState<any>({})
  const [sourceData, setSourceData] = useState(pullUp)
  const [comments, setComments] = useState<any[]>([])
  const [count, setCount] = useState(0)

  // 返回
  const jump = () => {
    if (type === 'h5') {
      return history.go(-1)
    }
    postMsgToNative('closePage', '')
  }

  // 跳转个人app中心页面
  const goUserInfo = (id: any) => {
    const param: any = {
      type: NativePage.userInfo,
      params: {
        userId: id,
      },
    }
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
  }

  // 获取帖子详情
  const _postDetails = async () => {
    const {code, result} = await postDetails({id})
    if (code !== CODE.SUCCESS) {
      return setError(true)
    }
    setInitPage(true)
    setPost(result.item)
    setCount(result.item.commentNumber)
    _commentList()
  }

  // 解析emoji
  const analyEmoji = (content: string) => {
    const emojiReg = /\[\![\u4E00-\u9FA5]+\]/g
    let emojiStr = ''
    emojiStr = content.replace(emojiReg, (a: string) => {
      let imgSrc: any = ''
      for (let i = 0; i < emoji.length; i++) {
        if (a === emoji[i].text) {
          imgSrc = emoji[i].image
          break
        }
      }
      return `<img src=${imgSrc}>`
    })
    return emojiStr
  }

  // 解析@成员
  const analyAtUser = (content: string, atUserInfoList: any[], isMore = false, replyUserInfo?: any) => {
    const atReg = /\[\%[0-9]+\]/g
    let atUserStr = ''
    let replyUserStr = ''
    let className = 'at_user'
    if (isMore) {
      className = 'at_user_more'
    }
    atUserStr = content.replace(atReg, (a: string) => {
      const userId = a.replace(/\[\%/, '').replace(/\]/, '')
      let nickName: string = ''
      for (let i = 0; i < atUserInfoList.length; i++) {
        if (userId === atUserInfoList[i].userId) {
          nickName = atUserInfoList[i].nickName
        }
      }
      return `<span class="${className}" data-userId=${userId}>@${nickName}</span>`
    })
    if (replyUserInfo) {
      replyUserStr = `<span class="${className}" data-userId=${replyUserInfo.userId}>@${replyUserInfo.nickName}</span>`
      // 获取所有的评论p标签
      const atUser: any = document.querySelectorAll('.desc >.at_user_more')
      if (atUser.length) {
        // 循环所有span，给每个评论@用户绑定跳转个人中心页面
        atUser.forEach((user: any) => {
          const userid = user.dataset.userid
          user.addEventListener('click', (e: any) => {
            console.log("点到@" + userid)
            e.stopPropagation()
            
            const param: any = {
              type: NativePage.userInfo,
              params: {
                userId: userid,
              },
            }
            postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
          })
        })
      }
    }
    return replyUserStr + atUserStr
  }

  // 解析评论
  const analyComments = (list: any[], type: string, replyId?: number) => {
    list.forEach(comment => {
      // 解析表情
      comment.content = analyEmoji(comment.content)
      // 解析@成员
      comment.content = analyAtUser(comment.content, comment.atUserInfoList)

      const replyList: any[] = comment.replyList
      if (replyList.length) {
        replyList.forEach(reply => {
          // 解析表情
          reply.content = analyEmoji(reply.content)
          // 解析@成员
          if (type === 'all' || replyId === reply.replyId * 1) {
            reply.content = analyAtUser(reply.content, reply.atUserInfoList, false, reply.replyUserInfo)
          } else {
            reply.content = analyAtUser(reply.content, reply.atUserInfoList)
          }
        })
      }
    })
  }
  // @用户点击事件
  const userClick = (e: any) => {
    const userId = e.target.dataset.userid
    e.stopPropagation()
    
    const param: any = {
      type: NativePage.userInfo,
      params: {
        userId,
      },
    }
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
  }

  // 获取评论详情
  const _commentList = async (isScroll = false) => {
    if (!loading) return
    isScroll ? page++ : (page = 1)
    const params = {
      page: page,
      pageSize: PAGESIZE,
      blogId: id,
    }
    const {code, result} = await commentList(params)
    if (code === CODE.SUCCESS) {
      const commentList: any[] = result.list
      analyComments(commentList, 'all')
      setInit(true)
      const newList = [...comments, ...commentList]
      newList.forEach(item => {
        item.more = '展开更多回复'
        item.toggle = true
        item.loading = true
        item.page = 1
      })
      setComments(newList)
      setSourceData(pullUp.cloneWithRows(newList))
      if (result.list.length < PAGESIZE) {
        setLoading(false)
      }
      // 获取所有的评论p标签
      const atUser: any = document.querySelectorAll('.desc >.at_user')
      // console.log(atUser)
      if (atUser.length) {
        // 循环所有span，给每个评论@用户绑定跳转个人中心页面
        atUser.forEach((user: any) => {
          // 标记已经绑定过点击事件
          const isHasEle = user.getAttribute("isHasEle")
          // 如果没有绑定过，则绑定点击事件，并且标记该元素，避免重复绑定
          if (!isHasEle) {
            user.setAttribute("isHasEle", '1')
            user.addEventListener('click', userClick, false)
          } else {
            user.removeEventListener('click', userClick)
          }
        })
      }
    }
  }

  // 分享功能
  const shareCircle = (post: any) => {
    const params = {
      id,
      actionType: userId === post.authorUserId ? [0, 1] : [0, 1, 2],
      desc: post.content,
      title: `${post.authorUserInfo.nickName}在创想云发了一条帖子`,
      pageType: SHARE_TYPE.Post,
      url: `/share-post/${post.id}`,
      icon: post.media && post.media.length ? post.media[0].url || '' : '',
    }
    postMsgToNative('onMenu', encodeURI(JSON.stringify(params)))
  }

  // 单个分享
  enum SINGLE_TYPE {
    QQ = 1,
    QQ_ZONE = 2,
    WECHAT = 3,
    WECHAT_CIRCLE = 4,
  }
  const doShare = (post: any, type: number) => {
    const params = {
      type,
      id: post.id,
      pageType: SHARE_TYPE.Post,
      title: `${post.authorUserInfo.nickName}在创想云发了一条帖子`,
      desc: post.content,
      icon: post.media && post.media.length ? post.media[0].url || '' : '',
      url: `/share-post/${post.id}`,
    }
    postMsgToNative('doShare', encodeURI(JSON.stringify(params)))
  }

  const toggleBtns = async (type: string, id: number, relationship?: number) => {
    const isJoined = post.circle.isJoined
    // 加入与未加入圈子操作
    if (type === TOGGLE_TYPE.JOIN) {
      if (isJoined) return
      const params = {
        id,
        action: CIRCLE_OPERATING.JOIN,
      }
      const {code} = await operaCircle(params)
      if (code === CODE.SUCCESS) {
        _postDetails()
      }
    }
    // 关注与取消关注
    if (type === TOGGLE_TYPE.FOLLOW) {
      let action = false
      if (relationship === 0 || relationship === 2) {
        action = true
      }
      if (relationship === 1 || relationship === 3) {
        action = false
      }
      const params = {
        userIds: [id],
        action: action,
      }
      const {code} = await follow(params)
      if (code === CODE.SUCCESS) {
        _postDetails()
      }
    }
  }

  // 跳转原生登录页面
  const jumpLogin = () => {
    const param: any = {
      type: NativePage.login,
      params: {
        url: '',
      },
    }
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
  }

  // 跳转至圈子主页
  const toCircleDetails = (id: number) => {
    history.push(`/circle-details/${id}/h5`)
  }

  // 发表评论
  const commentPost = (id: number) => {
    if (!useIsLogin()) return
    postMsgToNative('commentPost', `${id}`)
  }

  // 回复评论
  const replyComment = (commentId: number, replyId: number, nickName: string) => {
    if (!isLogin) {
      return jumpLogin()
    }
    const param: any = {
      blogId: id,
      commentId,
      replyId,
      nickName,
    }
    postMsgToNative('replyComment', encodeURI(JSON.stringify(param)))
  }

  const _setComment = (list: any[], replyId?: number) => {
    analyComments(list, 'single', replyId)
    setCount(count + 1)
    setSourceData(pullUp.cloneWithRows(list))
    setComments(list)
  }

  // APP发表评论成功之后请求列表
  addAppBridge('getCommentList', (data: any) => {
    if (!data) return
    _postDetails()
    let result: any = {}
    if (isAndroid()) {
      result = JSON.parse(data).item
    }
    if (isIOS()) {
      result = JSON.parse(decodeURIComponent(data)).item
    }

    const commentId = result.commentId * 1
    const replyId = result.replyId * 1
    // 发表评论
    if (!commentId && !replyId) {
      const newList = JSON.parse(JSON.stringify([...[result], ...comments]))
      _setComment(newList)
    }

    // 回复评论
    if (commentId && !replyId) {
      for (let i = 0; i < comments.length; i++) {
        const comment = comments[i]
        if (comment.id * 1 === commentId) {
          const replyList = comment.replyList
          comment.replyList = JSON.parse(JSON.stringify([...[result], ...replyList]))
          _setComment(comments)
          break
        }
      }
    }

    // 回复回复
    if (commentId && replyId) {
      for (let i = 0; i < comments.length; i++) {
        let targetIdx = 0
        const comment = comments[i]
        const replyList = comment.replyList
        for (let j = 0; j < replyList.length; j++) {
          if (replyList[j].id * 1 === replyId) {
            targetIdx = j
            break
          }
        }
        replyList.splice(targetIdx, 0, result)
        _setComment(comments, replyId)
        break
      }
    }
  })

  const setScrollY = (commentId: number) => {
    window.scrollTo(0, 110)
    const commentEl = document.querySelectorAll('.comment-comp')
    for (let i = 0; i < commentEl.length; i++) {
      const _commentEl: any = commentEl[i]
      if (_commentEl.dataset.id === commentId) {
        console.log(_commentEl, _commentEl.getBoundingClientRect())
        window.scrollTo(0, _commentEl.getBoundingClientRect().top)
        break
      }
    }
  }

  // 展开收起评论列表
  const toggleComment = (commentId: number, toggle: boolean) => {
    for (let i = 0; i < comments.length; i++) {
      const comment = comments[i]
      let _comments: any[] = []
      if (comment.id === commentId) {
        if (toggle) {
          setScrollY(commentId)
          const replyList: any[] = comment.replyList
          comment.more = intl.get('postDetail.more')
          comment.toggle = false
          comment.replyList = replyList.slice(0, 3)
          _comments = comments
        } else {
          comment.more = intl.get('postDetail.folded')
          comment.toggle = true
          _comments = loacalComment
        }
        setSourceData(pullUp.cloneWithRows(_comments))
        setComments(_comments)
        return
      }
    }
  }

  // 查看更多
  const lookMore = async (commentId: number, loading: boolean, page: number, toggle: boolean) => {
    if (!loading) {
      if (toggle) {
        setLoacalComment(JSON.parse(JSON.stringify(comments)))
        toggleComment(commentId, toggle)
      }
      if (!toggle) {
        toggleComment(commentId, toggle)
      }
      return
    }
    const params = {
      page: page,
      pageSize: 5,
      commentId,
    }
    const {code, result} = await replyList(params)
    if (code === CODE.SUCCESS) {
      const _replyList = result.list
      if (_replyList.length < params.pageSize) {
        for (let i = 0; i < comments.length; i++) {
          if (comments[i].id === commentId) {
            comments[i].more = intl.get('postDetail.folded')
            comments[i].loading = false
            setSourceData(pullUp.cloneWithRows(JSON.parse(JSON.stringify(comments))))
            setComments(comments)
            return
          }
        }
      }
      for (let i = 0; i < comments.length; i++) {
        const comment = comments[i]
        if (comment.id === commentId) {
          if (page === 1) {
            comment.replyList = [..._replyList]
          } else {
            comment.replyList = [...comment.replyList, ..._replyList]
          }
          comment.page++
          // comment.replyList = [..._replyList]
          const replyList: any[] = comment.replyList
          replyList.forEach(reply => {
            // 解析表情
            reply.content = analyEmoji(reply.content)
            // 解析@成员
            reply.content = analyAtUser(reply.content, reply.atUserInfoList, true, reply.replyUserInfo)
          })
          setSourceData(pullUp.cloneWithRows(JSON.parse(JSON.stringify(comments))))
          setComments(comments)

          break
        }
      }
      // 获取所有的评论p标签
      const atUser: any = document.querySelectorAll('.desc >.at_user_more')
      if (atUser.length) {
        // 循环所有span，给每个评论@用户绑定跳转个人中心页面
        atUser.forEach((user: any) => {
          const userid = user.dataset.userid
          user.addEventListener('click', (e: any) => {
            console.log("点到@" + userid)
            e.stopPropagation()
            
            const param: any = {
              type: NativePage.userInfo,
              params: {
                userId: userid,
              },
            }
            postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
          })
        })
      }

    }
  }

  // 点赞
  const toggleLike = async (id: number, type: string, isLike: boolean) => {
    const params = {
      commentId: 0,
      replyId: 0,
      isUnlike: isLike,
    }
    if (type === 'comment') {
      params.commentId = id
    }
    if (type === 'reply') {
      params.replyId = id
    }
    const {code} = await likeComment(params)
    if (code !== CODE.SUCCESS) return
    for (let i = 0; i < comments.length; i++) {
      const comment = comments[i]
      // 点赞一级评论
      if (params.commentId && params.commentId === comment.id) {
        isLike ? comment.likeCount-- : comment.likeCount++
        comment.isLike = !comment.isLike
        break
      }
      // 点赞二级评论
      if (params.replyId) {
        for (let j = 0; j < comment.replyList.length; j++) {
          const reply = comment.replyList[j]
          if (params.replyId === reply.id) {
            isLike ? reply.likeCount-- : reply.likeCount++
            reply.isLike = !reply.isLike
            break
          }
        }
      }
    }
    setSourceData(pullUp.cloneWithRows(JSON.parse(JSON.stringify(comments))))
    setComments(comments)
  }

  const computedPadTop = () => {
    const app: any = document.getElementsByClassName('App')[0]
    if (app) {
      app.style.paddingTop = deviceNavHeight +'px'
    }
    if (headerRef.current) {
      const top: number = headerRef.current.clientHeight
      setPadTop(top - deviceNavHeight)
    }
    
    if (errorRef.current) {
      setErrorTop(errorRef.current.clientHeight)
    }
  }

  // 首次进入页面，返回顶部
  useScrollTop()
  useEffect(() => {
    computedPadTop()
  })
  useEffect(() => {
    _postDetails()
    
    // _commentList()
  }, [])

  return (
    <div className="post-details" style={{paddingTop: padTop + 'px'}}>
      {
        err ?
        <div className="post-details_error" style={{paddingTop: deviceNavHeight + 'px'}}>
          <div className="error-header" ref={ errorRef }>
            <i className="back" onClick={() => postMsgToNative('closePage')}></i>
            <p className="title">帖子</p>
          </div>
          <div className="error-empty" style={{top: errorTop + 'px'}}>
            <img src={require('../../assets/img/error_post.png')} alt=""/>
            <p className="content">该帖子已被删除</p>
          </div>
        </div> 
        :
        initPage &&
        <>
          {post.authorUserInfo && (
            <div className="header" ref={headerRef} style={{paddingTop: deviceNavHeight + 'px'}}>
              <i className="back" onClick={() => jump()}></i>
              <div
                className="avatar"
                onClick={() => goUserInfo(post.authorUserInfo.userId)}
                style={{backgroundImage: 'url(' + post.authorUserInfo.avatar + ')'}}></div>
              <span className="name" onClick={() => goUserInfo(post.authorUserInfo.userId)}>
                {post.authorUserInfo.nickName}
              </span>
              {userId !== post.authorUserId && (
                <ButtonComp
                  onClick={() => toggleBtns(TOGGLE_TYPE.FOLLOW, post.authorUserId, post.authorUserInfo.relationship)}
                  status={post.authorUserInfo.relationship}
                />
              )}
              <i className="more" onClick={() => shareCircle(post)}></i>
            </div>
          )}
          <div className="content">
            <div className="details">
              <p>{post.content}</p>
            </div>
            <GalleryComp media={post.media} tags={ post.tags } isDetail={true} mediaType={post.mediaType} />
          </div>
          <Separator />
          {lang === 1 && (
            <div className="share">
              <span className="text">{intl.get('share')}</span>
              <i className="icon firends-circle" onClick={() => doShare(post, SINGLE_TYPE.WECHAT_CIRCLE)}></i>
              <i className="icon qq-zone" onClick={() => doShare(post, SINGLE_TYPE.QQ_ZONE)}></i>
            </div>
          )}
          <Separator />
          <div className="relevant">
            <h4 className="title">{intl.get('postDetail.associated')}</h4>
            {post.circle && (
              <div className="circle">
                <div
                  className="cover"
                  style={{backgroundImage: 'url(' + post.circle.avatar + ')'}}
                  onClick={() => toCircleDetails(post.circle.id)}></div>
                <div className="text" onClick={() => toCircleDetails(post.circle.id)}>
                  <h5 className="subtitle">{post.circle.name}</h5>
                  <p className="desc">{post.circle.description}</p>
                </div>
                {userId !== post.circle.authorUserId && (
                  <ButtonComp
                    onClick={() => toggleBtns(TOGGLE_TYPE.JOIN, post.circle.id)}
                    status={post.circle.isJoined ? -1 : -2}
                  />
                )}
              </div>
            )}
          </div>
          <Separator />
          <div className="comments">
            <h4 className="title">
              {count} {intl.get('postDetail.numComments')}
            </h4>
            <div className="list">
              {init ? (
                <>
                  {comments.length ? (
                    <ListView
                      dataSource={sourceData}
                      renderFooter={() => <LoadingComp loading={loading} />}
                      renderRow={(rowData, _sectionID, rowID) => (
                        <CommentComp
                          key={rowID}
                          replyComment={(commentId, replyId, nickName) => replyComment(commentId, replyId, nickName)}
                          lookMore={(commentId, loading, page, toggle) => lookMore(commentId, loading, page, toggle)}
                          toggleLike={(id, type, isLike) => toggleLike(id, type, isLike)}
                          comment={rowData}
                        />
                      )}
                      useBodyScroll
                      pageSize={PAGESIZE}
                      initialListSize={PAGESIZE}
                      onEndReached={() => _commentList(true)}
                      onEndReachedThreshold={20}
                    />
                  ) : (
                    <div className="empty">
                      <img src={require('../../assets/img/no_comment.png')} alt="" />
                      <p>{intl.get('postDetail.firstMan')}</p>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
          <div className="tooltips">
            {post.id && (
              <TooltipsComp
                id={id}
                disabled={true}
                commentPost={() => commentPost(id)}
                shareCircle={() => shareCircle(post)}
                isLike={post.isLike}
                like={post.likeNumber}
                comment={post.commentNumber}
                share={post.sharedNumber}
              />
            )}
          </div>
        </> 
      }   
    </div>
  )
}

export default PostDetails
