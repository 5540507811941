/** @format */

enum CODE {
  SUCCESS = 0, // 成功
  ERR_PARAMETER = 1, // 参数非法
  ERR_TOKEN = 4, // token失效
  INVALID_IDENTITY = 5, // 身份错误
}

export default CODE
