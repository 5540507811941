/** @format */

import CardComp from '@/components/card-comp'
import Empty from '@/components/empty-view'
import NavBar from '@/components/nav-bar'
import useIsLogin from '@/hooks/useIsLogin'
import {postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import CODE from '@/utils/enum/code'
import {TOGGLE_TYPE} from '@/utils/enum/index'
import NativePage from '@/utils/enum/native-page'
import SHARE_TYPE from '@/utils/enum/share'
import {getPostList} from '@/utils/request/circle-details'
import {follow} from '@/utils/request/public'
import {ActionSheet, Icon, ListView, Toast} from 'antd-mobile'
import React, {useEffect, useRef, useState} from 'react'
import intl from 'react-intl-universal'
import {useParams} from 'react-router-dom'
import './index.less'

let page = 1
const pageSize = 20 // 每次加载的数据数量
const MyPost = (): JSX.Element => {
  const {userId, isMy} = useParams()
  const myUserId = cookieUtils.getUserId()
  // 初始化上啦组件
  const ScrollData = new ListView.DataSource({
    rowHasChanged: (row1: any, row2: any) => row1 !== row2,
  })

  const childRef: any = useRef()
  const [padTop, setPadTop] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)
  const [] = useState(false)
  const [list, setList] = useState<any>([])
  const [dataSource, setDataSource] = useState(ScrollData)
  const [request, setRequest] = useState(true)
  const statusBarHeight = cookieUtils.getNativeStatusBarHeight()

  // 获取帖子列表数据
  const initCircleList = async (isScroll = false) => {
    if (!isLoading) {
      return
    }
    if (isScroll) {
      page++
    } else {
      page = 1
    }
    const {code, msg, result} = await getPostList({
      page,
      pageSize,
      userId: userId && parseInt(userId),
    })
    if (code !== CODE.SUCCESS) {
      Toast.fail(msg)
      return
    }
    const NewList: any = [...list, ...result.list]
    setRequest(false)
    setDataSource(ScrollData.cloneWithRows(NewList))
    setList(NewList)
    if (page * pageSize >= Number(result.count)) {
      setIsLoading(false)
    }
  }
  // 分享功能
  const shareCircle = (post: any, type: string) => {
    const _params: any = {}
    if (type === 'circle') {
      _params.desc = post.description
      _params.title = post.name
      _params.pageType = SHARE_TYPE.Circle
      _params.url = `/share-circle/${post.id}`
      _params.icon = post.avatar
      _params.actionType = userId === post.authorUserId ? [0, 3, 1] : [0, 3, 1, 2]
    }
    if (type === 'post') {
      _params.desc = post.content
      _params.title = `${post.authorUserInfo.nickName}在创想云发了一条帖子`
      _params.pageType = SHARE_TYPE.Post
      _params.url = `/share-post/${post.id}`
      _params.icon = post.media && post.media.length ? post.media[0].url || '' : ''
      _params.actionType = userId === post.authorUserId ? [0, 1] : [0, 1, 2]
    }
    const params = Object.assign({}, {id: post.id}, _params)
    postMsgToNative('onMenu', encodeURI(JSON.stringify(params)))
  }

  // 按钮操作，加入圈子或者点赞
  const toggleBtns = async (type: string, id: number, relationship?: number) => {
    // 关注与取消关注
    if (type === TOGGLE_TYPE.FOLLOW) {
      let action = false
      if (relationship === 0 || relationship === 2) {
        action = true
      }
      if (relationship === 1 || relationship === 3) {
        action = false
      }
      const params = {
        userIds: [id],
        action,
      }
      const {code} = await follow(params)
      if (code === CODE.SUCCESS) {
        const params = {
          type: action ? 1 : 2, // 1：关注，2：取消关注
        }
        postMsgToNative('toggleFollow', encodeURI(JSON.stringify(params)))
        const _postList: any[] = []
        list.forEach((post: any) => {
          const relationship = post.authorUserInfo.relationship
          if (id === post.authorUserId) {
            if (relationship === 0 || relationship === 2) {
              post.authorUserInfo.relationship = 1
            }
            if (relationship === 1 || relationship === 3) {
              post.authorUserInfo.relationship = 0
            }
            // post.authorUserInfo.relationship = relationship === 0 ? 1 : 0
          }
          _postList.push(post)
        })
        setList([..._postList])
        setDataSource(ScrollData.cloneWithRows(JSON.parse(JSON.stringify(_postList))))
      }
    }
  }

  // 评论
  const commentPost = (id: number, type: string) => {
    if (type === 'click') {
      if (!useIsLogin()) return
      postMsgToNative('commentPost', `${id}`)
    }
    if (type === 'jump') {
      // route.push(`/post-details/${ id }/h5`)
      const param: any = {
        type: NativePage.webPage,
        params: {
          url: `/post-details/${id}`,
        },
      }
      postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
    }
  }

  // 举报
  const showAction = () => {
    const BUTTONS = [intl.get('report'), intl.get('cancel')]
    ActionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        cancelButtonIndex: 1,
        maskClosable: true,
      },
      buttonIndex => {
        if (buttonIndex === 0) {
          // ...举报
        }
      },
    )
  }

  const computedPadTop = () => {
    if (childRef.current) {
      setPadTop(childRef.current.navBarH)
    }
  }

  useEffect(() => {
    computedPadTop()
    // Toast.info(`${Number(userId)}-${Number(myUserId)}`)
    initCircleList()
  }, [])
  return (
    <div
      className="myPost-list-layout"
      style={{paddingTop: Number(isMy) === 1 && statusBarHeight ? statusBarHeight + 'px' : ''}}>
      {Number(userId) === Number(myUserId) && Number(isMy) ? (
        <NavBar title={intl.get('myPost.myPost')} cRef={childRef} isNativeBack={true} />
      ) : null}
      {list && list.length ? (
        <ListView
          style={{paddingTop: Number(isMy) === 1 && padTop ? padTop + 'px' : ''}}
          dataSource={dataSource}
          renderFooter={() => (
            <>
              {isLoading ? (
                <>
                  <Icon type="loading" size="xs" />
                  <span>{ intl.get('comLoading.loading') }</span>
                </>
              ) : (
                intl.get('comLoading.loaded')
              )}
            </>
          )}
          renderRow={(rowData, rowID) => (
            <CardComp
              isNative={true}
              otherId={userId}
              commentPost={type => commentPost(rowData.id, type)}
              onClick={() => toggleBtns(TOGGLE_TYPE.FOLLOW, rowData.authorUserId, rowData.authorUserInfo.relationship)}
              showAction={() => showAction()}
              shareCircle={() => shareCircle(rowData, 'post')}
              key={rowID}
              card={rowData}
            />
          )}
          pageSize={pageSize}
          // style={{
          //   marginTop: Number(userId) === Number(myUserId) && Number(isMy) === 1 ? '44px' : '0.5px',
          //   overflow: 'auto',
          // }}
          useBodyScroll
          initialListSize={pageSize}
          onContentSizeChange={(_contentWidth, contentHeight) => {
            console.log(contentHeight)
          }}
          onEndReached={() => initCircleList(true)}
          onEndReachedThreshold={20}
        />
      ) : request ? null : (
        <Empty title={`${intl.get('comLoading.noData')}~`} />
      )}
    </div>
  )
}
export default MyPost
