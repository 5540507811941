/** @format */

import ButtonComp from '@/components/button-comp'
import GalleryComp from '@/components/gallery-comp'
import TooltipsComp from '@/components/tooltips-comp'
import {postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import NativePage from '@/utils/enum/native-page'
import React from 'react'
import {useHistory} from 'react-router-dom'
import '../com-style/card-comp.less'

interface Props {
  commentPost?(type: string): void
  isNative?: boolean
  otherId?: string
  type?: string
  onClick?(): void
  shareCircle?(): void
  showAction?(): void
  card: any
}

const CardComp = (props: Props): JSX.Element => {
  const userId = cookieUtils.getUserId()
  const {card, onClick, shareCircle, otherId, type, commentPost, isNative = false} = props
  const history = useHistory()
  const toPostDetails = (id: number) => {
    if (isNative) {
      const param: any = {
        type: NativePage.webPage,
        params: {
          url: `/post-details/${id}`,
        },
      }
      postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
    } else {
      history.push(`/post-details/${id}`)
    }
  }
  // 跳转个人app中心页面
  const goUserInfo = (id: any) => {
    const param: any = {
      type: NativePage.userInfo,
      params: {
        userId: id,
      },
    }
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
  }

  // 评论
  const _commentPost = (type: string) => {
    if (commentPost) {
      commentPost(type)
    }
  }

  return (
    <div className="card-comp">
      <div className="card-item">
        <div className="card-header">
          <div
            className="cover"
            onClick={() => goUserInfo(card.authorUserInfo.userId)}
            style={{backgroundImage: 'url(' + card.authorUserInfo.avatar + ')'}}></div>
          <div className="name" onClick={() => goUserInfo(card.authorUserInfo.userId)}>
            {card.authorUserInfo.nickName}
          </div>
          {userId !== card.authorUserId && otherId !== card.authorUserId && (
            <ButtonComp onClick={onClick} status={card.authorUserInfo.relationship} />
          )}
        </div>
        <div className="desc" onClick={() => toPostDetails(card.id)}>
          {card.content}
        </div>
        <GalleryComp tags={card.tags} media={card.media} mediaType={card.mediaType} />
        {type !== 'search' && (
          <TooltipsComp
            id={card.id}
            commentPost={type => _commentPost(type)}
            toPostDetails={() => toPostDetails(card.id)}
            shareCircle={shareCircle}
            isLike={card.isLike}
            like={card.likeNumber}
            comment={card.commentNumber}
            share={card.sharedNumber}
          />
        )}
      </div>
    </div>
  )
}

export default CardComp
