/** @format */

import React from 'react'
import intl from 'react-intl-universal'
import './index.less'

interface ShareTop {
  openPage: Function
  title?: string
}

const ComShareTop = (props: ShareTop) => {
  const {openPage, title} = props
  return (
    <div className="share-footer-container">
      <div className="btn" onClick={() => openPage()}>
        <span className="text">{title || intl.get('comSharePage.open')}</span>
        <span className="icon"></span>
      </div>
    </div>
  )
}

export default ComShareTop
