/** @format */

import arrow_right from '@/assets/img/arrow_right.png'
import Level1 from '@/assets/img/level1.png'
import Level2 from '@/assets/img/level2.png'
import Level3 from '@/assets/img/level3.png'
import {deepClone} from '@/utils/public'
import {List} from 'antd-mobile'
import React from 'react'
import './index.less'

interface FoldAvatar {
  avatarList: object[]
  userCount: number
  event(): void
  showCount?: number
}
const Item = List.Item

const FoldAvatar: React.FC<FoldAvatar> = props => {
  const {avatarList, event, userCount, showCount} = props
  let list: any = deepClone(avatarList)
  if (showCount && showCount > 0) {
    list = list.splice(0, showCount)
  }
  // console.log(list)
  return (
    <div className="foldAvatar-layout">
      <Item extra={<img className="extra-right-icon" src={arrow_right} width="12" />} onClick={event}>
        <div className="circle-like" style={{width: 25 * list.length}}>
          {list.map((pic: any, index: number) => (
            <div className="img-item" key={index} style={{zIndex: list.length - index, left: 25 * index}}>
              {pic.crownLevel === 1 && pic.likeNumber > 0 && <img className="level-img" src={Level1} />}
              {pic.crownLevel === 2 && pic.likeNumber > 0 && (
                <img className="level-img" style={{width: '12px', top: '-6px', left: '0px'}} src={Level2} />
              )}
              {pic.crownLevel === 3 && pic.likeNumber > 0 && (
                <img className="level-img" style={{width: '9px', top: '-3px', left: '1px'}} src={Level3} />
              )}
              <img className="user-img" src={pic.base.avatar} />
            </div>
          ))}
        </div>
      </Item>
    </div>
  )
}
export default FoldAvatar
