/** @format */
import {openApp} from '@/utils/openApp.js'
import React, {useEffect} from 'react'
import intl from 'react-intl-universal'
import './index.less'

const ShareHome = (): JSX.Element => {
  const openPage = () => {
    openApp({isDownload: true})
  }
  useEffect(() => {
    document.title = intl.get('comSharePage.crealityTitle')
  })
  return (
    <div className="share-home-container">
      <div className="share-top">
        <div className="logo"></div>
        <div className="name">{intl.get('comSharePage.crealityTitle')}</div>
        <div className="describe">{intl.get('comSharePage.createText')}</div>
      </div>
      <div className="share-android" onClick={() => openPage()}>
        <span className="icon"></span>
        <span>Android{intl.get('comSharePage.download2')}</span>
      </div>
      <div className="share-ios" onClick={() => openPage()}>
        <span className="icon"></span>
        <span>IOS{intl.get('comSharePage.download2')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </div>
    </div>
  )
}

export default ShareHome
