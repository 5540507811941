/**
 * 操作cookie的工具类
 *
 * @format
 */

import {getOs} from './userAgentUtil'
import {createUid} from './public'

enum AUTH_KEYS {
  TOKEN = '__CXY_TOKEN_',
  BRAND = '__CXY_BRAND_',
  OS_VERSION = '__CXY_OS_VER_',
  LANG = '__CXY_OS_LANG_',
  PLATFORM = '__CXY_PLATFORM_',
  DEVICE_ID = '__CXY_DUID_',
  USER_ID = '__CXY_UID_',
  CHANNAL = '__CXY_APP_CH_',
  APP_VERSION = '__CXY_APP_VER_',
  APP_ID = '__CXY_APP_ID_',
}
enum OTHER_KEYS {
  LOCALE = 'quanzi_locale',
  OSS_INFO = 'quanzi_oss_info',
  ALIYUN_INFO = 'quanzi_aliyun_info',
  STATUS_BAR_HEIGHT = '__CXY_STATUBARHEIGHT_',
  IS_WIFI = '__CXY_IS_WIFI_',
  USER_AVATAR = '__CXY_UAVATAR_',
  USER_NAME = '__CXY_UNAME_',
}
export default {
  /**
   * 设置Cookie
   * @param key
   * @param value
   */
  setCookie(key: any, value?: any, exp: any = new Date()) {
    exp.setTime(exp.getTime() + 3 * 24 * 60 * 60 * 1000) //3天过期
    document.cookie = key + '=' + value + ';expires=' + exp + ';path=/'
  },

  /**
   * 移除Cookie
   * @param key
   */
  removeCookie(key: any) {
    this.setCookie(key, '', -1) //这里只需要把Cookie保质期退回一天便可以删除
  },

  /**
   * 获取Cookie
   * @param key
   * @returns {*}
   */
  getCookie(key: any) {
    let cookieArr = document.cookie.split('; ')
    for (let i = 0; i < cookieArr.length; i++) {
      let arr = cookieArr[i].split('=')
      if (arr[0] === key) {
        return arr[1]
      }
    }
    return ''
  },
  // 初始化cookie
  init() {
    if (!this.getCookie(OTHER_KEYS.LOCALE)) {
      let locale
      if (navigator.language) {
        locale = navigator.language.substr(0, 5)
      } else {
        locale = window.navigator.language.substr(0, 5)
      }
      const lang = locale === 'en-US' ? 0 : 1
      this.setCookie(AUTH_KEYS.LANG, lang) // 设置语言
      this.setCookie(OTHER_KEYS.LOCALE, locale) //设置LOCALE
    }
    const ua = navigator.userAgent
    // 设置系统内核版本
    if (!this.getCookie(AUTH_KEYS.OS_VERSION)) this.setCookie(AUTH_KEYS.OS_VERSION, getOs(ua))
    // 设置设备类型
    if (!this.getCookie(AUTH_KEYS.PLATFORM)) this.setCookie(AUTH_KEYS.PLATFORM, 4)
    // 设置设备id
    if (!this.getCookie(AUTH_KEYS.DEVICE_ID)) this.setCookie(AUTH_KEYS.DEVICE_ID, createUid())
    // 设置appId
    if (!this.getCookie(AUTH_KEYS.APP_ID))
      this.setCookie(AUTH_KEYS.APP_ID, process.env.REACT_APP_APP_ID || 'creality_model')
  },
  // 获取app状态栏高度
  getNativeStatusBarHeight(path?: string): number {
    const statusBarHeight = Number(this.getCookie(OTHER_KEYS.STATUS_BAR_HEIGHT)) || 0
    if (!path) return statusBarHeight
    console.log('-path name-', path)
    const innerPageList = require('@/assets/json/inner-page-list.json')
    if (!innerPageList.includes(path)) return statusBarHeight
    return 0
  },
  // 获取token对象
  getAuthOptions(): {} {
    // 判断是否同步cookie，没有则设置默认
    if (!this.getCookie(AUTH_KEYS.DEVICE_ID)) this.init()
    const AuthInfo = {
      __CXY_APP_CH_: this.getCookie(AUTH_KEYS.CHANNAL) || '',
      __CXY_APP_ID_: this.getCookie(AUTH_KEYS.APP_ID) || '',
      __CXY_APP_VER_: this.getCookie(AUTH_KEYS.OS_VERSION) || '',
      __CXY_BRAND_: this.getCookie(AUTH_KEYS.BRAND) || '',
      __CXY_DUID_: this.getCookie(AUTH_KEYS.DEVICE_ID) || '',
      __CXY_OS_LANG_: this.getCookie(AUTH_KEYS.LANG) || '',
      __CXY_OS_VER_: this.getCookie(AUTH_KEYS.OS_VERSION) || '',
      __CXY_PLATFORM_: this.getCookie(AUTH_KEYS.PLATFORM) || '',
      __CXY_TOKEN_: this.getCookie(AUTH_KEYS.TOKEN) || '',
      __CXY_UID_: this.getCookie(AUTH_KEYS.USER_ID) || '',
    }
    return AuthInfo || {}
  },
  // 获取系统cookie语言
  getLang(): number {
    const lang = Number(this.getCookie(AUTH_KEYS.LANG))
    return lang
  },
  getUserId(): string {
    return this.getCookie(AUTH_KEYS.USER_ID)
  },
  getAvatar() {
    return this.getCookie(OTHER_KEYS.USER_AVATAR)
  },
  getToken() {
    return this.getCookie(AUTH_KEYS.TOKEN)
  },
  // 检查是否登陆
  checkingLogin(): boolean {
    return Boolean(this.getUserId() && this.getToken())
  },
  isWifi(): boolean {
    const isWifiStr = this.getCookie(OTHER_KEYS.IS_WIFI)
    return isWifiStr === 'true'
  },
}
