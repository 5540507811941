/** @format */

import errorIcon from '@/assets/img/404.png'
import NavBar from '@/components/nav-bar'
import {postMsgToNative} from '@/utils/app-bridge'
import {Button} from 'antd-mobile'
import React from 'react'
import intl from 'react-intl-universal'
import {useHistory} from 'react-router-dom'
import './index.less'

const Error = (): JSX.Element => {
  const route = useHistory()
  const backPage = () => {
    try {
      postMsgToNative('closePage', '')
    } catch (error) {
      route.replace('/')
    }
  }
  return (
    <>
      <NavBar title="404" isNativeBack={true} />
      <div className="error-page">
        <div className="layout">
          <img src={errorIcon} />
          <h1>{intl.get('errorPage.noPage')}</h1>
          <h2>{intl.get('errorPage.otherOperation')}</h2>
          <Button type="ghost" onClick={() => backPage()} inline size="small">
            {intl.get('errorPage.goHome')}
          </Button>
        </div>
      </div>
    </>
  )
}
export default Error
