import post from '../http'

// 帖子列表
interface circleHome {
  page: number;
  pageSize: number,
  isHome?: boolean
}
export const circleHomeList = (params: circleHome) => 
  post("/api/circle/circle/circleHomeList", {
    page: params.page,
    pageSize: params.pageSize,
    isHome: params.isHome
  })

// 圈子列表
interface circleList {
  page: number,
  pageSize: number,
  keyword?: string,
  classId?: number,
  isRecommend?: boolean,
  begin?: number,
  end?: number,
}
export const getCircleList = (params: circleList) => 
  post("/api/circle/circle/circleList", {
    page: params.page,
    pageSize: params.pageSize,
    keyword: params.keyword,
    classId: params.classId,
    isRecommend: params.isRecommend,
    begin: params.begin,
    end: params.end,
  })

// 我的圈子列表
interface ownerList {
  page: number,
  pageSize: number,
  isHome?: boolean,
}
export const ownerList = (params: ownerList) => 
  post("/api/circle/circle/ownerList", {
    page: params.page,
    pageSize: params.pageSize,
    isHome: params.isHome,
  })

// 帖子点赞
interface blogOperating {
  id: any
  action: number
}
export const blogOperating = (params: blogOperating) => 
  post("/api/circle/blog/blogOperating", {
    id: params.id,
    action: params.action
  })
