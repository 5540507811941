/** @format */

import arrow_right from '@/assets/img/arrow_right.png'
import Comment from '@/assets/img/comment.png'
import Follow from '@/assets/img/follow.png'
import Followed from '@/assets/img/follow_active.png'
import Forward from '@/assets/img/forward.png'
import MyCircleIcon from '@/assets/img/Lark20200616-150252.png'
import Avatar from '@/components/fold-avatar'
import GalleryComp from '@/components/gallery-comp'
import Separator from '@/components/separator'
import {addAppBridge, postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import CODE from '@/utils/enum/code'
import NativePage from '@/utils/enum/native-page'
import SHARE_TYPE from '@/utils/enum/share'
import {likeFormat} from '@/utils/format'
import {blogOperating, circleHomeList, ownerList} from '@/utils/request/home'
import {Icon, List, ListView, Toast} from 'antd-mobile'
import React, {useEffect, useRef, useState} from 'react'
import intl from 'react-intl-universal'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import './index.less'

const Item = List.Item
const isLogin = cookieUtils.checkingLogin()
// 跳转圈子详情页面
const opDetail = (id: string) => {
  // history.push(`/circle-details/${id}`)
  const param: any = {
    type: NativePage.webPage, // 枚举
    params: {
      url: `/circle-details/${id}`, // 需要跳转webview页面的路由地址
    },
  }
  postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
}
// 跳转帖子详情页面
const postDetail = (id: string) => {
  const param: any = {
    type: NativePage.webPage,
    params: {
      url: `/post-details/${id}`,
    },
  }
  postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
}
// 跳转我的圈子页面
const myCircle = () => {
  const param: any = {
    type: isLogin ? NativePage.webPage : NativePage.login,
    params: {
      url: `/my-circles`,
    },
  }
  postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
}
// 跳转发现圈子页面
const findCircle = () => {
  const param: any = {
    type: NativePage.webPage,
    params: {
      url: '/find-circles',
    },
  }
  postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
}
// 跳转圈子详情页面
const goCircle = (item: any) => {
  let path = `/circle-details/${item.id}`
  const param: any = {
    type: NativePage.webPage,
    params: {
      url: path,
    },
  }
  postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
}
// 跳转个人app中心页面
const goUserInfo = (id: any) => {
  const param: any = {
    type: NativePage.userInfo,
    params: {
      userId: id,
    },
  }
  postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
}
// 跳转圈子成员列表
const onCircleMember = (id: any) => {
  const param: any = {
    type: NativePage.webPage,
    params: {
      url: `/circle-member/${id}`,
    },
  }
  postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
}
// 跳转标签列表页面
const onTagPostList = (id: any) => {
  const param: any = {
    type: NativePage.webPage,
    params: {
      url: `/tag-post-list/${id}`,
    },
  }
  postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
}

// 圈子数据row
interface circleRow {
  rowData: any
  sectionID: any
  rowID: any
}
const CircleRow: React.FC<circleRow> = props => {
  const {rowData, rowID} = props
  const item = rowData
  const contentRef: any = useRef(null)
  const [isLike, setIslike] = useState(item.blog.isLike)
  const [likeNumber, setLikeNumber] = useState(item.blog.likeNumber)
  const [, setIsMore] = useState(false)
  // 关注按钮
  const onFollow = async (blog: any) => {
    if (!isLogin) {
      const param: any = {
        type: NativePage.login,
        params: {},
      }
      postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
      return
    }
    const action = isLike ? 2 : 1
    const {code, msg} = await blogOperating({id: blog.id, action})
    if (code !== CODE.SUCCESS) {
      Toast.fail(msg)
      return
    }
    const likeNumber_ = isLike ? likeNumber - 1 : likeNumber + 1
    setIslike(!isLike)
    setLikeNumber(likeNumber_ < 0 ? 0 : likeNumber_)
  }
  useEffect(() => {
    const contentHeight = contentRef.current.clientHeight
    if (contentHeight >= 40) {
      setIsMore(true)
      // console.log(contentHeight)
    }
  }, [])
  // 分享帖子
  const onShare = (post: any) => {
    try {
      const params = {
        pageType: SHARE_TYPE.Post, //  1: 帖子  / 2： 圈子
        title: `${post.authorUserInfo.nickName}在创想云发了一条帖子`, // 标题
        desc: post.content, // 描述
        icon: post.media && post.media.length ? post.media[0].url || '' : '', // 图片链接
        url: '/share-post/' + post.id, // 分享链接
        id: post.id, // id  帖子ID /圈子ID
        actionType: [0, 1, 2], //  操作数组  1: 复制链接 / 2：举报 / 3：退出圈子
      }
      // Toast.info(post.media[0].url)
      postMsgToNative('onMenu', encodeURI(JSON.stringify(params)))
    } catch (e) {
      //
    }
  }
  return (
    <div key={rowID} className="circle-item">
      <div className="item-title">
        <div className="circle-info" onClick={() => opDetail(item.circle.id)}>
          <img alt={item.circle.avatar} src={item.circle.avatar} />
          <div className="info">
            <p className="name">{item.circle.name}</p>
            {item.circle.likeNumber && item.circle.likeNumber > 0 ? (
              <p className="like">
                {likeFormat(item.circle.likeNumber)} {intl.get('likePeople')}
              </p>
            ) : null}
          </div>
        </div>
        <Avatar
          showCount={3}
          userCount={item.circle.userCount}
          avatarList={item.userList}
          event={(): void => onCircleMember(item.id)}
        />
      </div>
      <div className="circle-content">
        <div className="content" ref={contentRef} onClick={() => postDetail(item.blog.id)}>
          {item.blog.content}
        </div>
        {/* {isMore ? (<div className="more-content" onClick={() => postDetail(item.blog.id)}>{intl.get('homePage.fullText')}</div>) : null} */}
        <GalleryComp media={item.blog.media} mediaType={item.blog.mediaType} />
        {item.blog.tags.length ? (
          <div className="tag-list">
            {item.blog.tags.map((t: any, index: any) => (
              <div className="tag" onClick={() => onTagPostList(t.id)} key={index}>
                {t.name}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="circle-user">
        <div className="user" onClick={() => goUserInfo(item.blog.authorUserId)}>
          <img src={item.blog.authorUserInfo.avatar} />
          <p>{item.blog.authorUserInfo.nickName}</p>
        </div>
        <div className="option">
          <div className="follow">
            <img src={isLike ? Followed : Follow} onClick={() => onFollow(item.blog)} />
            <p>{likeNumber}</p>
          </div>
          <div
            className="Comment"
            onClick={() => {
              postDetail(item.blog.id)
            }}>
            <img src={Comment} />
            <p>{item.blog.commentNumber}</p>
          </div>
          <div
            className="Forward"
            onClick={() => {
              onShare(item.blog)
            }}>
            <img src={Forward} />
            <p>{item.blog.sharedNumber}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
interface MyCircle {
  list: object[]
  refreshPage?: boolean
}
/**
 * 我的圈子
 */
const MyCircle: React.FC<MyCircle> = props => {
  const {list, refreshPage = true} = props
  const circle = list
  return (
    <div className="myCircle-layout">
      <div className="circle-title">
        <Item
          extra={<img className="extra-right-icon" src={arrow_right} />}
          onClick={() => {
            myCircle()
          }}>
          {intl.get('homePage.myCircle')}
        </Item>
      </div>
      <div className="my-circle-list-box">
        <div className="my-circle-title" onClick={() => findCircle()}>
          <img alt={intl.get('homePage.findCircle')} src={MyCircleIcon} />
          <p className="title">{intl.get('homePage.findCircle')}</p>
        </div>
        {refreshPage ? (
          <div className="swiper-container">
            <div className="circle-content swiper-wrapper">
              {circle.map((item: any, index) => (
                <div key={index} onClick={() => goCircle(item)} className="circle-item swiper-slide">
                  <div className="image-box">
                    <img alt={item.name} src={item.avatar} />
                  </div>
                  <p className="title">{item.name}</p>
                  {item.likeNumber !== 0 && (
                    <p className="like">
                      {likeFormat(item.likeNumber)} {intl.get('likePeople')}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
/**
 * 圈子列表
 */
let page = 0
const pageSize = 5 // 每次加载的数据数量
const CircleList = () => {
  // 初始化上啦组件
  const ScrollData = new ListView.DataSource({
    rowHasChanged: (row1: any, row2: any) => row1 !== row2,
  })

  const [isLoading, setIsLoading] = useState(true)
  const [] = useState(false)
  const [list, setList] = useState([])
  const [dataSource, setDataSource] = useState(ScrollData)
  // 获取帖子列表数据
  const initCircleList = async (isScroll = false) => {
    if (!isLoading) {
      return
    }
    if (isScroll) {
      page++
    } else {
      page = 1
    }
    const {code, msg, result} = await circleHomeList({page, pageSize})
    if (code !== CODE.SUCCESS) {
      if (!isScroll) Toast.fail(msg)
      return
    }
    const NewList: any = [...list, ...result.list]
    setDataSource(ScrollData.cloneWithRows(NewList))
    setList(NewList)
    if (page * pageSize >= parseInt(result.count)) {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    initCircleList()
  }, [])
  return (
    <div className="circle-list-layout">
      {list && list.length ? (
        <ListView
          dataSource={dataSource}
          renderFooter={() => (
            <>
              {isLoading ? (
                <>
                  <Icon type="loading" size="xs" />
                  <span>Loading...</span>
                </>
              ) : (
                intl.get('homePage.loadAll')
              )}
            </>
          )}
          renderRow={(rowData: any, sectionID: any, rowID: any) => (
            <CircleRow rowData={rowData} sectionID={sectionID} rowID={rowID} />
          )}
          useBodyScroll
          pageSize={pageSize}
          initialListSize={pageSize}
          onContentSizeChange={(contentWidth, contentHeight) => {
            console.log(contentHeight)
          }}
          onEndReached={() => initCircleList(true)}
          onEndReachedThreshold={0}
        />
      ) : !isLoading ? (
        <div className="null-circle-list">{intl.get('homePage.noData')}</div>
      ) : null}
    </div>
  )
}

const Home = () => {
  const [myCircle, setMyCircle] = useState<any>([])
  // const [isLogin, setIsLogin] = useState(false)
  const [refreshPage, setRefreshPage] = useState(true)
  const initSwiper = () => {
    new Swiper('.swiper-container', {
      slidesPerView: 'auto',
    })
  }
  // 获取我的圈子列表数据
  const initmyCircleList = async () => {
    // Toast.info(token, 5)
    if (!isLogin) return
    const {code, msg, result} = await ownerList({page: 1, pageSize: 10, isHome: true})
    if (code !== CODE.SUCCESS) {
      Toast.fail(msg)
      return
    }
    let list = [...result.topList, ...result.list]
    // 取前9条数据展示
    if (list.length > 9) {
      list = list.splice(0, 9)
    }
    setMyCircle(list)
    // 初始化swiper滑动插件
    if (list.length > 3) {
      initSwiper()
    }
  }
  // 同步页面数据
  const RefreshPage = async () => {
    await setRefreshPage(false)
    await setRefreshPage(true)
    initmyCircleList()
  }
  useEffect(() => {
    initmyCircleList()
    addAppBridge('refreshPage', RefreshPage)
  }, [isLogin])
  return (
    <div className="home-page-layout">
      {/* <div className="search-top-layout">
        <TitleSearch />
      </div> */}
      {/* <Banner /> */}
      <Separator />
      {/* <Button onClick={() => RefreshPage()}>刷新</Button> */}
      <MyCircle refreshPage={refreshPage} list={myCircle} />
      <Separator />
      {/* {refreshPage ? (<CircleList />) : null} */}
      <CircleList />
    </div>
  )
}
export default Home
