/** @format */
import React from 'react'

export default function TextButton(props: {
  text: string
  iconSize?: number
  fontSize?: number
  color?: string
  disabled?: boolean
  preIcon?: any
  sufIcon?: any
  onClick?: Function
}) {
  const {text, iconSize, fontSize, color, disabled, preIcon, sufIcon, onClick} = props
  let realColor = color === 'blue' ? '#1987ea' : '#1987ea'
  if (disabled) realColor = '#999'

  const iconStyle = !!iconSize ? {width: `${iconSize / 100}rem`, height: `${iconSize / 100}rem`} : {}

  return (
    <div
      className="text-button"
      onClick={() => {
        if (onClick && !disabled) onClick()
      }}>
      {!!preIcon ? <span className="pre-icon" style={{...iconStyle, backgroundImage: `url(${preIcon})`}} /> : ''}
      <span style={{fontSize: `${fontSize ? fontSize / 100 : 0.12}rem`, color: realColor}}>{text}</span>
      {!!sufIcon ? <span className="pre-icon" style={{...iconStyle, backgroundImage: `url(${sufIcon})`}} /> : ''}
    </div>
  )
}
