/** @format */

import PictureList from '@/components/picture-list'
import VideoComp from '@/components/video-comp'
import {postMsgToNative} from '@/utils/app-bridge'
import NativePage from '@/utils/enum/native-page'
import React from 'react'
import './index.less'

interface Props {
  media: any[]
  tags?: any[]
  isDetail?: boolean
  mediaType: number
  isSharePage?: boolean
  openPage?: Function
}
enum type {
  text = 1, // 文本
  image, // 图片
  video, // 视频
}
const GalleryComp = (props: Props): JSX.Element => {
  const {media, mediaType, tags, isSharePage, openPage, isDetail = false} = props
  // 跳转标签列表页面
  const onTagPostList = (id: any) => {
    if (isSharePage && openPage) {
      openPage()
      return
    }
    const param: any = {
      type: NativePage.webPage,
      params: {
        url: `/tag-post-list/${id}`,
      },
    }
    postMsgToNative('jumpPage', encodeURI(JSON.stringify(param)))
  }
  return (
    <div className="gallery-comp">
      {mediaType === type.image && <PictureList List={media || []} />}
      {mediaType === type.video && <VideoComp isDetail={isDetail} video={media[0]} />}
      {tags && tags.length ? (
        <div className="tags">
          {tags.map((tag, index) => (
            <span onClick={() => onTagPostList(tag.id)} key={index}>
              {tag.name}
            </span>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default GalleryComp
