/** @format */

import {nativeRouteTo} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import {Circle} from '@/utils/data'
import {CIRCLE_OPERATING, CIRCLE_STATUS} from '@/utils/enum'
import CODE from '@/utils/enum/code'
import NativePage from '@/utils/enum/native-page'
import {numToWan} from '@/utils/format'
import {deepClone} from '@/utils/public'
import {operateCircle} from '@/utils/request/circle'
import showKeyWords from '@/utils/showKeyWords'
import {ActionSheet, Button, ListView, Modal, Toast} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import FormComfirm from '../form-comfirm'
import './index.less'

const userId = cookieUtils.getCookie('__CXY_UID_')
export default function CircleList(props: {
  refresh?(id: string): void
  circleList: Array<Circle>
  isMine?: boolean
  isTop?: boolean
  needStickyBtn?: boolean
  needHighlight?: boolean
  keyword?: string
  noButton?: boolean
  onScrollToBottom?: Function
  onCircleOperating?: Function
}) {
  const {
    circleList,
    isMine,
    isTop,
    needStickyBtn,
    onScrollToBottom,
    onCircleOperating,
    needHighlight,
    keyword,
    refresh,
    noButton,
  } = props

  // 滚动加载
  function handleScroll() {
    if (onScrollToBottom) onScrollToBottom()
  }

  const pageSize = 10

  const scrollData = new ListView.DataSource({
    rowHasChanged: (row1: any, row2: any) => row1 != row2,
  })

  const [dataSource, setDataSource] = useState(scrollData)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const initHeight = circleList.length * 0.85
  const [contentHeight, setContentHeight] = useState(initHeight)

  function resetCircleList() {
    const height = circleList.length * 0.85
    setContentHeight(height)
    const cloneList = deepClone(circleList)
    setDataSource(scrollData.cloneWithRows(cloneList))
  }

  useEffect(() => {
    resetCircleList()
  }, [circleList])

  // 跳转到详情
  function viewDetail(circle: Circle) {
    const inAudit = circle.status === CIRCLE_STATUS.PENDING
    if (inAudit) {
      setConfirmVisible(true)
    } else {
      nativeRouteTo(NativePage.webPage, {url: `/circle-details/${circle.id}`})
    }
  }

  return (
    <div className="circle-list_wrapper">
      <ListView
        dataSource={dataSource}
        // renderFooter={() => <span>{intl.get('comLoading.loaded')}</span>}
        style={{height: contentHeight + 'rem', overflow: 'auto'}}
        renderRow={(rowData, rowID) => (
          <div className="circle-item" key={rowID}>
            <img className="circle-cover" src={rowData.avatar} alt="" onClick={() => viewDetail(rowData)} />
            <div className={`circle-info ${noButton ? 'no-button' : ''}`} onClick={() => viewDetail(rowData)}>
              <div className="name">
                {needHighlight ? (
                  <div
                    dangerouslySetInnerHTML={{__html: showKeyWords(rowData.name, keyword || '', rowData.name.length)}}
                  />
                ) : (
                  rowData.name
                )}
              </div>
              {rowData.description ? (
                <div className="intro">
                  {needHighlight ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: showKeyWords(rowData.description, keyword || '', rowData.description.length),
                      }}
                    />
                  ) : (
                    rowData.description
                  )}
                </div>
              ) : (
                ''
              )}
              {rowData.likeNumber > 0 ? (
                <div className="like-count">
                  {numToWan(rowData.likeNumber)} {intl.get('likePeople')}
                </div>
              ) : (
                ''
              )}
            </div>
            {noButton ? null : (
              <CircleItemButton
                circle={rowData}
                isMine={isMine}
                isTop={isTop}
                refresh={refresh && (id => refresh(id))}
                needStickyBtn={needStickyBtn}
                onOperating={onCircleOperating}
              />
            )}
          </div>
        )}
        useBodyScroll={false}
        renderBodyComponent={() => <CircleListBody />}
        pageSize={pageSize}
        onEndReachedThreshold={10}
        onEndReached={() => handleScroll()}
        initialListSize={pageSize}
      />
      <FormComfirm
        visible={confirmVisible}
        type="info"
        title={intl.get('auditing2')}
        message={intl.get('auditing2Tip')}
        hide={() => setConfirmVisible(false)}
      />
    </div>
  )
}

function CircleListBody(props: any) {
  return (
    <div className="circle-list">
      { props.children }
    </div>
  )
}

// 按钮
function CircleItemButton(props: {
  refresh?(id: string): void
  circle: Circle
  isMine?: boolean
  isTop?: boolean
  needStickyBtn?: boolean
  onOperating?: Function
}) {
  const userId = cookieUtils.getCookie('__CXY_UID_')
  const {circle, isMine, isTop, needStickyBtn, onOperating, refresh} = props

  const {isJoined} = circle

  const [joined, setJoined] = useState(isJoined)

  // 提示弹窗
  function confirm(options: {title?: string; message: string; onConfirm: Function}) {
    const {title, message, onConfirm} = options
    Modal.alert(title, message, [
      {text: intl.get('cancel'), style: {color: '#aaa'}},
      {text: intl.get('confirm'), onPress: () => onConfirm(), style: {color: '#1987ea'}},
    ])
  }

  // 圈子操作
  async function doOperate(id: string, action: number, successMsg: string) {
    const {code, msg} = await operateCircle({id, action})
    if (code === CODE.SUCCESS) {
      Toast.success(successMsg, 2)
      if (refresh) refresh(id)
      if (action !== CIRCLE_OPERATING.JOIN && onOperating) {
        onOperating()
      }
    } else {
      Toast.fail(msg)
    }
  }

  const joinCircle = (circle: Circle) => {
    if (!cookieUtils.checkingLogin()) {
      nativeRouteTo(NativePage.login)
      return
    }
    doOperate(circle.id, CIRCLE_OPERATING.JOIN, `${intl.get('myCircle.joinedTip')} ${circle.name}！`)
    setJoined(true)
  }

  function beforeQuit() {
    Modal.alert('', intl.get('myCircle.quitTip'), [
      {text: intl.get('myCircle.think'), style: 'default'},
      {
        text: intl.get('quit'),
        onPress: () => {
          doOperate(circle.id, CIRCLE_OPERATING.QUIT, `${intl.get('myCircle.quittedTip')} ${circle.name}`)
        },
      },
    ])
  }

  const operate = (circle: Circle) => {
    if (!cookieUtils.checkingLogin()) {
      nativeRouteTo(NativePage.login)
      return
    }
    const options: any[] = [
      isTop ? intl.get('myCircle.cancelStick') : intl.get('myCircle.stick'),
      intl.get('myCircle.quitCircle'),
      intl.get('cancel'),
    ]
    // 如果圈子是自己创建的，则不需要退出圈子功能
    if (userId === circle.authorUserId) {
      options.splice(1, 1)
    }

    ActionSheet.showActionSheetWithOptions(
      {
        options: needStickyBtn ? options : [intl.get('myCircle.quitCircle'), intl.get('cancel')],
        cancelButtonIndex: needStickyBtn ? 2 : 1,
      },
      buttonIndex => {
        if (buttonIndex === 0) {
          if (needStickyBtn) {
            confirm({
              message: isTop ? intl.get('myCircle.cancelStickTip') : intl.get('myCircle.stickTip'),
              onConfirm: () => {
                const {STICKY, CANCEL_STICKY} = CIRCLE_OPERATING
                const action = isTop ? CANCEL_STICKY : STICKY
                doOperate(circle.id, action, intl.get('doSucc'))
              },
            })
          } else {
            beforeQuit()
          }
        } else if (buttonIndex === 1) {
          if (needStickyBtn && userId !== circle.authorUserId) beforeQuit()
        }
      },
    )
  }

  const inAudit = circle.status === CIRCLE_STATUS.PENDING
  const isMineCircle = userId === circle.authorUserId
  if (isMine) {
    return inAudit ? (
      <div className="circle-status-btn">{intl.get('auditing')}</div>
    ) : (
      !isMineCircle ?
      <div className="circle-operate" onClick={() => operate(circle)}>
        <span className="full-bg" />
      </div> : null
    )
  }
  return (
    <Button className="circle-btn" disabled={joined} onClick={() => joinCircle(circle)}>
      {joined ? intl.get('comButton.joined') : intl.get('comButton.join')}
    </Button>
  )
}
