/** @format */

import React from 'react'
import intl from 'react-intl-universal'
import './index.less'

interface ShareTop {
  openPage: Function
}

const ComShareTop = (props: ShareTop) => {
  const {openPage} = props
  return (
    <div className="share-top-container">
      <div className="left-div">
        <div className="logo"></div>
        <div className="text">{intl.get('comSharePage.creality')}</div>
      </div>
      <div className="btn" onClick={() => openPage()}>
        {intl.get('comSharePage.download')}
      </div>
    </div>
  )
}

export default ComShareTop
