/** @format */

export default {
  home: '首页',
  mine: '我的',
  share: '分享到',
  report: '举报',
  confirm: '确认',
  cancel: '取消',
  quit: '退出',
  likePeople: '人喜欢',
  doSucc: '操作成功!',
  auditing: '审核中',
  auditing2: '审核中',
  auditing2Tip: '圈子已提交，正在审核中',
  errNetwork: '网络出错',
  minute: '分钟前',
  hour: '小时前',
  day: '天前',
  week: '周前',
  month: '月前',
  now: '刚刚',
  systemErr: '系统错误，请联系运维',
  endpointErr: '登录过期用户信息有误，请重新登录后重试！',
  uploadErr: '上传失败，身份信息错误',
  comButton: {
    // 公共按钮
    join: ' 加入 ',
    joined: '已加入',
    follow: '关注',
    followed: '已关注',
  },
  comLoading: {
    // 公共底部加载信息
    loading: '加载中...',
    loaded: '已加载全部数据',
    noData: '暂无数据',
  },
  homePage: {
    // 首页
    myCircle: '我的圈子',
    findCircle: '发现圈子',
    loadAll: '已加载全部数据',
    noData: '暂无数据',
    searchPlaceholder: '搜索你感兴趣的内容',
    share: '分享到',
    fullText: '全文',
  },
  circleDetail: {
    // 圈子详情页
    tab_all: '全部',
    tab_video: '视频',
    join: '我要参与',
    noData: '圈主太懒了，赶紧成为第一个~',
  },
  createCircle: {
    //创建圈子
    needCate: '请选择圈子分类',
    needName: '请输入圈子名称',
    needDesc: '请输入圈子描述',
    needAvatar: '请上传头像',
    createErr: '创建失败',
    createSucc: '创建成功',
    auditTip: '我们将会在2个工作日审核完成',
    avatarMaxSize: '头像大小不能超过5M',
    create: '创建圈子',
    confirm: '提交',
    circleAvatar: '圈子头像',
    circleBg: '选择圈子背景色',
    cate: '分类',
    name: '名称',
    namePlaceholder: '请输入10个字以内的圈子名称',
    describe: '描述',
    describePlaceholder: '输入圈子的描述',
    maxDescribe: '圈子描述在15至120个字符',
    location: '地点',
    locationPlaceholder: '点击获取地点',
    createTip: '是否要退出创建圈子页面？',
    tip: '提示'
  },
  findCircle: {
    // 发现圈子
    loading: '加载中...',
    recommend: '推荐',
    create: '创建',
    findCircle: '发现圈子',
    searchPlaceholder: '搜索你想要找的圈子',
  },
  circleMember: {
    // 圈子成员
    followSucc: '关注成功',
    follow: '关注',
    followed: '已关注',
    member: '圈子成员',
  },
  myCircle: {
    // 我的圈子
    loading: '加载中...',
    create: '创建',
    myCircle: '我的圈子',
    topCircle: '置顶圈子',
    emptyText: '可将喜欢的圈子置顶哦',
    noJoinText: '还没有加入任何圈子~',
    emptyListText: '发现很多好玩的圈子，快去加入~~',
    toJoin: '去加入',
    toCreate: '去创建',
    joinCircle: '加入圈子',
    joinTip: '确定加入该圈子吗?',
    joinedTip: '您已加入圈子',
    quitTip: '确认要退出圈子吗？',
    quittedTip: '您已退出圈子',
    quitCircle: '退出圈子',
    stick: '置顶',
    cancelStick: '取消置顶',
    cancelStickTip: '确定将该圈子取消置顶吗？',
    stickTip: '确定将该圈子置顶吗？',
    think: '我再想想',
  },
  participate: {
    // 发布帖子
    saveTip: '是否保留本次编辑？保留后，再次进入页面可继续编辑。',
    loadVideoRrr: '视频加载失败！',
    uploadVideoRrr: '视频上传失败！',
    releasing: '发布中...',
    releaseSucc: '发布成功!',
    release: '发布',
    describePlaceholder: '添加一段描述，会被更多人看到哦...',
    cover: '选择封面',
    addTags: '添加标签',
    addLocation: '添加位置',
    tag: '标签',
  },
  searchPost: {
    // 搜索帖子
    searchPlaceholder: '搜索圈子内帖子',
    noPostText: '打开方式不对，换个关键词试试',
  },
  postDetail: {
    //帖子详情
    associated: '关联圈子',
    numComments: '条评论',
    firstMan: '快来成为第一个评论的人吧~',
    more: '展开更多回复',
    folded: '收起'
  },
  myPost: {
    // 我的帖子
    myPost: '我的帖子',
  },
  userCircles: {
    //用户圈子
    myJoin: '我加入的',
    myCreate: '我创建的',
    myCircle: '我的圈子',
    noCircleText: '还没有加入任何圈子~',
    noCircleText2: '还没有创建圈子，快去创建~~',
    joinBtnTxt: '去加入',
    createBtnTxt: '去创建',
  },
  errorPage: {
    noPage: '啊哦，你所访问的页面不存在了~~',
    otherOperation: '你可以尝试以下操作',
    goHome: '返回首页',
  },
  comComment: {
    oneDayBefore: '一天前',
    like: '喜欢',
    author: '作者'
  },
  comFormComfirm: {
    know: '我知道了',
  },
  comFormModel: {
    createErr: '创建失败',
  },
  comKeyboard: {
    write: '写评论...',
    release: '发布',
  },
  comMapModel: {
    errLocation: '获取城市定位失败！',
    errTip: '获取位置信息失败，请手动搜索地址',
    hidden: '不显示我的位置',
    searchPlaceholder: '搜索位置',
    emptyLocation: '位置信息为空，请重新搜索',
  },
  comTooltip: {
    saySomething: '说点什么...',
  },
  comSharePage: {
    download: '立即下载',
    creality: '创想云 | 3D打印产业布道者',
    open: 'App内打开',
    circlePage: '圈子主页',
    postPage: '帖子主页',
    model: '模型组',
    post: '帖子',
    circle: '圈子',
    homePage: '个人主页',
    private: '私信',
    follow: '关注',
    man: '男',
    woman: '女',
    fans: '粉丝',
    like: '获赞',
    pcTips: '请用手机打开该网页!',
    crealityTitle: '创想云',
    createText: '创造现实 成就梦想',
    download2: '下载',
    openApp: '打开App浏览',
    circles: '个圈子',
    models: '个模型组',
    posts: '个帖子',
    allModels: '打开App浏览全部模型组',
    allcircles: '打开App浏览全部圈子',
    allPosts: '打开App浏览全部帖子',
  },
}
