/** @format */

import CircleList from '@/components/circle-list'
import {nativeRouteTo, postMsgToNative} from '@/utils/app-bridge'
import cookieUtils from '@/utils/cookieUtils'
import {Circle} from '@/utils/data'
import CODE from '@/utils/enum/code'
import NativePage from '@/utils/enum/native-page'
import {getCircleList, getOwnerCircleList} from '@/utils/request/circle'
import {Button, NavBar, Toast, WhiteSpace} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import intl from 'react-intl-universal'
import {useHistory} from 'react-router-dom'
import './index.less'

export default function UserCircles() {
  enum TYPE {
    JOINED = 1,
    CREATED = 2,
  }
  const types = [
    {name: intl.get('userCircles.myJoin'), value: TYPE.JOINED},
    {name: intl.get('userCircles.myCreate'), value: TYPE.CREATED},
  ]
  const pageSize = 10
  const userId = cookieUtils.getUserId()
  const router = useHistory()

  const [type, setType] = useState(TYPE.JOINED)

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [ownerCircles, setOwnerCircles] = useState<Circle[]>([])
  const [count, setCount] = useState(0)

  const [isEmpty, setIsEmpty] = useState(false)

  useEffect(() => {
    if (loading) {
      Toast.loading(intl.get('comLoading.loading'), 60)
    } else {
      Toast.hide()
    }
  }, [loading])

  useEffect(() => {
    loadOwnerCircles()
  }, [page, type])

  // 加载我的圈子列表
  async function loadOwnerCircles() {
    setLoading(true)
    const {code, msg, result} = await loadCircles()
    if (code === CODE.SUCCESS) {
      if (page === 1 && type === TYPE.JOINED) {
        setOwnerCircles([...result.topList, ...result.list])
      } else {
        setOwnerCircles([...ownerCircles, ...result.list])
      }
      const listCount = parseInt(result.count)
      setCount(listCount)
      setIsEmpty(listCount === 0)
    } else {
      Toast.fail(msg)
    }
    setLoading(false)
  }

  // 加载圈子列表
  async function loadCircles() {
    let result = null
    if (type === TYPE.JOINED) {
      result = await getOwnerCircleList({page, pageSize, isHome: false, isNotCreated: true})
    } else {
      result = await getCircleList({page, pageSize, userId})
    }
    return result
  }

  // Tab切换
  function changeType(val: number) {
    if (type === val) return
    setIsEmpty(false)
    setOwnerCircles([])
    setPage(1)
    setType(val)
  }

  // 上拉加载
  function onListScroll() {
    if (count === 0 || (!loading && ownerCircles.length < count)) {
      setPage(page + 1)
    }
  }

  // 去加入
  function goToJoin() {
    if (!cookieUtils.checkingLogin()) {
      nativeRouteTo(NativePage.login)
      return
    }
    nativeRouteTo(NativePage.webPage, {url: '/find-circles'})
  }

  // 去创建圈子
  function goToCreate() {
    if (!cookieUtils.checkingLogin()) {
      nativeRouteTo(NativePage.login)
      return
    }
    router.push('/creat-circle')
  }

  const refresh = (id: string) => {
    for (let i = 0; i < ownerCircles.length; i++) {
      if (ownerCircles[i].id === id) {
        ownerCircles.splice(i, 1)
        break
      }
    }
    setOwnerCircles([...ownerCircles])
  }

  const statusBarHeight = cookieUtils.getCookie('__CXY_STATUBARHEIGHT_')

  return (
    <div className="page-user-circles pd-top-44">
      <NavBar
        className="fixed-nav-bar"
        mode="light"
        leftContent={<span className="left-icon full-bg" onClick={() => postMsgToNative('closePage')} />}
        style={{paddingTop: statusBarHeight + 'px'}}>
        {intl.get('userCircles.myCircle')}
      </NavBar>
      <div className="top-tabs" style={{paddingTop: statusBarHeight + 'px'}}>
        {types.map(typeItem => (
          <div
            key={typeItem.value}
            className={`top-tab-item ${type === typeItem.value ? 'active' : ''}`}
            onClick={() => changeType(typeItem.value)}>
            {typeItem.name}
            <span className="selected-line" />
          </div>
        ))}
      </div>
      <div className="top-tabs-replace" />
      <WhiteSpace />
      {!isEmpty ? (
        <CircleList
          circleList={ownerCircles}
          isMine
          onScrollToBottom={() => onListScroll()}
          refresh={id => refresh(id)}
          noButton={false}
        />
      ) : (
        <div className="list-empty-container">
          <div className="list-empty-pic full-bg" />
          <div className="list-empty-tip">
            {type === TYPE.JOINED ? intl.get('userCircles.noCircleText') : intl.get('userCircles.noCircleText2')}
          </div>
          <Button type="ghost" onClick={() => (type === TYPE.JOINED ? goToJoin() : goToCreate())}>
            {type === TYPE.JOINED ? intl.get('userCircles.joinBtnTxt') : intl.get('userCircles.createBtnTxt')}
          </Button>
        </div>
      )}
    </div>
  )
}
