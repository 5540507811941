/** @format */

import React from 'react'
import intl from 'react-intl-universal'
import './index.less'

interface ButtonProps {
  onClick?(): void
  status?: number
}

enum btnStatus {
  unjoin = -2,
  join = -1,
  unfollow = 0,
  follow = 1,
  reverseunfollow = 2,
  reversefollow = 3,
}

const ButtonComp = (props: ButtonProps): JSX.Element => {
  const {status, onClick} = props
  let buttonStyle = ''
  let title = ''
  switch (status) {
    case btnStatus.unjoin:
      title = intl.get('comButton.join')
      buttonStyle = 'unjoined'
      break
    case btnStatus.join:
      title = intl.get('comButton.joined')
      buttonStyle = 'checked'
      break
    case btnStatus.unfollow:
      title = intl.get('comButton.follow')
      buttonStyle = 'unchecked'
      break
    case btnStatus.reverseunfollow:
      title = intl.get('comButton.follow')
      buttonStyle = 'unchecked'
      break
    case btnStatus.follow:
      title = intl.get('comButton.followed')
      buttonStyle = 'checked'
      break
    case btnStatus.reversefollow:
      title = intl.get('comButton.followed')
      buttonStyle = 'checked'
      break
    default:
      title = ''
      buttonStyle = ''
  }

  return (
    <div className={`button-comp ${buttonStyle}`} onClick={onClick}>
      {title}
    </div>
  )
}

export default ButtonComp
