import post from '../http'
export const getUserInfo = (userId:number) => post('/api/user/getOtherInfo', {userId})
// 得到圈子列表
export const getOwnerCircleList = (
  params: {page: number; pageSize: number; isHome: boolean; isOwner?: boolean; isNotCreated?: boolean; userId?:number} = {
    page: 1,
    pageSize: 10,
    isHome: false,
  },
) => post('/api/circle/circle/ownerList', params)
// 模型列表
export const getModelList = (params:{}) => post('/api/model/list/share', params)